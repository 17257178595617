import { useEffect, useRef } from "react";
import classNames from "classnames";
import gsap from "gsap";

import Definition, {
  DefinitionProps,
} from "components/layouts/Definition/Definition";

import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "./ViewPortfolioItemSection";
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageProps,
} from "./ViewPortfolioItemImage";

import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import Print from "components/common/Print";
import VimeoPlayer from "components/common/VimeoPlayer";
import {useTranslation} from "react-i18next";

interface ViewPortfolioItemAboutSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption?: [string, string, string];
  image?: {
    isLogo?: boolean;
    src: ViewPortfolioItemImageProps["src"];
    alt?: string;
    sources?: { srcset: string; media: string }[];
  };
  video?: {
    src: string
  },
  name: string;
  description: DefinitionProps["description"];
  className?: string;
}

const ViewPortfolioItemAboutSection = ({
  caption,
  image,
  video,
  name,
  description,
  className,
  ...sectionProps
}: ViewPortfolioItemAboutSectionProps) => {
  const bottomElRef = useRef<HTMLDivElement>(null);
  const captionElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bottomEl = bottomElRef.current!;
    const labelEl = bottomEl.querySelector(".definition__label");
    const termEl = bottomEl.querySelector(".definition__term");

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: bottomEl,
        toggleActions: "play reset play reset",
      },
    });

    timeline.from([labelEl, termEl], {
      y: 20,
      opacity: 0,
      ease: "ease-out",
      duration: 0.6,
    });


    return () => {
      timeline.kill();
    };
  }, []);

  useEffect(() => {
    if (!caption) {
      return;
    }

    const captionEl = captionElRef.current!;
    const captionFirstEl = captionEl.querySelector(
      ".view-portfolio-item-about-section__caption--first"
    );
    const captionSecondEl = captionEl.querySelector(
      ".view-portfolio-item-about-section__caption--second"
    );
    const captionThirdEl = captionEl.querySelector(
      ".view-portfolio-item-about-section__caption--third"
    );

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: captionEl,
        toggleActions: "play reset play reset",
      },
    });

    timeline.fromTo(captionEl, {
      y: 40,
    }, {
      y: 0,
      ease: "ease-out",
      duration: 0.6 + 0.2 * 2,
    });

    timeline.fromTo(
      captionFirstEl,
      {
        y: 20,
        opacity: 0
      },
      {
        opacity: 1,
        y: 0,
        ease: "ease-out",
        duration: 0.6,
      },
      "<"
    );

    timeline.fromTo(
      captionSecondEl,
      {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        ease: "ease-out",
        duration: 0.6,
        delay: 0.2,
      },
      "<"
    );

    timeline.fromTo(
      captionThirdEl,
      {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        ease: "ease-out",
        duration: 0.6,
        delay: () => {
          const isMinSm = matchMedia("(min-width: 414px)");
          const isMinMd = matchMedia("(min-width: 768px)");

          if (isMinSm) {
            return 0;
          } else if (isMinMd) {
            return 0.2;
          } else {
            return 0.4;
          }
        },
      },
      "<"
    );

    return () => {
      timeline.kill();
    };
  }, [caption]);

  const { t } = useTranslation();

  return (
    <ViewPortfolioItemSection
      size={{
        top: "sm",
        bottom: "lg",
      }}
      {...sectionProps}
      className={"view-portfolio-item-about-section background--gray " + className}
    >
      <div className="view-portfolio-item-about-section__container container">
        {caption && (
          <div className="view-portfolio-item-about-section__top row">
            <div
              ref={captionElRef}
              className="view-portfolio-item-about-section__top-container col col-xl-4 offset-xl-5"
            >
              <div className="view-portfolio-item-about-section__top-inner row">
                <p className="view-portfolio-item-about-section__caption--first order-sm-0 col col-md-11 view-portfolio-item__text">
                  {caption[0]}
                </p>
                <p className="view-portfolio-item-about-section__caption--second order-sm-2 col-11 col-md-auto offset-md-auto view-portfolio-item__text">
                  {caption[2]}
                </p>
                <p className="view-portfolio-item-about-section__caption--third order-sm-1 col-auto view-portfolio-item__text">
                  {caption[1]}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="view-portfolio-item-about-section__middle row">
          {image && <ViewPortfolioItemImage
            className={classNames(
              image.isLogo ? "col-5 offset-3" : "col-9 offset-1 col-md-9 offset-md-1",
              !image.isLogo && "col-lg-9 offset-lg-1 col-xl-8 offset-xl-1"
            )}
            imageClassName={classNames(
              "view-portfolio-item-about-section__middle-image",
              image.isLogo &&
              "view-portfolio-item-about-section__middle-image--logo"
            )}
            src={image.src}
            alt={image.alt}
          />}

          {video && <div className="col-11 col-lg-9 offset-lg-1 col-xl-8 offset-xl-1">
            <VimeoPlayer size="fill" className="u-ratio u-ratio--3/5 view-portfolio-item-about-section__video" url={video.src} play muted controls={false} loop={true} />
          </div>}
        </div>
        <div
          ref={bottomElRef}
          className="view-portfolio-item-about-section__bottom"
        >
          <Definition label={t("About the project")} term={name}>
            {Array.isArray(description) ? description.map((descItem, i) => (
              <Print key={i} data={descItem}>
                {(text) => (
                  <RevealTextByLine wordClassName={(l, w) => i !== 0 && l === 0 && w === 0 && "offset-lg-8"} className="definition__description-item">
                    {text}
                  </RevealTextByLine>
                )}
              </Print>
            )
            ) : (
              <Print data={description!}>
                {(text) => (
                  <RevealTextByLine>
                    {text!}
                  </RevealTextByLine>
                )}
              </Print>
            )}
          </Definition>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioItemAboutSection;
