import { useEffect, useRef, useState, useCallback } from "react";
import classNames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";

import { loadImages } from "helpers/common/dom";
import { makeCancelable } from "helpers/common/promises";
import useBreakpointData, { BreakpointDataType } from "hooks/useBreakpointData";
import { useMediaQuery } from "hooks/useMediaQuery";

import FlipBookCanvas, { FlipBookCanvasRef } from "./FlipBookCanvas";

gsap.registerPlugin(ScrollTrigger);

export interface FlipBookProps {
  /**
   * @default "div"
   */
  tag?: React.ElementType<any>;

  /**
   * Кадры (src картинок)
   */
  frames: string[];
  breakpointsFrames?: BreakpointDataType<string[]>;
  alt?: string;

  /**
   * Если true, тогда размер картинок будут рассчитываться
   * как свойство background-image со значением contain, в противном случае cover
   */
  contain?: boolean;
  framesPerScreen?: number;
  className?: string;
  clearBeforeDraw?: boolean;
  onHeightUpdate?: (height: number) => void;
  onProgress?: (progress: number) => void;
}

const frameCache = new Map<string, HTMLImageElement>();

const loadCachedImage = async (src: string): Promise<HTMLImageElement> => {
  if (frameCache.has(src)) {
    return frameCache.get(src)!;
  }

  const img = new Image();
  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    img.onload = () => {
      frameCache.set(src, img);
      resolve(img);
    };
    img.onerror = reject;
  });
  img.src = src;
  return promise;
};

interface FlipBookPageProps {
  image: string;
  index: number;
}

// Мемоизированный компонент страницы
const FlipBookPage = React.memo(({ image, index }: FlipBookPageProps) => {
  return <img src={image} alt={`Page ${index}`} />;
});

const FlipBook = ({
  tag: Tag = "div",
  contain,
  frames,
  alt,
  className,
  framesPerScreen = 80,
  breakpointsFrames,
  clearBeforeDraw,
  onHeightUpdate,
  onProgress,
}: FlipBookProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesRef = useRef<HTMLImageElement[]>([]);

  const breakpointFrames = useBreakpointData(breakpointsFrames || {}, frames);

  const [state, setState] = useState<{
    loading: boolean;
    previewImage: HTMLImageElement | null;
    framesImages: HTMLImageElement[];
  }>({
    loading: false,
    previewImage: null,
    framesImages: [],
  });

  const flipBookElRef = useRef<HTMLDivElement>(null);
  const innerElRef = useRef<HTMLDivElement>(null);
  const flipBookCanvasRef = useRef<FlipBookCanvasRef>(null);

  const [error, setError] = useState<Error | null>(null);

  // Добавляем состояние для оптимизированных кадров
  const [optimizedFrames, setOptimizedFrames] = useState<string[]>(frames);

  // Используем хук для определения мобильного устройства
  const isMobile = useMediaQuery("(max-width: 767px)");

  // В начале компонента добавляем ref для ScrollTrigger
  const scrollTriggerRef = useRef<ScrollTrigger | null>(null);

  const handleImageError = useCallback((error: Error) => {
    console.error("Failed to load flip-book image:", error);
    setError(error);
  }, []);

  // Переместили useCallback внутрь компонента
  const handleAnimationEnd = useCallback(() => {
    setIsAnimating(false);
  }, []);

  // Загружаем сначала каждый 10-й кадр
  const loadKeyFrames = useCallback(() => {
    const keyFrames = frames.filter((_, i) => i % 10 === 0);
    return loadImages(keyFrames);
  }, [frames]);

  // Загружаем промежуточные кадры
  const loadIntermediateFrames = useCallback(
    (start: number, end: number) => {
      const intermediateFrames = frames.slice(start, end);
      return loadImages(intermediateFrames);
    },
    [frames]
  );

  // Добавим приоритетную загрузку видимых кадров
  const loadVisibleFrames = useCallback(
    (startIndex: number, endIndex: number) => {
      const visibleFrames = frames.slice(startIndex, endIndex);
      return Promise.all(
        visibleFrames.map(async (src) => {
          const img = await loadCachedImage(src);
          imagesRef.current[frames.indexOf(src)] = img;
          return img;
        })
      );
    },
    [frames]
  );

  // Функция для загрузки всех кадров
  const loadAllFrames = useCallback(() => {
    return Promise.all(
      frames.map(async (src) => {
        const img = await loadCachedImage(src);
        return img;
      })
    );
  }, [frames]);

  useEffect(() => {
    // Сначала загружаем ключевые кадры
    loadKeyFrames().then((keyFrameImages) => {
      setState((prev) => ({ ...prev, framesImages: keyFrameImages }));

      // Затем загружаем остальные кадры порциями
      for (let i = 0; i < frames.length; i += 10) {
        loadIntermediateFrames(i, i + 10).then((images) => {
          setState((prev) => ({
            ...prev,
            framesImages: [
              ...prev.framesImages.slice(0, i),
              ...images,
              ...prev.framesImages.slice(i + 10),
            ],
          }));
        });
      }
    });
  }, [frames, loadKeyFrames, loadIntermediateFrames]);

  useEffect(() => {
    // Предзагружаем все изображения
    const preloadImages = () => {
      frames.forEach((src, index) => {
        const img = new Image();
        img.src = src;
        imagesRef.current[index] = img;
      });
    };

    preloadImages();
  }, [frames]);

  // эффект обновляет высоту флип бука в зависимости от кол-во всех прогруженных фреймов
  useEffect(() => {
    const flipBookEl = flipBookElRef.current!;

    const height = (frames.length / framesPerScreen) * window.innerHeight;

    flipBookEl.style.height = height + "px";

    ScrollTrigger.refresh();

    if (onHeightUpdate) {
      onHeightUpdate(height);
    }
  }, [frames, framesPerScreen, onHeightUpdate]);

  // Изменяем эффект с анимацией
  useEffect(() => {
    if (state.framesImages.length === 0) {
      return;
    }

    let handleResize: (() => void) | null = null;

    try {
      const flipBookEl = flipBookElRef.current!;
      const flipBookCanvas = flipBookCanvasRef.current!;
      const innerEl = innerElRef.current!;

      handleResize = () => {
        flipBookCanvas.resize(innerEl.offsetWidth, innerEl.offsetHeight);
        flipBookCanvas.drawImage(
          state.framesImages[0],
          contain ? "contain" : "cover"
        );
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      // Создаем ScrollTrigger и сохраняем ссылку
      scrollTriggerRef.current = ScrollTrigger.create({
        trigger: flipBookEl,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        onUpdate: (self) => {
          if (clearBeforeDraw) {
            flipBookCanvas.clear();
          }

          const progress = self.progress;
          const frameIndex = Math.min(
            Math.floor(progress * state.framesImages.length),
            state.framesImages.length - 1
          );

          const currentFrame = state.framesImages[frameIndex];
          if (currentFrame) {
            flipBookCanvas.drawImage(
              currentFrame,
              contain ? "contain" : "cover"
            );
          }

          if (onProgress) {
            onProgress(progress);
          }
        },
      });
    } catch (error) {
      console.error("Error creating ScrollTrigger:", error);
    }

    return () => {
      if (handleResize) {
        window.removeEventListener("resize", handleResize);
      }
      // Безопасно очищаем ScrollTrigger
      if (scrollTriggerRef.current) {
        scrollTriggerRef.current.kill();
        scrollTriggerRef.current = null;
      }
    };
  }, [state.framesImages, contain, clearBeforeDraw, onProgress]);

  useEffect(() => {
    if (state.previewImage === null) {
      return;
    }

    const flipBookCanvas = flipBookCanvasRef.current!;
    const innerEl = innerElRef.current!;

    flipBookCanvas.clear();
    flipBookCanvas.resize(innerEl.offsetWidth, innerEl.offsetHeight);
    flipBookCanvas.drawImage(state.previewImage, contain ? "contain" : "cover");
  }, [state.previewImage, contain]);

  // Используем предзагруженные изображения
  const getImage = (index: number) => {
    return imagesRef.current[index] || null;
  };

  // путь к картинке, которая будет отображаться пока грузятся фреймы
  // const posterSrc = useMemo(() => {
  //   return (
  //     breakpointFrames && breakpointFrames.length > 0 && breakpointFrames[0]
  //   );
  // }, [breakpointFrames]);

  // Проверка последовательности изображений
  const validateImageSequence = useCallback(() => {
    const loadedImages = imagesRef.current.filter(Boolean);
    return loadedImages.length === frames.length;
  }, [frames]);

  // Выносим animate за пределы handlePageFlip
  const animate = useCallback(
    (flipBookCanvas: FlipBookCanvasRef, currentImage: HTMLImageElement) => {
      if (!isAnimating && flipBookCanvas) {
        gsap.to(flipBookCanvas.canvas, {
          duration: 0.3,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            flipBookCanvas.clear();
            requestAnimationFrame(() => {
              flipBookCanvas.drawImage(
                currentImage,
                contain ? "contain" : "cover"
              );
              gsap.to(flipBookCanvas.canvas, {
                duration: 0.3,
                opacity: 1,
                ease: "power2.in",
              });
            });
          },
        });
      }
    },
    [isAnimating, contain]
  );

  // Теперь используем animate в handlePageFlip
  const handlePageFlip = useCallback(() => {
    if (!isAnimating && currentIndex >= 0 && currentIndex < frames.length) {
      setIsAnimating(true);
      const flipBookCanvas = flipBookCanvasRef.current;
      const currentImage = imagesRef.current[currentIndex];

      if (flipBookCanvas && currentImage) {
        animate(flipBookCanvas, currentImage);
      }
    }
  }, [currentIndex, frames.length, isAnimating, animate]);

  // Изменяем эффект очистки
  useEffect(() => {
    return () => {
      // Очищаем кэш изображений
      frameCache.clear();

      // Убиваем все анимации только если canvas существует
      if (flipBookCanvasRef.current?.canvas) {
        gsap.killTweensOf(flipBookCanvasRef.current.canvas);
      }

      // Безопасно очищаем ScrollTrigger
      if (scrollTriggerRef.current) {
        scrollTriggerRef.current.kill();
        scrollTriggerRef.current = null;
      }

      // Очищаем состояние
      setState({
        loading: false,
        previewImage: null,
        framesImages: [],
      });
    };
  }, []);

  // Используем оптимизированные кадры для мобильных
  useEffect(() => {
    if (isMobile) {
      const mobileFrames = frames.filter((_, i) => i % 2 === 0);
      setOptimizedFrames(mobileFrames);
    } else {
      setOptimizedFrames(frames);
    }
  }, [isMobile, frames]);

  if (error) {
    return (
      <div className="flip-book flip-book--error">Failed to load animation</div>
    );
  }

  return (
    //@ts-ignore
    <Tag ref={flipBookElRef} className={classNames("flip-book", className)}>
      <div ref={innerElRef} className="flip-book__inner">
        <FlipBookCanvas ref={flipBookCanvasRef} />
        {state.loading && (
          <div className="flip-book__loader">
            <div
              className="flip-book__loader-progress"
              style={{
                width: `${(state.framesImages.length / frames.length) * 100}%`,
              }}
            />
          </div>
        )}
      </div>
    </Tag>
  );
};

export default FlipBook;
