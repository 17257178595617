import Section from "components/layouts/Section";

import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { SwiperSlide, Swiper } from "swiper/react";

import * as ReactDOMServer from "react-dom/server";

import SwiperCore, { Pagination } from "swiper";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import { sectionData } from "data/customerReviews";

SwiperCore.use([Pagination]);

interface CustomerReviewsSectionProps {
  data: {
    customerRewiewsSection?: {
      title: string;
      subtitle: string;
      reviews: Array<{
        title: string;
        subtitle: string;
        point: number;
        text: string;
        icon: string;
      }>;
      footerText: React.ReactNode;
    };
  };
}

const CustomerReviewsSection = ({ data }: CustomerReviewsSectionProps) => {
  const context = useViewServicesItemContext() || { data: {} };
  const reviewsData = data || context.data || {};
  const customerReviews = reviewsData?.customerRewiewsSection || sectionData;

  console.log("CustomerReviewsSection - данные отзывов:", customerReviews);
  console.log(
    "CustomerReviewsSection - тексты отзывов:",
    customerReviews.reviews.map((r) => r.text)
  );

  if (
    !customerReviews ||
    !customerReviews.reviews ||
    !Array.isArray(customerReviews.reviews)
  ) {
    console.error("CustomerReviewsSection - отсутствуют данные отзывов");
    return null;
  }

  return (
    <DynamicBackgroundTrigger>
      <Section className={s["view-developer-sites-customer-reviews-section"]}>
        <div className={"section-container container "}>
          <div>
            <div
              className={
                "row " +
                s["view-developer-sites-customer-reviews-section__header"]
              }
            >
              <RevealWhenInView className="col-xl-7">
                <h2 className="heading service-heading2">
                  {customerReviews.title}
                </h2>
              </RevealWhenInView>
              <div className=" offset-lg-4 col-lg col-md-8 offset-xl-0">
                <RevealTextByLine
                  wordClassName={(l, w) => l === 0 && w === 0 && "offset-lg-3"}
                >
                  {customerReviews.subtitle}
                </RevealTextByLine>
              </div>
            </div>
          </div>
          <Swiper
            loop
            navigation={{
              nextEl: ".next-el-button",
            }}
            pagination={{
              clickable: true,
              el: ".swiper-pagination",
              bulletActiveClass: s.active,
              bulletClass: s["paginator-item"],
              renderBullet: function (index, className) {
                // Проверяем, что индекс находится в пределах массива
                const safeIndex = index % customerReviews.reviews.length;
                const review = customerReviews.reviews[safeIndex];

                if (!review || !review.icon) {
                  console.error(
                    `Отсутствует иконка для отзыва с индексом ${safeIndex}`
                  );
                  return "";
                }

                return ReactDOMServer.renderToStaticMarkup(
                  <div className={className}>
                    <img
                      key={safeIndex}
                      className="awards-img"
                      src={`/images/projects-icon/${review.icon}`}
                      width="70"
                      height="70"
                      alt={`Отзыв от ${review.title}`}
                    />
                  </div>
                );
              },
            }}
          >
            {customerReviews.reviews.map((item, index) => {
              if (!item) {
                console.error(
                  `Отсутствуют данные для отзыва с индексом ${index}`
                );
                return null;
              }

              console.log(`Отзыв ${index} - текст:`, item.text);

              return (
                <SwiperSlide key={index}>
                  <div
                    className={
                      s[
                        "view-developer-sites-customer-reviews-section__slider-header"
                      ] + " row"
                    }
                  >
                    <RevealWhenInView className="col-7 italic-text text-uppercase">
                      <p className={"heading-4 italic-text " + s["reviewer"]}>
                        {item.title}
                      </p>
                      <RevealTextByLine
                        className={"sublabel--secondary " + s["review-subname"]}
                      >
                        {item.subtitle}
                      </RevealTextByLine>
                    </RevealWhenInView>
                    <RevealWhenInView className={"offset-auto"}>
                      <div
                        className={
                          s[
                            "view-developer-sites-customer-reviews-section__award-tag"
                          ]
                        }
                      >
                        <p>{`${item.point}.0`}</p>
                        <img
                          src="/images/sprite-icons/star.svg"
                          alt="Рейтинг"
                        />
                      </div>
                    </RevealWhenInView>
                  </div>
                  <div>
                    <span
                      className={
                        "subheading " +
                        s[
                          "view-developer-sites-customer-reviews-section__review-footer-text"
                        ]
                      }
                    >
                      <RevealTextByLine>{item.text}</RevealTextByLine>
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
            <RevealWhenInView>
              <div
                className={
                  "row " +
                  s["view-developer-sites-customer-reviews-section__footer"]
                }
              >
                <div
                  className={
                    "sprite-icon sprite-icon--arrow-down next-el-button " +
                    s[
                      "view-developer-sites-customer-reviews-section__footer-icon"
                    ]
                  }
                />

                <p
                  className={
                    s[
                      "view-developer-sites-customer-reviews-section__footer-text"
                    ] + " next-el-button "
                  }
                >
                  {customerReviews.footerText}
                </p>

                <div
                  className={
                    "row swiper-pagination " +
                    s[
                      "view-developer-sites-customer-reviews-section__paginator"
                    ] +
                    " next-el-button"
                  }
                ></div>
              </div>
            </RevealWhenInView>
          </Swiper>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default CustomerReviewsSection;
