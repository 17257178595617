import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

export interface ViewPortfolioWomanSportSiteAccountPageSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  image: {
    src: string;
    alt?: string;
  };
  caption: [string, string[]];
}

const ViewPortfolioWomanSportSiteAccountPageSection = ({
  caption,
  image,
  ...sectionProps
}: ViewPortfolioWomanSportSiteAccountPageSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-gallery-wss-1"
    >
      <figure className="view-portfolio-item-section__container container">
        <figcaption className="view-portfolio-item-section__header">
          <RevealWhenInView targetClassName="row">
            <div className="offset-md-1 offset-lg-0 col-11 col-md-9 col-lg-11 row">
              <div className="view-portfolio-item-section__header-first-col col-lg-3 ">
                <p className="label side-brackets">{caption[0]}</p>
              </div>
              <div className="view-portfolio-item-section__header-second-col offset-md-2 offset-lg-1 col-lg-7">
                <List items={caption[1]} />
              </div>
            </div>
          </RevealWhenInView>
        </figcaption>
        <div className="view-portfolio-item-section__body row">
          <div className="offset-md-1 offset-lg-2 col-11 col-md-9 col-lg">
            <RevealWhenInView>
              <img className="u-w-full" src={image.src} alt={image.alt} />
            </RevealWhenInView>
          </div>
        </div>
      </figure>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportSiteAccountPageSection;
