import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import React from "react";

export interface ViewPortfolioCuprumProps {}

const ViewPortfolioCuprum = (props: ViewPortfolioCuprumProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-cuprum"
        caption={[t("portfolioItem:cuprum.about.0"), t("portfolioItem:cuprum.about.1"), t("portfolioItem:cuprum.about.2"), ]}
        video={{
            src: 'https://vimeo.com/1035508536',
        }}
        style={{
          backgroundColor: '#494E58',
          color: '#FFF',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#494E58' }}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-3.image'))} alt={'cuprum'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#494E58' }} className={'view-portfolio-cuprum-section portfolio-item-section--type-cuprum-4'}>
          <RevealWhenInView>
              <div className={"u-ratio"}>
                  <VimeoPlayer  size="fill"  url={'https://vimeo.com/988760091/36d76f866c'} play muted controls={false} loop={true} />
              </div>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 5ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#494E58'}}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-5.image'))} alt={'cuprum'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 6ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#3A3D42', color: '#FFF' }} className={'view-portfolio-cuprum-section portfolio-item-section--type-cuprum-6 container'}>
            <div className="description row">
                <div className={'col-7'}>
                    <RevealWhenInView>
                        <p>{t("portfolioItem:cuprum.section-6.description.0")}</p>
                    </RevealWhenInView>
                </div>
                <div className={'col-auto offset-auto'}>
                    <RevealWhenInView><p>{t("portfolioItem:cuprum.section-6.description.1")}</p></RevealWhenInView>
                </div>
            </div>
            <div className={'col-9 offset-1'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-6.image'))} alt={'cuprum'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#494E58' }} className={'view-portfolio-cuprum-section portfolio-item-section--type-cuprum-7'}>
          <div className={'container'}>
              <div className={'col-9 offset-1'}>
                  <RevealWhenInView>
                      <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-7.image.0'))} alt={'cuprum'}/>
                  </RevealWhenInView>
              </div>
          </div>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-7.image.1'))} alt={'cuprum'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#494E58' }} className={'view-portfolio-cuprum-section portfolio-item-section--type-cuprum-8'}>
          <RevealWhenInView>
              <div className={"u-ratio"}>
                  <VimeoPlayer  size="fill"  url={'https://vimeo.com/992130046/e11950233b'} play muted controls={false} loop={true} />
              </div>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

        {/* 9ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#1D1D1D', color: '#FFF' }} className={'container view-portfolio-cuprum-section portfolio-item-section--type-cuprum-9 row'}>
            <div className="description">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:cuprum.section-9.description.0")}</p>
                        <p className={'list'}>
                            <span>{t("portfolioItem:cuprum.section-9.description.1.0")}</span>
                            <span>{t("portfolioItem:cuprum.section-9.description.1.1")}</span>
                            <span>{t("portfolioItem:cuprum.section-9.description.1.2")}</span>
                        </p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:cuprum.section-9.description.2")}</p></RevealWhenInView>
            </div>
            <div className={'col-9 offset-1'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-9.image'))} alt={'cuprum'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

        {/* 10ой экран*/}
        <ViewPortfolioItemSection dynamicBackgroundColor={true}
            style={{ backgroundColor: '#1D1D1D' }}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-10.image'))} alt={'cuprum'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 11ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#1D1D1D' }}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cuprum.section-11.image'))} alt={'cuprum'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 12ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#1D1D1D' }} className={'view-portfolio-cuprum-section portfolio-item-section--type-cuprum-12'}>
            <RevealWhenInView>
                <div className={"u-ratio"}>
                    <VimeoPlayer  size="fill"  url={'https://vimeo.com/988759901/8c05df3ebb'} play muted controls={false} loop={true} />
                </div>
            </RevealWhenInView>
        </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioCuprum;
