import classNames from "classnames";

import SectionLink from "components/layouts/SectionLink";

import { portfolio as portfolioRoute } from "routes";
import portfolio from "data/portfolio";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

import Print from "components/common/Print";
import { forwardRef, Ref } from "react";

interface PortfolioLinkSectionProps {
  className?: string;
}

type PortfolioLinkSectionRef = HTMLDivElement;

const PortfolioLinkSection = (
  { className }: PortfolioLinkSectionProps,
  ref: Ref<PortfolioLinkSectionRef>
) => {
  return (
    <SectionLink
      ref={ref}
      to={portfolioRoute.path}
      className={classNames("portfolio-link-section", className)}
      tickerText="All projects"
      label={() => (
        <RevealWhenInView>
          <p className="label link">
            <span className="link--normal">
              <span className="u-d-md-none">
                <Print data="layout:portfolio-link-section.label.first-part" />{" "}
              </span>
              <Print data="layout:portfolio-link-section.label.second-part" />
            </span>
          </p>
        </RevealWhenInView>
      )}
      description={() => (
        <RevealWhenInView>
          <p className="u-d-none u-d-md-block text--muted">
            <Print data="layout:portfolio-link-section.caption" /> (
            {portfolio.ru.length})
          </p>
        </RevealWhenInView>
      )}
    />
  );
};

export default forwardRef<PortfolioLinkSectionRef, PortfolioLinkSectionProps>(
  PortfolioLinkSection
);
