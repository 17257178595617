import DocumentLink from "components/common/DocumentLink/DocumentLink";
import s from "./index.module.scss";
import Section from "components/layouts/Section";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import Button from "components/common/Button";
import classNames from "classnames";
import Print from "components/common/Print";
import { Link } from "react-router-dom";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import { useTranslation } from "react-i18next";

interface ViewDeveloperSitesPriceSectionProps {}

interface DocumentLinkProps {
  name: string;
  size: string;
  date: string;
  href: string;
}

interface SubItem {
  title1?: string;
  title2?: string;
  subtitle?: string;
  title?: string;
  caption1?: string;
  caption2?: string;
  isCross?: boolean;
  document?: DocumentLinkProps;
  isSmall?: boolean;
}

interface JobPriceItem {
  document?: DocumentLinkProps;
  title?: string;
  title1?: string;
  subtitle?: string;
}

interface PriceItem {
  size?: "h4";
  title: string;
  caption?: string;
  items: SubItem[];
  link?: {
    href: string;
    text: string;
  };
  aunchor?: {
    to: string;
    text: string;
  };
  type?: string;
  isCross?: boolean;
}

const JobPrice = () => {
  const { data } = useViewServicesItemContext();
  const sectionData = data.priceSection;
  const { i18n } = useTranslation();

  if (!sectionData) {
    return null;
  }

  // Безопасно получаем класс из dopclasses
  const dopClass =
    sectionData.dopclasses &&
    typeof sectionData.dopclasses === "string" &&
    s[sectionData.dopclasses as keyof typeof s]
      ? s[sectionData.dopclasses as keyof typeof s]
      : "";

  // Функция для формирования ссылки с учетом языка
  const getLocalizedLink = (href: string) => {
    // Если ссылка уже содержит префикс языка, возвращаем её как есть
    if (href.startsWith("/en/") || href.startsWith("/ru/")) {
      return href;
    }

    // Добавляем префикс языка к ссылке
    return i18n.language === "en" ? `/en${href}` : href;
  };

  return (
    <DynamicBackgroundTrigger>
      <Section className={s["services-item-price-section"]}>
        <div className={`section-container container ${dopClass}`}>
          <div className="services-item-price-section__inner">
            <div>
              <div
                className={"row " + s["services-item-price-section__header"]}
              >
                <RevealWhenInView className={"col-lg-6 "}>
                  <h2 className={"heading " + s.title}>{sectionData.title}</h2>
                </RevealWhenInView>
                <div
                  className={
                    "col-lg-5 col-md-8 offset-lg-0 offset-md-3 " +
                    s["price-subtitle"]
                  }
                >
                  <RevealTextByLine
                    wordClassName={(l, w) =>
                      l === 0 && w === 0 && "offset-md-4"
                    }
                  >
                    {sectionData.subtitle}
                  </RevealTextByLine>
                </div>
              </div>
            </div>
            {sectionData.items.map((item) => {
              //here
              let headingClass =
                s["services-item-price-section__heading-2-adaptive"];
              if (item.size === "h4") {
                headingClass =
                  s["services-item-price-section__heading-3-adaptive"];
              }
              return (
                <div
                  className={
                    "row " +
                    s["services-item-price-section__price-per-hour-container"] +
                    " " +
                    s["body-item"]
                  }
                >
                  {/* Price */}
                  <div
                    className={
                      "col-auto offset-0 col-xl-6 col-md-5 offset-md-0 " +
                      s["title-container-subitem"] +
                      " " +
                      s["middle-col"]
                    }
                  >
                    <div>
                      <div
                        className={
                          `${headingClass} col-md-9 col-auto  ` +
                          s["heading-2-margin"]
                        }
                      >
                        <RevealWhenInView>
                          <p>{item.title}</p>
                        </RevealWhenInView>
                      </div>
                      {item.caption !== "" && (
                        <div
                          className={
                            "caption col-auto col-lg-6 col-md-8 " +
                            s["job-caption"]
                          }
                        >
                          <RevealWhenInView>
                            <p>{item.caption}</p>
                          </RevealWhenInView>
                        </div>
                      )}
                    </div>
                    {item.link && (
                      <Link
                        to={getLocalizedLink(item.link.href)}
                        className={
                          " col-11 col-md order-xl-0 col-xl-5 more-btn"
                        }
                      >
                        <Button
                          className={classNames("button footnote")}
                          renderRight={() => (
                            <RevealWhenInView from={{ opacity: 0, y: -25 }}>
                              <div
                                className={classNames(
                                  "sprite-icon sprite-icon--arrow-down",
                                  "btn-inner"
                                )}
                              />
                            </RevealWhenInView>
                          )}
                        >
                          <RevealWhenInView>{item.link.text}</RevealWhenInView>
                        </Button>
                      </Link>
                    )}
                    {item.aunchor && (
                      <div
                        className={
                          s.aunchor +
                          " col-auto col-lg-6 col-md-8 " +
                          s["aunchor-desc"]
                        }
                      >
                        <RevealWhenInView>
                          <ScrollDownButton
                            scrollTo={item.aunchor.to}
                            duration={1.5}
                            className={
                              "scroll-down-button-with-text text-italic"
                            }
                          >
                            {item.aunchor.text}
                          </ScrollDownButton>
                        </RevealWhenInView>
                      </div>
                    )}
                  </div>

                  {item.type === "price" ? (
                    <div
                      className={
                        (item.isCross
                          ? " col-xl-5 col-md-6 col-11 offset-md-0 row "
                          : " col-9 offset-2 col-sm-7 col-xl-5 col-md-6 offset-md-0 row ") +
                        s["middle-col"] +
                        " "
                      }
                    >
                      {item.items.map((subitem: SubItem) => {
                        return (
                          <div
                            className={
                              "col-11 " +
                              s.subitem +
                              " " +
                              (data.id === "technical-support" ||
                              data.id === "design-support"
                                ? s.lowmargin
                                : "")
                            }
                          >
                            <RevealWhenInView>
                              <div
                                className={
                                  "row " +
                                  s["heading-3-margin"] +
                                  " " +
                                  (subitem.isCross ? s["price-cross"] : "")
                                }
                              >
                                <div
                                  className={
                                    !subitem.isCross
                                      ? " col-xxl-7 col-lg-6 "
                                      : " col-auto "
                                  }
                                >
                                  {subitem.title1 && (
                                    <p
                                      className={
                                        headingClass + " " + s["price-title"]
                                      }
                                    >
                                      {subitem.title1}
                                    </p>
                                  )}
                                  {subitem.caption1 && (
                                    <p
                                      className={
                                        "caption " + s["caption-cross"]
                                      }
                                    >
                                      {subitem.caption1}
                                    </p>
                                  )}
                                </div>
                                {subitem.isCross && (
                                  <div className="u-ml-auto row">
                                    <p className={"label " + s["cross"]}>x</p>
                                  </div>
                                )}
                                <div
                                  className={
                                    item.isCross
                                      ? "col-xxl-4 col-md-5 col-4 u-ml-auto "
                                      : "col-xxl-4 col-lg-5 u-ml-auto "
                                  }
                                >
                                  <p
                                    className={
                                      s["nowrap-price"] +
                                      " " +
                                      (subitem.isSmall
                                        ? s[
                                            "services-item-price-section__heading-3-adaptive"
                                          ]
                                        : headingClass)
                                    }
                                  >
                                    {subitem.title2}
                                  </p>
                                  <p className={"caption " + s["job-caption"]}>
                                    {subitem.caption2}
                                  </p>
                                </div>
                              </div>
                            </RevealWhenInView>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <SlideFadeOutWhenInView>
                      {({ setTarget, setTriggerEl }) => (
                        <div
                          ref={setTriggerEl}
                          className={"col-11 col-md-5 row order-2 "}
                        >
                          <div ref={setTarget({ selector: ".selector-1" })}>
                            {item.items.map(
                              (elem: JobPriceItem, index: number) => {
                                if (elem.document) {
                                  return (
                                    <div
                                      className={
                                        "offset-0 col-11 offset-sm-auto col-sm-auto offset-md-0 col-md-6 order-3 " +
                                        s[
                                          "view-developer-sites-price-section__document-link-container"
                                        ]
                                      }
                                    >
                                      <RevealWhenInView>
                                        <DocumentLink
                                          {...elem.document}
                                          className="lowercase-title"
                                        />
                                      </RevealWhenInView>
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className={
                                      "row col-11 selector-1 " +
                                      s[
                                        "services-item-price-section__payment-steps-item"
                                      ]
                                    }
                                    key={index}
                                  >
                                    <span
                                      className={
                                        "side-brackets label " +
                                        s["itemprice-title"]
                                      }
                                    >
                                      {elem.title1 || elem.title || ""}
                                    </span>
                                    <p className={s["description"]}>
                                      {elem.subtitle}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    </SlideFadeOutWhenInView>
                  )}
                  {item.aunchor && (
                    <div
                      className={
                        "col-11 order-3 " + s["button-container-subitem"]
                      }
                    >
                      <div
                        className={s.aunchor + " col-auto col-lg-6 col-md-8"}
                      >
                        <RevealWhenInView>
                          <ScrollDownButton
                            scrollTo={item.aunchor.to}
                            duration={1.5}
                            className={
                              "scroll-down-button-with-text text-italic"
                            }
                          >
                            {item.aunchor.text}
                          </ScrollDownButton>
                        </RevealWhenInView>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            {/* Форматы оплаты */}
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default JobPrice;
