import {useEffect, useState} from "react";
import gsap from "gsap";
import { MotionPathPlugin, Draggable } from 'gsap/all';
gsap.registerPlugin(MotionPathPlugin, Draggable);
const ViewPortfolioCsdModel = () => {

  const [maxX, setMaxX] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);
  const [radius, setRadius] = useState(5);
  const [transformModel, setTransformModel] = useState('');
  const [step, setStep] = useState(0);
  let minX = 0;      // минимальная точка дуги

  const  initSvgSize = () =>{
    let containerWidth = document.getElementById('model-3d-container')?.offsetWidth ?? 0;
    setMaxX(containerWidth - radius);
    setSvgHeight(maxX * 34 / 95);
    setStep(Math.ceil((maxX - minX)/ 200));
  }

  const handleResize = () => {
    initSvgSize();
    let scale = '1, 1, 1';
    if(window.innerWidth < 1200 && window.innerWidth > 767){
      scale = '1.3, 1.3, 1.3'
    } else if(window.innerWidth < 768) {
      scale = '1.7, 1.7, 1.7';
    }
    setTransformModel(`perspective(20000px) rotateX(${75}deg) rotateZ(${35}deg) translateZ(-9vw) scale3d(${scale})`);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, );

  useEffect(() => {
    initSvgSize();
  },)
  useEffect(() => {
    let dot = document.getElementById('knob') ?? null;
    let timeline = gsap.timeline({
      paused: true,
      //duration: 0.6,
      ease: 'linear',
    })
    timeline.to(dot, {
      motionPath:{
        path:[
          {x:0,y:0},
          {x:0,y:(svgHeight*(1.15294) - svgHeight/2)},
          {x:maxX,y:(svgHeight*(1.15294) - svgHeight/2)},
          {x:maxX,y:0}
        ],
        type: "cubic",
        alignOrigin: [0.5, 0.5],
      },
    });

    timeline.progress(0.001);

    let D = document.createElement('div');
    Draggable.create(D,
        {
          trigger: "#knob",
          type:"x,y",
          allowEventDefault: true,
          bounds: {minX: minX, maxX: maxX, minY: 0, maxY: svgHeight},
          throwProps: true,
          cursor: 'pointer',
          onDrag: function() {
            let number = Math.ceil((this.x - minX) / step);         // номер активного кадра
            if(number > 200) number = 200 - 2;
            if(number < 0) number = 0;
            let scale = '1, 1, 1';
            if(window.innerWidth < 1200 && window.innerWidth > 767){
              scale = '1.3, 1.3, 1.3'
            } else if(window.innerWidth < 768) {
              scale = '1.7, 1.7, 1.7';
            }
            setTransformModel(`perspective(20000px) rotateX(${75}deg) rotateZ(${ number * 0.7 + 35}deg) translateZ(-9vw) scale3d(${scale})`);
            timeline.progress(Math.abs(this.x/maxX));
          }
        }
    );

    return () => {
      timeline.kill();
      Draggable.get(D).kill();
    }
  }, [svgHeight])
  return (
      <div className="3d-model" id="model-3d-container">
          <svg id="arch-dash" viewBox={"0 0 " + (maxX+5) + " " + (svgHeight+5)} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="path2" d={'M' + radius + ','+ (svgHeight/2) + ' C' + radius + ',' + svgHeight*(1.15294) + ' ' + (maxX+5)+',' + svgHeight*(1.15294) + ' ' + (maxX+5) + ',' + (svgHeight/2)} fill='none' strokeWidth="1px" strokeDasharray="2 5"
                    stroke="url(#paint0_radial)"/>
              <defs>
                  <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                  gradientTransform={'translate('+maxX/2+' '+ svgHeight/2 + ') rotate(-1.5353) scale(526.245 4578.52)'}>
                      <stop stopColor="#2F6AF2"/>
                      <stop offset="1" stopColor="#8986FF" stopOpacity="0"/>
                  </radialGradient>
                  <radialGradient id="paint0_radial2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                  gradientTransform={'translate('+maxX/2+' '+ svgHeight/2 + ') rotate(-1.5353) scale(526.245 4578.52)'}>
                      <stop stopColor="#8986FF"/>
                      <stop offset="1" stopColor="#8986FF" stopOpacity="0"/>
                  </radialGradient>
              </defs>
          </svg>
          <svg id='arch' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               viewBox={"0 0 " + (maxX+5) + " " + (svgHeight+5)} version="1.1">
              <path stroke="url(#paint1_radial)" className="path2" d={'M' + radius + ','+ (svgHeight/2) + ' C' + radius + ',' + svgHeight*(1.15294) + ' ' + (maxX+5)+',' + svgHeight*(1.15294) + ' ' + (maxX+5) + ',' + (svgHeight/2)} fill='none' strokeWidth="1px"/>
              <defs>
                  <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                  gradientTransform={'translate('+maxX/2+' '+ svgHeight/2 + ') rotate(-1.40314) scale(581.065 4898.91)'}>
                      <stop stopColor="#2F6AF2"/>
                      <stop offset="1" stopColor="#8986FF" stopOpacity="0"/>
                  </radialGradient>
                  <radialGradient id="paint1_radial2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                  gradientTransform={'translate('+maxX/2+' '+ svgHeight/2 + ') rotate(-1.40314) scale(581.065 4898.91)'}>
                      <stop stopColor="#8986FF"/>
                      <stop offset="1" stopColor="#8986FF" stopOpacity="0"/>
                  </radialGradient>
              </defs>
          </svg>
          <div className="house" style={{transform: transformModel}} id="h">
            <div className="al">
              <div className="al__front face"></div>
              <div className="al__back face"></div>
              <div className="al__right face"></div>
              <div className="al__left face"></div>
              <div className="al__top face"></div>
              <div className="al__bottom face"></div>
            </div>
            <div className="ab">
              <div className="ab__front face"></div>
              <div className="ab__back face"></div>
              <div className="ab__right face"></div>
              <div className="ab__left face"></div>
              <div className="ab__top face"></div>
              <div className="ab__bottom face"></div>
            </div>
            <div className="at">
              <div className="at__front face"></div>
              <div className="at__back face"></div>
              <div className="at__right face"></div>
              <div className="at__left face"></div>
              <div className="at__top face"></div>
              <div className="at__bottom face"></div>
            </div>
            <div className="ac">
              <div className="ac__front face"></div>
              <div className="ac__back face"></div>
              <div className="ac__right face"></div>
              <div className="ac__left face"></div>
              <div className="ac__top face"></div>
              <div className="ac__bottom face"></div>
            </div>
            <div className="bce">
              <div className="bce__front face"></div>
              <div className="bce__back face"></div>
              <div className="bce__right face"></div>
              <div className="bce__left face"></div>
              <div className="bce__top face"></div>
              <div className="bce__bottom face"></div>
            </div>
            <div className="bci">
              <div className="bci__front face"></div>
              <div className="bci__back face"></div>
              <div className="bci__right face"></div>
              <div className="bci__left face"></div>
              <div className="bci__top face"></div>
              <div className="bci__bottom face"></div>
            </div>
            <div className="bl">
              <div className="bl__front face"></div>
              <div className="bl__back face"></div>
              <div className="bl__right face"></div>
              <div className="bl__left face"></div>
              <div className="bl__top face"></div>
              <div className="bl__bottom face"></div>
            </div>
            <div className="bt">
              <div className="bt__front face"></div>
              <div className="bt__back face"></div>
              <div className="bt__right face"></div>
              <div className="bt__left face"></div>
              <div className="bt__top face"></div>
              <div className="bt__bottom face"></div>
            </div>
            <div className="bbe">
              <div className="bbe__front face"></div>
              <div className="bbe__back face"></div>
              <div className="bbe__right face"></div>
              <div className="bbe__left face"></div>
              <div className="bbe__top face"></div>
              <div className="bbe__bottom face"></div>
            </div>
            <div className="bbi">
              <div className="bbi__front face"></div>
              <div className="bbi__back face"></div>
              <div className="bbi__right face"></div>
              <div className="bbi__left face"></div>
              <div className="bbi__top face"></div>
              <div className="bbi__bottom face"></div>
            </div>
            <div className="cl">
              <div className="cl__front face">
                <div className="lamp"></div>
              </div>
              <div className="cl__back face"></div>
              <div className="cl__right face"></div>
              <div className="cl__left face"></div>
              <div className="cl__top face"></div>
              <div className="cl__bottom face"></div>
            </div>
            <div className="cc">
              <div className="cc__front face"></div>
              <div className="cc__back face"></div>
              <div className="cc__right face"></div>
              <div className="cc__left face"></div>
              <div className="cc__top face"></div>
              <div className="cc__bottom face"></div>
            </div>
            <div className="ccl">
              <div className="ccl__front face"></div>
              <div className="ccl__back face"></div>
              <div className="ccl__right face"></div>
              <div className="ccl__left face"></div>
              <div className="ccl__top face"></div>
              <div className="ccl__bottom face"></div>
            </div>
            <div className="cml">
              <div className="cml__front face"></div>
              <div className="cml__back face"></div>
              <div className="cml__right face"></div>
              <div className="cml__left face"></div>
              <div className="cml__top face"></div>
              <div className="cml__bottom face"></div>
            </div>
            <div className="cmr">
              <div className="cmr__front face"></div>
              <div className="cmr__back face"></div>
              <div className="cmr__right face"></div>
              <div className="cmr__left face"></div>
              <div className="cmr__top face"></div>
              <div className="cmr__bottom face"></div>
            </div>
            <div className="cmt">
              <div className="cmt__front face"></div>
              <div className="cmt__back face"></div>
              <div className="cmt__right face"></div>
              <div className="cmt__left face"></div>
              <div className="cmt__top face"></div>
              <div className="cmt__bottom face"></div>
            </div>
            <div className="dl">
              <div className="dl__front face"></div>
              <div className="dl__back face"></div>
              <div className="dl__right face"></div>
              <div className="dl__left face"></div>
              <div className="dl__top face"></div>
              <div className="dl__bottom face"></div>
            </div>
            <div className="dm">
              <div className="dm__front face"></div>
              <div className="dm__back face"></div>
              <div className="dm__right face"></div>
              <div className="dm__left face"></div>
              <div className="dm__top face"></div>
              <div className="dm__bottom face"></div>
            </div>
            <div className="dt">
              <div className="dt__front face"></div>
              <div className="dt__back face"></div>
              <div className="dt__right face"></div>
              <div className="dt__left face"></div>
              <div className="dt__top face"></div>
              <div className="dt__bottom face"></div>
            </div>
            <div className="el">
              <div className="el__front face"></div>
            </div>
            <div className="ec">
              <div className="ec__front face"></div>
            </div>
            <div className="er">
              <div className="er__right face"></div>
            </div>
            <div className="eb">
              <div className="eb__back face"></div>
            </div>
            <div className="fl">
              <div className="fl__front face"></div>
              <div className="fl__back face"></div>
              <div className="fl__right face"></div>
              <div className="fl__left face"></div>
              <div className="fl__top face"></div>
              <div className="fl__bottom face"></div>
            </div>
            <div className="ft">
              <div className="ft__front face"></div>
              <div className="ft__back face"></div>
              <div className="ft__right face"></div>
              <div className="ft__left face"></div>
              <div className="ft__top face"></div>
              <div className="ft__bottom face"></div>
            </div>
            <div className="fb">
              <div className="fb__front face"></div>
              <div className="fb__back face"></div>
              <div className="fb__right face"></div>
              <div className="fb__left face"></div>
              <div className="fb__top face"></div>
              <div className="fb__bottom face"></div>
            </div>
            <div className="table">
              <div className="table__front face"></div>
              <div className="table__back face"></div>
              <div className="table__right face"></div>
              <div className="table__left face"></div>
              <div className="table__top face"></div>
              <div className="table__bottom face">
                <div className="foot">
                  <div className="foot__front face"></div>
                  <div className="foot__back face"></div>
                  <div className="foot__right face"></div>
                  <div className="foot__left face"></div>
                  <div className="foot__top face"></div>
                  <div className="foot__bottom face"></div>
                </div>
                <div className="foot">
                  <div className="foot__front face"></div>
                  <div className="foot__back face"></div>
                  <div className="foot__right face"></div>
                  <div className="foot__left face"></div>
                  <div className="foot__top face"></div>
                  <div className="foot__bottom face"></div>
                </div>
                <div className="foot">
                  <div className="foot__front face"></div>
                  <div className="foot__back face"></div>
                  <div className="foot__right face"></div>
                  <div className="foot__left face"></div>
                  <div className="foot__top face"></div>
                  <div className="foot__bottom face"></div>
                </div>
                <div className="foot">
                  <div className="foot__front face"></div>
                  <div className="foot__back face"></div>
                  <div className="foot__right face"></div>
                  <div className="foot__left face"></div>
                  <div className="foot__top face"></div>
                  <div className="foot__bottom face"></div>
                </div>
              </div>
            </div>
            <div className="kitchen">
              <div className="kitchen__front face"></div>
              <div className="kitchen__back face"></div>
              <div className="kitchen__right face"></div>
              <div className="kitchen__left face"></div>
              <div className="kitchen__top face"></div>
              <div className="kitchen__bottom face"></div>
            </div>
            <div className="couch">
              <div className="couch__front face"></div>
              <div className="couch__back face"></div>
              <div className="couch__right face"></div>
              <div className="couch__left face"></div>
              <div className="couch__top face"></div>
              <div className="couch__bottom face"></div>
            </div>
            <div className="couchb">
              <div className="couchb__front face"></div>
              <div className="couchb__back face"></div>
              <div className="couchb__right face"></div>
              <div className="couchb__left face"></div>
              <div className="couchb__top face"></div>
              <div className="couchb__bottom face"></div>
            </div>
            <div className="couchc">
              <div className="couchc__front face"></div>
              <div className="couchc__back face"></div>
              <div className="couchc__right face"></div>
              <div className="couchc__left face"></div>
              <div className="couchc__top face"></div>
              <div className="couchc__bottom face"></div>
            </div>
            <div className="couchd">
              <div className="couchd__front face"></div>
              <div className="couchd__back face"></div>
              <div className="couchd__right face"></div>
              <div className="couchd__left face"></div>
              <div className="couchd__top face"></div>
              <div className="couchd__bottom face"></div>
            </div>
            <div className="picture">
              <div className="picture__front face"></div>
              <div className="picture__back face"></div>
              <div className="picture__right face"></div>
              <div className="picture__left face"></div>
              <div className="picture__top face"></div>
              <div className="picture__bottom face"></div>
            </div>
            <div className="door-r">
              <div className="door-r__front face"></div>
              <div className="door-r__back face"></div>
              <div className="door-r__right face"></div>
              <div className="door-r__left face"></div>
              <div className="door-r__top face"></div>
              <div className="door-r__bottom face"></div>
            </div>
            <div className="door-l">
              <div className="door-l__front face"></div>
              <div className="door-l__back face"></div>
              <div className="door-l__right face"></div>
              <div className="door-l__left face"></div>
              <div className="door-l__top face"></div>
              <div className="door-l__bottom face"></div>
            </div>
            <div className="door-b">
              <div className="door-b__front face"></div>
              <div className="door-b__back face"></div>
              <div className="door-b__right face"></div>
              <div className="door-b__left face"></div>
              <div className="door-b__top face"></div>
              <div className="door-b__bottom face"></div>
            </div>
            <div className="door-t">
              <div className="door-t__front face"></div>
              <div className="door-t__back face"></div>
              <div className="door-t__right face"></div>
              <div className="door-t__left face"></div>
              <div className="door-t__top face"></div>
              <div className="door-t__bottom face"></div>
            </div>
          </div>
          <svg  id='arch-pointer' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               viewBox={"0 0 " + (maxX+5) + " " + (svgHeight+5)} version="1.1">
              <path stroke="transparent" className="path2" fill='none' strokeWidth="1px" d={'M' + radius + ','+ (svgHeight/2) + ' C' + radius + ',' + svgHeight*(1.15294) + ' ' + (maxX+5)+',' + svgHeight*(1.15294) + ' ' + (maxX+5) + ',' + (svgHeight/2)}/>
              <path stroke="transparent" className="path1" fill='none' strokeWidth="1px" d={'M' + radius + ','+ (svgHeight/2) + ' C' + radius + ',' + svgHeight*(-0.1588235) + ' ' + (maxX+5)+',' + svgHeight*(-0.1588235) + ' ' + (maxX+5) + ',' + (svgHeight/2)}/>
              <g id="knob">
                  <circle r="15" id="main-circle-big" cx={radius} cy={svgHeight/2} fill="transparent"/>
                  <circle r="5" id="main-circle" cx={radius} cy={svgHeight/2} fill="#2F6AF2"/>
              </g>
          </svg>
      </div>
  );
};

export default ViewPortfolioCsdModel;