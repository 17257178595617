import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from '../ViewPortfolioItemImage';

interface ViewPortfolioItemTextData {
  heading: string;
  content: string[];
}

interface ViewPortfolioDomavekaGallerySectionProps {
  images: ViewPortfolioItemImageData[];
  texts: ViewPortfolioItemTextData[];
}

const ViewPortfolioDomavekaGallerySection = ({
  images,
  texts,
}: ViewPortfolioDomavekaGallerySectionProps) => {
  return (
    <ViewPortfolioItemSection
      dynamicBackgroundColor={true}
      style={{ backgroundColor: '#E6E6E6' }}
      className="view-portfolio-domaveka-gallery-section">
      <div className="view-portfolio-domaveka-gallery-section__container row">
        <div className="view-portfolio-domaveka-gallery-section__item">
          <RevealWhenInView>
            <p className="view-portfolio-domaveka-gallery-section__heading">
              {texts[0].heading}
            </p>
            <div className="view-portfolio-domaveka-gallery-section__content">
              {texts[0].content.map((text, i: number) => (
                <p key={text + i}>{text}</p>
              ))}
            </div>
          </RevealWhenInView>
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <ViewPortfolioItemImage
            className="doma-veka-section__image-wrapper"
            imageClassName="doma-veka-section__image"
            {...images[0]}
          />
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <ViewPortfolioItemImage
            className="doma-veka-section__image-wrapper"
            imageClassName="doma-veka-section__image"
            {...images[1]}
          />
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <ViewPortfolioItemImage
            className="doma-veka-section__image-wrapper"
            imageClassName="doma-veka-section__image"
            {...images[2]}
          />
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <ViewPortfolioItemImage
            className="doma-veka-section__image-wrapper"
            imageClassName="doma-veka-section__image"
            {...images[3]}
          />
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <RevealWhenInView>
            <p className="view-portfolio-domaveka-gallery-section__heading">
              {texts[1].heading}
            </p>
            <div className="view-portfolio-domaveka-gallery-section__content">
              {texts[1].content.map((text, i: number) => (
                <p key={text + i}>{text}</p>
              ))}
            </div>
          </RevealWhenInView>
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <RevealWhenInView>
            <p className="view-portfolio-domaveka-gallery-section__heading">
              {texts[2].heading}
            </p>
            <div className="view-portfolio-domaveka-gallery-section__content">
              {texts[2].content.map((text, i: number) => (
                <p key={text + i}>{text}</p>
              ))}
            </div>
          </RevealWhenInView>
        </div>
        <div className="view-portfolio-domaveka-gallery-section__item">
          <ViewPortfolioItemImage
            className="doma-veka-section__image-wrapper"
            imageClassName="doma-veka-section__image"
            {...images[4]}
          />
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioDomavekaGallerySection;
