import ViewPortfolioWildberries from "components/views/ViewPortfolioItem/ViewPortfolioWildberries";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "../../helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "wildberries",
  component: ViewPortfolioWildberries,
  title: "wildberries",
  name: "Wildberries",
  altName: "международный маркетплейс",
  year: 2023,
  awardsList: [],

  link: {
    label: "wildberries.ru",
    href: "https://wildberries.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/159994759/WEBSITE-WILDBERRIES-website-redesign",
    },
  ],

  preview: {
    label: "фирменный стиль, iOS-приложение",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/wildberries/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/wildberries/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["UX|UI design", "Branding"],

  type: "application",
  subtype: "application",

  description: [
    "это международный рынок товаров. Работает в России, Польше, Казахстане, Армении, Франции и других странах",
    "Клиентская аудитория маркетплейса за год более 100 миллионов пользователей",
  ],
  shortDescription: "Редизайн фирменного стиля и мобильного приложения",
  seo: {
    title:
      "Брендинг и дизайн мобильного приложения маркетплейса Wildberries для iOS",
    description:
      "Мы создали фирменный стиль и дизайн мобильного приложения iOS для международного маркетплейса Wildberries. Основная задача редизайна – оптимизация интерфейса приложения, чтобы обеспечить лучший пользовательский опыт и повысить конверсию приложения",
    keywords: [
      "брендинг",
      "дизайн",
      "мобильное приложение",
      "Wildberries",
      "вайлдберриз",
      "iOS",
      "маркетплейс",
      "дизайн интерфейса",
      "пользовательский опыт",
      "дизайн мобильного приложения",
      "фирменный стиль wildberries",
      "фирменный стиль",
    ],
    openGraph: {
      type: "acticle",
      title:
        "Брендинг и дизайн мобильного приложения маркетплейса Wildberries для iOS",
      description:
        "Мы создали брендинг (фирменный стиль) и дизайн мобильного приложения iOS для международного маркетплейса Wildberries. Основная задача редизайна это оптимизации интерфейса приложения, чтобы обеспечить лучший пользовательский опыт и повысить конверсию приложения",
    },
  },
};

export default porfolioItem;
