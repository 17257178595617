import Head from "components/layouts/Head/Head";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import { memo, useMemo, useEffect, useState } from "react";
import i18n from "i18next";
import { LangCodeType } from "i18n/types";
import { Redirect, useParams, useHistory } from "react-router-dom";
import ServicesItemLeadSection from "components/common/services/LeadSection/ServicesItemLeadSection";
import ProjectsSwiperSection from "components/common/services/ProjectsSwiper/ProjectsSwiperSection";
import ServicesFaqsSection from "components/common/services/FaqsSection/FaqsSection";
import ServicesStagesSection from "components/common/services/WorkStages/WorkStages";
import CustomerReviewsSection from "components/common/services/CustomerReviews/CustomerReviewsSection";
import JobPrice from "components/common/services/JobPrice/JobPrice";
import { ServicesItemData } from "data/services/types";
import {
  createSafeConsumer,
  createSafeContext,
  useSafeContext,
} from "contexts/helpers";
import { notFound as notFoundRoute } from "routes";
import AwardsSection from "components/layouts/AwardsSection";
import ServicesItemOurExperienceSection from "components/common/services/OurExperience/OurExpierenceSection";
import ServicesItemWhenNeededSection from "components/common/services/WhenNeeded/WhenNeededSection";
import JobTypes from "components/common/services/JobTypes/JobTypes";
import { sectionData } from "data/customerReviews";

// Функция для динамического импорта services
const getServices = () => {
  return import("data/services").then((module) => module.default);
};

interface ContextValue {
  data: ServicesItemData;
}

const Context = createSafeContext<ContextValue>();

export const useViewServicesItemContext = () => useSafeContext(Context);
export const ViewPortfolioItemConsumer = createSafeConsumer(Context);

function ViewServicesItem() {
  const params = useParams<{ servicesItemId: string; locale?: string }>();
  const history = useHistory();
  const [servicesItem, setServicesItem] = useState<ServicesItemData | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  // Устанавливаем язык на основе параметра locale из URL
  useEffect(() => {
    if (params.locale === "en" && i18n.language !== "en") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else if (!params.locale && i18n.language !== "ru") {
      i18n.changeLanguage("ru");
      localStorage.setItem("language", "ru");
    }
  }, [params.locale]);

  // Загружаем данные сервисов динамически
  const loadServices = async () => {
    try {
      const services = await getServices();
      // Определяем язык на основе параметра locale из URL
      const language = params.locale === "en" ? "en" : "ru";
      const servicesItems = services[language];
      const currentServicesIndex = servicesItems.findIndex(
        (item) => item.id === params.servicesItemId
      );

      setServicesItem(
        currentServicesIndex !== -1 ? servicesItems[currentServicesIndex] : null
      );
      setLoading(false);
    } catch (error) {
      console.error("Error loading services:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadServices();
  }, [params.servicesItemId, params.locale]);

  // Обработчик события storage для синхронизации языка между вкладками
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "language" && event.newValue) {
        const newLanguage = event.newValue;
        const currentLanguage = i18n.language;

        // Если язык изменился
        if (newLanguage !== currentLanguage) {
          // Изменяем язык
          i18n.changeLanguage(newLanguage);

          // Обновляем URL
          const pathWithoutLang =
            window.location.pathname === "/en"
              ? ""
              : window.location.pathname.replace("/en/", "");

          if (newLanguage === "en") {
            history.push(`/en${pathWithoutLang}`);
          } else {
            history.push(`${pathWithoutLang}`);
          }
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [history]);

  const contextValue = useMemo(
    () => ({
      data: servicesItem!,
    }),
    [servicesItem]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!servicesItem) {
    return <Redirect to="/404" />;
  }

  const {
    leadSection,
    sliderSection,
    faqsSection,
    workStagesSection,
    ourExpireanceSection,
    whenNeededSection,
    customerRewiewsSection,
    priceSection,
    jobTypes,
    seo,
  } = servicesItem;

  const SpecificPortfolioItemView = servicesItem.component;

  return (
    <Context.Provider value={contextValue}>
      <View>
        <Head
          seo={{
            title: seo.title,
            description: seo.description,
            keywords: seo.keywords,
            openGraph: seo.openGraph,
          }}
        />
        {/* 1. Lead Section (заголовок и подзаголовок) */}
        {leadSection && (
          <ServicesItemLeadSection locale={params.locale || "ru"} />
        )}

        {/* 2. Slider Section (слайдер проектов) */}
        {sliderSection && <ProjectsSwiperSection />}

        {/* 3. Specific Portfolio Item View (если есть) */}
        {SpecificPortfolioItemView && <SpecificPortfolioItemView />}

        {/* 4. Job Types (типы работ) */}
        {jobTypes && <JobTypes />}

        {/* 5. Our Experience Section (преимущества) */}
        {ourExpireanceSection && (
          <ServicesItemOurExperienceSection
            sectionData={ourExpireanceSection}
            color="white"
          />
        )}

        {/* 6. When Needed Section (когда нужна услуга) */}
        {whenNeededSection && (
          <ServicesItemWhenNeededSection
            sectionData={whenNeededSection}
            color="transparent"
          />
        )}

        {/* 7. Price Section (цены) */}
        {priceSection && <JobPrice />}

        {/* 8. Customer Reviews Section (отзывы клиентов) */}
        {customerRewiewsSection && (
          <CustomerReviewsSection data={servicesItem} />
        )}

        {/* 9. Work Stages Section (этапы работы) */}
        {workStagesSection && <ServicesStagesSection />}

        {/* 10. FAQs Section (часто задаваемые вопросы) */}
        {faqsSection && <ServicesFaqsSection />}

        {/* 11. Awards Section */}
        <AwardsSection />

        {/* 12. Footer */}
        <Footer />
      </View>
    </Context.Provider>
  );
}

export default memo(ViewServicesItem);
