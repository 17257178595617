import ViewPortfolioItemMobileVersionSection from "../ViewPortfolioItemMobileVersionSection";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemRelatedSection from "../ViewPortfolioItemRelatedSection";
import ViewPortfolioNovaGroupInnerPagesSection from "./ViewPortfolioNovaGroupInnerPagesSection";
import ViewPortfolioNovaGroupProjectCardSection from "./ViewPortfolioNovaGroupProjectCardSection";
import ViewPortfolioNovaGroupLandingPageSection from "./ViewPortfolioNovaGroupLandingPageSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import { useTranslation } from "react-i18next";
import { ViewSpecificPortfolioItemProps } from "data/portfolio/types";

const ViewPortfolioNovaGroup = ({
  relatedPortfolio,
}: ViewSpecificPortfolioItemProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        caption={[
          t("portfolioItem:nova-group.about.0"),
          t("portfolioItem:nova-group.about.1"),
          t("portfolioItem:nova-group.about.2"),
        ]}
        name={data.name}
        description={data.description}
        video={{
          src: "https://vimeo.com/548623694",
        }}
      />

      <ViewPortfolioNovaGroupInnerPagesSection
        images={Array.from({ length: 6 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-3-${i + 1}`),
          };
        })}
      />
      <ViewPortfolioNovaGroupProjectCardSection
        images={Array.from({ length: 2 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-4-${i + 1}`),
          };
        })}
      />
      <ViewPortfolioNovaGroupLandingPageSection
        video={{
          src: "https://vimeo.com/569469499",
        }}
      />

      <ViewPortfolioItemParallaxSection
        image={{
          src: buildPageImageSrc("screen-6"),
          alt: "House and blue skys",
        }}
      />

      {data.pageContent?.sections.mobileVersion && (
        <ViewPortfolioItemMobileVersionSection
          portfolioId={data.id}
          style={data.pageContent?.sections.mobileVersion.theme}
          {...data.pageContent?.sections.mobileVersion}
        />
      )}

      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          id={data.relatedId}
          preview={{
            image: {
              src: buildPageImageSrc("related"),
            },
          }}
          style={{ color: "#fff", backgroundColor: "#215DFD" }}
        />
      )}
    </>
  );
};

export default ViewPortfolioNovaGroup;
