import { memo, useCallback, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import FlipBook from "components/common/FlipBook";
import { FlipBookProps } from "components/common/FlipBook/FlipBook";

interface ViewHomeFlipBookSectionProps
  extends Pick<FlipBookProps, "frames" | "breakpointsFrames"> {
  onProgress?: (progress: number) => void;
}

const ViewHomeFlipBookSection = ({
  frames,
  breakpointsFrames,
  onProgress,
}: ViewHomeFlipBookSectionProps) => {
  const flipBookSectionElRef = useRef<HTMLDivElement>(null);

  const handleFlipBookHeightUpdate = useCallback(() => {
    ScrollTrigger.refresh();
  }, []);

  return (
    <section ref={flipBookSectionElRef} className="view-home-flip-book-section">
      <FlipBook
        frames={frames}
        onHeightUpdate={handleFlipBookHeightUpdate}
        breakpointsFrames={breakpointsFrames}
        onProgress={onProgress}
      />
    </section>
  );
};

export default memo(ViewHomeFlipBookSection);
