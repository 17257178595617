import ViewPortfolioAAA from "components/views/ViewPortfolioItem/ViewPortfolioAAA/ViewPortfolioAAA";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "aaa",
  component: ViewPortfolioAAA,
  title: "aaa",
  name: "aaa agency",
  altName: "баннеры и постеры",
  year: "2024",
  awardsList: [],

  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/213808373/AAA-Agency-Banners-posters",
    },
  ],

  preview: {
    label: "баннеры и постеры",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        file: "1.webp",
      }),
      toSrc: buildPublicImageSrc({
        file: "2.webp",
      }),
    },
  },

  poolOfWorks: ["Research", "Design"],

  type: "site",
  subtype: "site",

  description: [
    "проект направлен на создание визуального контента для продвижения брендов через кампании с участием инфлюенсеров.",
    " Мы разработали уникальные посты и баннеры, которые соответствовали стилю компании и интересам целевой аудитории",
  ],
  shortDescription: "Инфлюенсер-кампании и рекламные баннеры",

  seo: {
    title:
      "Дизайн-поддержка и аутсорсинг для AAA Agency: инфлюенсер-кампании и рекламные баннеры | SAGIROV",
    description:
      "Оказывали комплексную дизайн-поддержку и дизайн-аутсорсинг для AAA Agency: разработали уникальные посты и баннеры, направленные на продвижение брендов через инфлюенсер-кампании. Провели исследование, создали концепции, сгенерировали и обработали изображения. Особое внимание уделили соответствию фирменному стилю клиента и интересам целевой аудитории.",
    keywords: [
      "AAA Agency",
      "дизайн-поддержка",
      "дизайн аутсорсинг",
      "визуальный контент",
      "инфлюенсер-кампании",
      "рекламные баннеры",
      "уникальные посты",
      "брендовое продвижение",
      "ретро-гейминг тренд",
    ],
    openGraph: {
      type: "article",
      title:
        "Дизайн-поддержка и аутсорсинг для AAA Agency: инфлюенсер-кампании и рекламные баннеры | SAGIROV",
      description:
        "Оказывали комплексную дизайн-поддержку и дизайн-аутсорсинг для AAA Agency: разработали уникальные посты и баннеры, направленные на продвижение брендов через инфлюенсер-кампании. Провели исследование, создали концепции, сгенерировали и обработали изображения. Особое внимание уделили соответствию фирменному стилю клиента и интересам целевой аудитории.",
    },
  },
};

export default porfolioItem;
