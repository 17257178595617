import { Helmet } from "react-helmet-async";
import { useCurrentRoute } from "routes/helpers";
import { Seo } from "types/global";
import getOgItems from "./helpers";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

interface HeadProps {
  seo?: Seo;
}

/**
 * Позволяет указать мета теги для тэга head
 */
const Head = ({ seo }: HeadProps) => {
  const location = useLocation();
  const currentRoute = useCurrentRoute();
  const routeSeo =
    currentRoute && "seo" in currentRoute ? currentRoute.seo : undefined;
  const { i18n } = useTranslation();

  const currentPath = location.pathname.replace(/^\/(en\/)?/, "");
  const baseUrl = "https://sagirov.com";

  const langCode = useMemo(() => {
    return i18n.language;
  }, [i18n.language]);

  if (!seo && typeof routeSeo !== "undefined") {
    // @ts-ignore
    seo = routeSeo[langCode];
  }

  const mirrorLangCode = useMemo(() => {
    return i18n.language === "ru" ? "en" : "ru";
  }, [i18n.language]);

  useEffect(() => {
    document.querySelector("html")?.setAttribute("lang", langCode);
  });

  return seo ? (
    //@ts-ignore
    <Helmet>
      <title>{seo.title}</title>
      <meta httpEquiv="content-language" content={langCode}></meta>
      {seo.alternative && (
        <link
          rel="alternate"
          hrefLang={mirrorLangCode}
          href={seo.alternative}
        />
      )}
      {seo.keywords && (
        <meta name="keywords" content={seo.keywords.join(", ")} />
      )}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.openGraph &&
        getOgItems(seo.openGraph).map(([name, content], i) => {
          return <meta key={i} property={name} content={content} />;
        })}
      <link
        rel="canonical"
        href={
          i18n.language === "ru"
            ? `${baseUrl}/${currentPath}`
            : `${baseUrl}/en/${currentPath}`
        }
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${baseUrl}/${currentPath}`}
      />
      <link rel="alternate" hrefLang="ru" href={`${baseUrl}/${currentPath}`} />
      <link
        rel="alternate"
        hrefLang="en"
        href={`${baseUrl}/en/${currentPath}`}
      />
    </Helmet>
  ) : null;
};

export default Head;
