import translation from "./translation.json";
import view from "./view.json";
import layout from "./layout.json";
import portfolioItem from "./portfolioItem.json";

export default {
  translation,
  view,
  layout,
  portfolioItem,
};
