import {
  forwardRef,
  memo,
  Ref,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import {
  getScaleFactorToCover,
  getScaleFactorToContain,
} from "helpers/common/canvas";

interface FlipBookCanvasProps {}

export interface FlipBookCanvasRef {
  canvas: HTMLCanvasElement;
  /**
   * Обновляет размер холста с учетом соотношения пикселей устройства
   */
  resize: (width: number, height: number) => void;
  /**
   * Отрисовывает картинку image на холсте, применя масштабирование
   */
  drawImage: (image: HTMLImageElement, fit: "contain" | "cover") => void;
  clear: () => void;
}

/**
 * Отвечает за отрисовку фреймов для компонента FlipBook
 */
const FlipBookCanvas = (
  props: FlipBookCanvasProps,
  ref: Ref<FlipBookCanvasRef>
) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Выносим функцию отрисовки за пределы useCallback
  const drawImageToCanvas = (
    ctx: CanvasRenderingContext2D,
    canvasEl: HTMLCanvasElement,
    image: HTMLImageElement,
    scale: "cover" | "contain" = "cover"
  ) => {
    const getScaleFactor =
      scale === "cover" ? getScaleFactorToCover : getScaleFactorToContain;

    const scaleFactor = getScaleFactor(
      canvasEl.width,
      canvasEl.height,
      image.width,
      image.height
    );

    const width = Math.floor(scaleFactor * image.width);
    const height = Math.floor(scaleFactor * image.height);

    const x = -Math.round((width - canvasEl.width) / 2);
    const y = -Math.round((height - canvasEl.height) / 2);

    // Рисуем напрямую на основном canvas
    ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    ctx.drawImage(image, x, y, width, height);
  };

  useImperativeHandle(
    ref,
    () => {
      const canvasEl = canvasRef.current!;
      const ctx = canvasEl.getContext("2d")!;
      const dpr = Math.ceil(window.devicePixelRatio);

      return {
        canvas: canvasEl,
        resize: (width: number, height: number) => {
          canvasEl.width = width * dpr;
          canvasEl.height = height * dpr;
        },
        clear: () => ctx.clearRect(0, 0, canvasEl.width, canvasEl.height),
        drawImage: (
          image: HTMLImageElement,
          scale: "cover" | "contain" = "cover"
        ) => {
          drawImageToCanvas(ctx, canvasEl, image, scale);
        },
      };
    },
    []
  );

  return <canvas ref={canvasRef} className="flip-book__canvas" />;
};

export default memo(
  forwardRef<FlipBookCanvasRef, FlipBookCanvasProps>(FlipBookCanvas)
);
