import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18n";

import Head from "./layouts/Head";
import Menu from "./layouts/Menu";
import Header from "./layouts/Header";
import Main from "./layouts/Main";
import Cursor from "components/common/Cursor";

import { BrowserInterfaceVisibilityProvider } from "contexts/BrowserInterfaceVisibility";

import { BodyScrollLockerProvider } from "contexts/BodyScrollLocker";
import isTouchDevice from "is-touch-device";
import Preloader from "./layouts/Preloader";

import CookiePolicy from "./layouts/CookiePolicy";
interface AppProps {}

const App = (props: AppProps) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const isMac = navigator.platform.toLowerCase().indexOf("mac") !== -1;
    if (window.innerWidth > 1199 && !isMac) {
      document.documentElement.classList.add("custom-scroll");
    }
  }, []);

  // Обработчик события storage для синхронизации языка между вкладками
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "language" && event.newValue) {
        const newLanguage = event.newValue;
        const currentLanguage = i18n.language;

        // Если язык изменился
        if (newLanguage !== currentLanguage) {
          // Изменяем язык
          i18n.changeLanguage(newLanguage);

          // Обновляем URL
          const pathWithoutLang =
            location.pathname === "/en"
              ? ""
              : location.pathname.replace("/en/", "");

          const newPath =
            "/" +
            (newLanguage === "ru" ? "" : newLanguage + "/") +
            (pathWithoutLang.startsWith("/")
              ? pathWithoutLang.substring(1)
              : pathWithoutLang);

          // Перенаправляем на новый URL
          history.push(newPath);
        }
      }
    };

    // Добавляем обработчик события storage
    window.addEventListener("storage", handleStorageChange);

    // Удаляем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [history, location]);

  const hasCursor = !isTouchDevice();

  return (
    <HelmetProvider>
      <Head />
      <BrowserInterfaceVisibilityProvider>
        <BodyScrollLockerProvider>
          <Preloader />

          <Header />
          <Menu />
          <Main />
        </BodyScrollLockerProvider>
      </BrowserInterfaceVisibilityProvider>

      <CookiePolicy />
      {hasCursor ? <Cursor /> : null}
    </HelmetProvider>
  );
};

export default App;
