import ViewPortfolioAag from "components/views/ViewPortfolioItem/ViewPortfolioAag/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "aag",
  component: ViewPortfolioAag,
  title: "aag",
  name: "aag",
  altName: "Investment and construction \n holding company",
  year: "2024",
  awardsList: [],

  link: {
    label: "aag.company",
    href: "https://aag.company/",
  },

  socials: [
    {
      label: "",
      href: "",
    },
  ],

  preview: {
    label: "corporate site of a developer",
    backgroundColor: "#00387A",
    media: {
      type: "video",
      src: "https://vimeo.com/998697276/e03e46295d",
    },
  },

  poolOfWorks: [
    "technical support",
    "new pages",
    "admin panel",
    "UX|UI design",
  ],

  type: "site",
  subtype: "site",

  description: [
    "It is an investment and construction holding company operating in Saint Petersburg and Moscow, engaged in the construction of high-end and premium-class residential complexes.",
    "We needed to refine the user interface and integrate new functional sections.",
  ],
  shortDescription: "Investment and construction holding company",

  seo: {
    title: "Development of a website for a real estate developer",
    description:
      "We created a website for the construction company AAG, specializing in the construction of high-rise buildings. The project included complete development of the backend, admin panel, and frontend updates to enhance functionality and user experience",
    keywords: [
      "development of a website for a developer",
      "case study design",
      "high-rise buildings",
      "multi-story buildings",
      "backend development for real estate developers",
      "admin panel for a developer's website",
      "website for selling apartments",
      "website for a construction company",
    ],
    openGraph: {
      type: "article",
      title: "Development of a website for a real estate developer",
      description:
        "We created a website for the construction company AAG, specializing in the construction of high-rise buildings. The project included complete development of the backend, admin panel, and frontend updates to enhance functionality and user experience",
    },
  },
};

export default porfolioItem;
