import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioAtlanticArticlesPageSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption: [string, string];
  images: ViewPortfolioItemImageData[];
}

const ViewPortfolioAtlanticInnerPagesSection = ({
  caption,
  images,
  ...sectionProps
}: ViewPortfolioAtlanticArticlesPageSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-atlantic-articles-page-section"
    >
      <div className="view-portfolio-atlantic-articles-page-section__container container">
        <header className="view-portfolio-atlantic-articles-page-section__header row">
          <SlideFadeOutWhenInView>
            {({ setTarget, setTriggerEl }) => (
              <div
                ref={setTriggerEl}
                className="col-md-4 offset-md-6 row u-max-w-600"
              >
                <p
                  ref={setTarget()}
                  className="col-11 view-portfolio-item__text"
                >
                  {caption[0]}
                </p>
                <p ref={setTarget()} className="col offset-sm-2">
                  {caption[1]}
                </p>
              </div>
            )}
          </SlideFadeOutWhenInView>
        </header>
        <div className="row gaps--horizontal-medium gaps--vertical-medium">
          <div className="col-sm-11 col-md-6 offset-md-0">
            <ViewPortfolioItemImage
              {...images[0]}
            />
          </div>
          <div className="view-portfolio-atlantic-articles-page-section__space-top-md col-sm-7 offset-sm-2 col-md-3 offset-md-1">
            <ViewPortfolioItemImage
              {...images[1]}
            />
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioAtlanticInnerPagesSection;
