import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";

export interface ViewPortfolioAagProps {}

const ViewPortfolioAag = (props: ViewPortfolioAagProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  const [imageErrors, setImageErrors] = useState<Record<string, boolean>>({});

  const handleImageError = (imageName: string) => {
    setImageErrors((prev) => ({ ...prev, [imageName]: true }));
    console.error(`Failed to load image: ${imageName}`);
  };

  const getImageSrc = (imageName: string, ext: string = "webp") => {
    if (!imageName || imageErrors[imageName]) return "";
    return buildPageImageSrc(imageName, ext);
  };

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-aag"
        caption={[
          t("portfolioItem:aag.about.0"),
          t("portfolioItem:aag.about.1"),
          t("portfolioItem:aag.about.2"),
        ]}
        video={{
          src: "https://vimeo.com/998697276/e03e46295d",
        }}
        style={{
          backgroundColor: "#DEDEE1",
          color: "#1F1F21",
          backgroundPosition: "top left",
          backgroundSize: "cover",
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#DEDEE1" }}
      >
        <RevealWhenInView>
          <img
            src={getImageSrc(t("portfolioItem:aag.section-3.image"))}
            alt={"aag"}
            onError={() =>
              handleImageError(t("portfolioItem:aag.section-3.image"))
            }
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#DEDEE1" }}
        className={
          " container section view-portfolio-aag-section portfolio-item-section--type-aag-4"
        }
      >
        <div className={"row description"}>
          <div className={"col-md-6"}>
            <RevealWhenInView>
              <p>{t("portfolioItem:aag.section-4.description.0")}</p>
            </RevealWhenInView>
          </div>

          <div className={"col-md-5 offset-auto"}>
            <RevealWhenInView>
              <span>{t("portfolioItem:aag.section-4.description.1")}</span>
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-9 offset-md-1"}>
          <RevealWhenInView>
            <div className={"u-ratio"}>
              <VimeoPlayer
                size="fill"
                url={"https://vimeo.com/998697183/4f732637fd"}
                play
                muted
                controls={false}
                loop={true}
              />
            </div>
          </RevealWhenInView>
          <div className={"row"}>
            <div className={"col-md-4"}>
              <RevealWhenInView>
                <img
                  src={getImageSrc(t("portfolioItem:aag.section-4.image"))}
                  alt={"aag"}
                  onError={() =>
                    handleImageError(t("portfolioItem:aag.section-4.image"))
                  }
                />
              </RevealWhenInView>
            </div>

            <div className={"col-md-4 offset-md-auto description-img"}>
              <RevealWhenInView>
                <p>{t("portfolioItem:aag.section-4.description.2")}</p>
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>

      {/* 5ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#19191B", color: "#fff" }}
        className={
          " container view-portfolio-aag-section portfolio-item-section--type-aag-5"
        }
      >
        <div className={"row description"}>
          <div className={"col-md-6"}>
            <RevealWhenInView>
              <p>{t("portfolioItem:aag.section-5.description.0")}</p>
            </RevealWhenInView>
          </div>

          <div className={"col-md-5 offset-auto"}>
            <RevealWhenInView>
              <span>{t("portfolioItem:aag.section-5.description.1")}</span>
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-9 offset-md-1"}>
          <RevealWhenInView>
            <div className={"u-ratio"}>
              <VimeoPlayer
                size="fill"
                url={"https://vimeo.com/998697245/3388e16ae1"}
                play
                muted
                controls={false}
                loop={true}
              />
            </div>
          </RevealWhenInView>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-5.image"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-5.image"))
              }
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#19191B" }}
        className={"row portfolio-item-section--type-aag-6"}
      >
        <div className={"col-half"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-6.image.0"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-6.image.0"))
              }
            />
          </RevealWhenInView>
        </div>
        <div className={"col-half"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-6.image.1"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-6.image.1"))
              }
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#DEDEE1" }}
        className={
          " container view-portfolio-aag-section portfolio-item-section--type-aag-7"
        }
      >
        <div className={"row description"}>
          <div className={"col-md-6"}>
            <RevealWhenInView>
              <p>{t("portfolioItem:aag.section-7.description.0")}</p>
            </RevealWhenInView>
          </div>

          <div className={"col-md-5 offset-auto"}>
            <RevealWhenInView>
              <span>{t("portfolioItem:aag.section-7.description.1")}</span>
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-9 offset-md-1"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-7.image"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-7.image"))
              }
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#DEDEE1" }}
        className={"row portfolio-item-section--type-aag-8"}
      >
        <div className={"col-half"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-8.image.0"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-8.image.0"))
              }
            />
          </RevealWhenInView>
        </div>
        <div className={"col-half"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-8.image.1"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-8.image.1"))
              }
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 9ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#19191B", color: "#fff" }}
        className={
          " container view-portfolio-aag-section portfolio-item-section--type-aag-9"
        }
      >
        <div className={"row description"}>
          <div className={"col-md-6"}>
            <RevealWhenInView>
              <p>{t("portfolioItem:aag.section-9.description.0")}</p>
            </RevealWhenInView>
          </div>

          <div className={"col-md-5 offset-auto"}>
            <RevealWhenInView>
              <span>{t("portfolioItem:aag.section-9.description.1")}</span>
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-7 offset-md-2"}>
          <div className={"img-wrap"}>
            <RevealWhenInView>
              <img
                src={getImageSrc(t("portfolioItem:aag.section-9.image.0"))}
                alt={"aag"}
                onError={() =>
                  handleImageError(t("portfolioItem:aag.section-9.image.0"))
                }
              />
            </RevealWhenInView>
          </div>
          <div className={"row"}>
            <div className={"col-md-4 description-img"}>
              <RevealWhenInView>
                <p>{t("portfolioItem:aag.section-9.description.2")}</p>
              </RevealWhenInView>
            </div>

            <div className={"col-md-4 offset-auto"}>
              <RevealWhenInView>
                <img
                  src={getImageSrc(t("portfolioItem:aag.section-9.image.1"))}
                  alt={"aag"}
                  onError={() =>
                    handleImageError(t("portfolioItem:aag.section-9.image.1"))
                  }
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>

      {/* 10ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#19191B" }}
      >
        <RevealWhenInView>
          <img
            src={getImageSrc(t("portfolioItem:aag.section-10.image"))}
            alt={"aag"}
            onError={() =>
              handleImageError(t("portfolioItem:aag.section-10.image"))
            }
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 11ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#DEDEE1", color: "#1F1F21" }}
        className={
          "container view-portfolio-aag-section portfolio-item-section--type-aag-11"
        }
      >
        <div className={"row description"}>
          <div className={"col-md-6"}>
            <RevealWhenInView>
              <p>{t("portfolioItem:aag.section-11.description.0")}</p>
            </RevealWhenInView>
          </div>

          <div className={"col-md-5 offset-auto"}>
            <RevealWhenInView>
              <span>{t("portfolioItem:aag.section-11.description.1")}</span>
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-7 offset-md-1"}>
          <div className={"img-wrap"}>
            <RevealWhenInView>
              <img
                src={getImageSrc(t("portfolioItem:aag.section-11.image.0"))}
                alt={"aag"}
                onError={() =>
                  handleImageError(t("portfolioItem:aag.section-11.image.0"))
                }
              />
            </RevealWhenInView>
          </div>

          <div className={"img-wrap"}>
            <RevealWhenInView>
              <img
                src={getImageSrc(t("portfolioItem:aag.section-11.image.1"))}
                alt={"aag"}
                onError={() =>
                  handleImageError(t("portfolioItem:aag.section-11.image.1"))
                }
              />
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-7 offset-md-3 right-img"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-11.image.2"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-11.image.2"))
              }
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 12ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#19191B", color: "#fff" }}
        className={
          "container view-portfolio-aag-section portfolio-item-section--type-aag-12"
        }
      >
        <div className={"row description"}>
          <div className={"col-md-6"}>
            <RevealWhenInView>
              <p>{t("portfolioItem:aag.section-12.description.0")}</p>
            </RevealWhenInView>
          </div>

          <div className={"col-md-5 offset-auto"}>
            <RevealWhenInView>
              <span>{t("portfolioItem:aag.section-12.description.1")}</span>
            </RevealWhenInView>
          </div>
        </div>

        <div className={"col-md-9 offset-md-1"}>
          <RevealWhenInView>
            <img
              src={getImageSrc(t("portfolioItem:aag.section-12.image"))}
              alt={"aag"}
              onError={() =>
                handleImageError(t("portfolioItem:aag.section-12.image"))
              }
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 13ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: "#19191B" }}
      >
        <RevealWhenInView>
          <img
            src={getImageSrc(t("portfolioItem:aag.section-13.image"))}
            alt={"aag"}
            onError={() =>
              handleImageError(t("portfolioItem:aag.section-13.image"))
            }
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioAag;
