import ViewPortfolioCryptoro from "components/views/ViewPortfolioItem/ViewPortfolioCryptoro/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "../../helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "cryptoro",
  component: ViewPortfolioCryptoro,
  title: "cryptoro",
  name: "cryptoro",
  altName: "Интернет-магазин\nхолодных кошельков\nдля криптовалют",
  year: 2023,
  awardsList: [],

  link: {
    label: "cryptoro.ru",
    href: "https://cryptoro.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/205014905/Cryptoro",
    },
  ],

  preview: {
    label: "интернет-магазин",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        file: "1.webp",
      }),
      toSrc: buildPublicImageSrc({
        file: "2.webp",
      }),
    },
  },

  poolOfWorks: ["UX|UI design", "Page making"],

  type: "site",
  subtype: "site",

  description: [
    "компания, которая делает защиту цифровых активов простой, обеспечивая безопасность криптовалютных сбережений",
    "Нам требовалось показать технологичность и надежность компании",
  ],

  shortDescription:
    "компания, которая делает защиту цифровых активов простой, обеспечивая безопасность криптовалютных сбережений",
  seo: {
    title:
      "Разработка интернет-магазина оборудования для майнинга и хранения криптовалют Cryptoro",
    description:
      "Узнайте, как мы создали интернет-магазин Cryptoro, предлагающий оборудование для хранения, майнинга и трейдинга криптовалют. Подробный кейс, этапы дизайна, разработки сайта по крипте и результаты",
    keywords: [
      "разработка интернет-магазина",
      "Cryptoro",
      "оборудование для майнинга",
      "онлайн магазин криптовалют",
      "хранение криптовалют",
      "кейс сайта по крипте",
      "портфолио майнинг",
      "создание интернет-магазина",
      "магазин криптокошельков",
    ],
    openGraph: {
      type: "article",
      title:
        "Разработка интернет-магазина оборудования для майнинга и хранения криптовалют Cryptoro",
      description:
        "Узнайте, как мы создали интернет-магазин Cryptoro, предлагающий оборудование для хранения, майнинга и трейдинга криптовалют. Подробный кейс, этапы дизайна, разработки сайта по крипте и результаты",
    },
  },
};

export default porfolioItem;
