import React, { useState } from "react";
import classNames from "classnames";
import Image from "components/common/Image";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import ToggleSwitch from "components/common/ToggleSwitch";
import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import {useTranslation} from "react-i18next";
interface ViewPortfolioGosaptekaThemeSectionProps { }

const themeStyle = {
  light: {
    color: "7B7F8B",
    backgroundColor: "#eef0ef",
  },
  dark: {
    color: "#4b535b",
    backgroundColor: "#131313",
  },
};

const ViewPortfolioGosaptekaThemeSection = (
  props: ViewPortfolioGosaptekaThemeSectionProps
) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  const [theme, setTheme] = useState<"light" | "dark">("dark");
  const [toggled, setToggled] = useState(theme === "dark");

  const handleToggle = () => {
    setToggled(!toggled);

    if (!toggled) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const currentThemeStyle = themeStyle[theme];

  return (
    <ViewPortfolioItemSection
      style={currentThemeStyle}
      className="view-portfolio-item-section view-portfolio-item-section--layout-gosapteka-theming"
    >
      <div className="view-portfolio-item-section__container container">
        <div className="view-portfolio-item-section__inner row">
          <div className="view-portfolio-item-section__col-theme-preview-image offset-1 col-8 col-lg-4">

            <Image className={classNames("view-portfolio-item-section__changing-image", { "is-active": theme === "dark" })} src={buildPageImageSrc("screen-3-1")} width="auto" />
            <Image className={classNames("view-portfolio-item-section__changing-image", { "is-active": theme === "light" })} src={buildPageImageSrc("screen-3-2")} width="auto" />

          </div>

          <div className="view-portfolio-item-section__col-theme-switcher offset-lg-1 col-lg">
            <RevealWhenInView>
              <p className="view-portfolio-item-section__theme-switcher-wrapper">
                <span>{t('portfolioItem:gosapteka.theme-section.caption-light')}</span>
                <ToggleSwitch
                  className="view-portfolio-item-section__theme-switcher"
                  toggled={toggled}
                  onToggle={handleToggle}
                />
                <span>{t('portfolioItem:gosapteka.theme-section.caption-dark')}</span>
              </p>
            </RevealWhenInView>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioGosaptekaThemeSection;
