import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { Image } from "types/global";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

export interface ViewPortfolioWomanSportSiteAccountPageSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption: [string, string];
  images: [Image, Image, Image, Image, Image] | Image[];
}

const ViewPortfolioWomanSportSiteAccountPageSection = ({
  caption,
  images,
  ...sectionProps
}: ViewPortfolioWomanSportSiteAccountPageSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-gallery-wss-2"
    >
      <figure className="view-portfolio-item-section__container container">
        <figcaption className="view-portfolio-item-section__header">
          <RevealWhenInView>
            <div className="row">
              <div className="offset-md-5 col row u-max-w-480">
                <p className="col-11">{caption[0]}</p>
                <p className="col-11 offset-sm-3 col-sm">{caption[1]}</p>
              </div>
            </div>
          </RevealWhenInView>
        </figcaption>
        <div className="view-portfolio-item-section__body row">
          <div className="order-1 order-sm-1 col-11 row">
            <ViewPortfolioItemImage
              {...images[0]}
              className="col-11 offset-md-0 col-md-7"
            />
          </div>
          <ViewPortfolioItemImage
            {...images[1]}
            className="order-4 order-sm-2 col col-sm-3 offset-md-2 col-md-2"
          />
          <ViewPortfolioItemImage
            {...images[2]}
            className="order-2 order-sm-3 col-11 col-sm-8 offset-md-0 col-md-7"
          />
          <ViewPortfolioItemImage
            {...images[3]}
            className="order-3 order-sm-4 col-11 col-sm-8 offset-md-1 col-md-7"
          />
          <ViewPortfolioItemImage
            {...images[4]}
            className="order-5 order-sm-5 col col-sm-3 offset-md-0 col-md-2"
          />
        </div>
      </figure>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportSiteAccountPageSection;
