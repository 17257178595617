/**
 * Возвращает прогресс прокрутки элемента во вьюпорте.
 *
 * @param el - Элемент для которого нужно найти прогресс прокрутки.
 * @returns Число от 0 до 1
 */
export const getElementScrollProgress = (
  el: HTMLElement,
  options: {
    raw?: boolean;
    /**
     * Если true, тогда прогресс будет считаться только тогда, когда элемент находиться полностью во вьюпорте
     */
    fullInView?: boolean;
  } = {}
): number => {
  const elClientRect = el.getBoundingClientRect();
  const elTop = Math.round(elClientRect.top);
  const elHeight = Math.round(elClientRect.height);

  let scrollHeight = elHeight;
  let scrollPos = window.innerHeight - elTop;

  if (options.fullInView) {
    scrollHeight -= window.innerHeight;
    scrollPos -= window.innerHeight;
  } else {
    scrollHeight += window.innerHeight;
  }

  let result = scrollPos / scrollHeight;

  if (!options.raw) {
    if (isNaN(result) || result < 0) {
      result = 0;
    } else if (result > 1) {
      result = 1;
    }
  }

  return result;
};

/**
 * Подгружает картинку по пути src
 */
export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.addEventListener(
      "load",
      () => {
        setTimeout(() => {
          resolve(img);
        }, 100)
      },
      { once: true }
    );

    img.addEventListener(
      "error",
      () => {
        reject(img);
      },
      { once: true }
    );

    img.src = src;
  });
};

export const loadImages = (sources: string[]) => //Promise.all(sources.map(async source => loadImage(source)));
  Promise.all(sources.map(loadImage));

/**
 * Возвращает true если элемент находится во вьюпорте, false в противном случае
 *
 * @param element - элемент
 */
export const isElementInViewport = (el: HTMLElement | Element, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight: viewportHeight, innerWidth: viewportWidth } = window;

  return partiallyVisible
    ? ((top > 0 && top < viewportHeight) || (bottom > 0 && bottom < viewportHeight)) &&
    ((left > 0 && left < viewportWidth) || (right > 0 && right < viewportWidth))
    : top >= 0 && left >= 0 && bottom <= viewportHeight && right <= viewportWidth;
};


export const hasClass = (el: HTMLElement, className: string | string[]): boolean => {
  return new RegExp(`(^| )(${Array.isArray(className) ? className.join("|") : className})($| )`, 'g').test(el.className);
}