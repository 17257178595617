import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import { ViewSpecificPortfolioItemProps } from "data/portfolio/types";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemRelatedSection from "../ViewPortfolioItemRelatedSection";

import ViewPortfolioWomanSportCardsSection from "./ViewPortfolioWomanSportBrandingCardsSection";
import ViewPortfolioWomanSportBrandingProductsSection from "./ViewPortfolioWomanSportBrandingProductsSection";
import { useTranslation } from "react-i18next";

const ViewPortfolioWomanSportBranding = (
  props: ViewSpecificPortfolioItemProps
) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        name={data.name}
        description={data.description}
        image={{
          isLogo: true,
          src: buildPageImageSrc("about", "svg"),
          alt: data.name,
        }}
        style={{
          backgroundColor: "#fff",
        }}
      />
      <ViewPortfolioWomanSportCardsSection
        size={{ top: "md" }}
        style={{ backgroundColor: "#252525", color: "#fff" }}
        dynamicBackgroundColor={false}
        caption={[
          t("portfolioItem:woman-sport-brand.cards-section.0"),
          t("portfolioItem:woman-sport-brand.cards-section.1"),
        ]}
        image={{
          src: buildPageImageSrc("screen-3", "png"),
          sources: [
            {
              type: "webp",
              srcSet: buildPageImageSrc("screen-3"),
            },
          ],
        }}
      />
      <ViewPortfolioWomanSportBrandingProductsSection
        products={[
          {
            image: {
              src: buildPageImageSrc("screen-4", "png"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("screen-4"),
                },
              ],
            },
            caption: t(
              "portfolioItem:woman-sport-brand.products-section.screen-4"
            ),
          },
          {
            image: {
              first: {
                src: buildPageImageSrc("screen-5-1", "png"),
                sources: [
                  {
                    type: "webp",
                    srcSet: buildPageImageSrc("screen-5-1"),
                  },
                ],
              },
              second: {
                src: buildPageImageSrc("screen-5-2", "png"),
                sources: [
                  {
                    type: "webp",
                    srcSet: buildPageImageSrc("screen-5-2"),
                  },
                ],
              },
            },
            caption: t(
              "portfolioItem:woman-sport-brand.products-section.screen-5"
            ),
          },
          {
            image: {
              src: buildPageImageSrc("screen-6", "png"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("screen-6"),
                },
              ],
            },
            caption: t(
              "portfolioItem:woman-sport-brand.products-section.screen-6"
            ),
          },
          {
            image: {
              src: buildPageImageSrc("screen-7", "png"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("screen-7"),
                },
              ],
            },
            caption: t(
              "portfolioItem:woman-sport-brand.products-section.screen-7"
            ),
          },
          {
            image: {
              src: buildPageImageSrc("screen-8", "png"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("screen-8"),
                },
              ],
            },
          },
          {
            image: {
              src: buildPageImageSrc("screen-9", "jpg"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("screen-9"),
                },
              ],
            },
            caption: t(
              "portfolioItem:woman-sport-brand.products-section.screen-9"
            ),
          },
          {
            image: {
              src: buildPageImageSrc("screen-10", "jpg"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("screen-10"),
                },
              ],
            },
            caption: [
              t("portfolioItem:woman-sport-brand.products-section.screen-11.0"),
              t("portfolioItem:woman-sport-brand.products-section.screen-11.1"),
              t("portfolioItem:woman-sport-brand.products-section.screen-11.2"),
              t("portfolioItem:woman-sport-brand.products-section.screen-11.3"),
              t("portfolioItem:woman-sport-brand.products-section.screen-11.4"),
            ],
          },
        ]}
      />

      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          id={data.relatedId}
          style={{ backgroundColor: "#E10066", color: "#fff" }}
          preview={{
            frame: "#A8004C",
            image: {
              src: buildPageImageSrc("related", "jpg"),
              sources: [
                {
                  type: "webp",
                  srcSet: buildPageImageSrc("related"),
                },
              ],
            },
          }}
        />
      )}
    </>
  );
};

export default ViewPortfolioWomanSportBranding;
