import { MorphSvgWord } from "components/common/MorphSvg";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Section from "components/layouts/Section";
import { useEffect, useRef, useState } from "react";

import gsap from "gsap";

import s from "./index.module.scss";
import List from "components/common/List";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";

interface ViewDeveloperSitesLeadSectionProps {
  locale: string;
}

const ServicesItemLeadSection = (props: ViewDeveloperSitesLeadSectionProps) => {
  const [morph, setMorph] = useState(false);

  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorph(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const titleElem = titleRef.current!;

    const timeline = gsap.timeline({
      defaults: { ease: "ease-out", duration: 0.6 },
    });
    const isTablet = matchMedia("(min-width: 768px)").matches;

    if (!isTablet) {
      timeline.from(titleElem, {
        y: 15,
        opacity: 0,
        delay: 0.5,
      });
    }
  }, [morph]);

  const { data } = useViewServicesItemContext();
  const sectionData = data.leadSection;

  if (!sectionData) {
    return null;
  }

  console.log(
    "ServicesItemLeadSection - данные о клиентах:",
    sectionData.ourClients
  );

  return (
    <DynamicBackgroundTrigger>
      <Section
        isLead
        className={
          s["services-item-lead-section"] +
          " background--dark-white " +
          s[data.id]
        }
      >
        <div
          className={
            "section-container container row " +
            s["services-item-lead-section__container"]
          }
        >
          <div>
            {/* header */}
            <div className="row col-11">
              <RevealWhenInView>
                {typeof sectionData.title === "string" ? (
                  <h1
                    ref={titleRef}
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: sectionData.title }}
                  />
                ) : (
                  <h1 ref={titleRef} className={s.title}>
                    {sectionData.title}
                  </h1>
                )}
              </RevealWhenInView>
            </div>
            {/* body */}
            <div
              className={"row col-11 " + s["services-item-lead-section__body"]}
            >
              <div className={"col-lg-4 col-md-5 col-9 " + s["section-reveal"]}>
                <RevealWhenInView>
                  <span
                    ref={titleRef}
                    className={"heading-4 italic-text " + s["service-heading"]}
                  >
                    {sectionData.description}
                  </span>
                </RevealWhenInView>
              </div>
              {sectionData.subdescription && (
                <div
                  className={
                    "offset-xl-1 offset-md-4 offset-3 col-xl-3 col-md-7 col-auto " +
                    s["services-item-lead-section__subdescription"]
                  }
                >
                  <RevealWhenInView>
                    <span ref={titleRef} className={"heading-3 side-brackets"}>
                      {sectionData.subdescription}
                    </span>
                  </RevealWhenInView>
                </div>
              )}
            </div>
          </div>
          <div
            className={"row col-11 " + s["services-item-lead-section__footer"]}
          >
            <div
              className={
                "col-xl-4 col-md-9 col-11 heading-4 italic-text row order-xl-1 order-2 " +
                s["services-item-lead-section__works-company"]
              }
            >
              <span className="col-md-auto col-11 heading-3 side-brackets">
                {props.locale === "en" ? (
                  <>
                    our
                    <br />
                    clients
                  </>
                ) : (
                  <>
                    нам
                    <br />
                    доверяют
                  </>
                )}
              </span>
              <div className="col-lg-8 col-md-9 col-11 row">
                {sectionData.ourClients?.map((item, index) => {
                  const itemName = typeof item === "string" ? item : item.src;
                  // Кодируем специальные символы в имени файла
                  const encodedName = itemName.replace(/\+/g, "%2B");
                  const imgSrc = `/images/services/clients/${encodedName}.svg`;

                  console.log("Путь к изображению клиента:", imgSrc);

                  return (
                    <img
                      key={index}
                      className={s["our-clients-img"]}
                      style={{
                        transform: `translateX(-${10 * index}px)`,
                        zIndex: `${10 - index}`,
                      }}
                      src={imgSrc}
                      alt={typeof item === "string" ? item : item.alt}
                      onError={(e) => {
                        console.error(`Ошибка загрузки изображения: ${imgSrc}`);
                        // Можно добавить запасное изображение
                        // e.currentTarget.src = '/images/placeholder.svg';
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <RevealWhenInView
              className={
                "offset-xl-1 offset-md-4 offset-0 col-xl-4 col-md-7 col-11 heading-4 italic-text order-xl-2 order-1"
              }
            >
              <div className={s["list-container"]}>
                {sectionData.works?.map((work) => {
                  return (
                    <SlideFadeOutWhenInView>
                      {({ setTriggerEl, setTarget }) => (
                        <div
                          ref={setTriggerEl}
                          className={"row " + s["title-list"]}
                        >
                          <div className="col-xl-4 col-md-3 col-sm-3 col-4">
                            <p
                              className={
                                "heading-3 " +
                                (work.title ? "side-brackets" : "")
                              }
                            >
                              {work.title}
                            </p>
                          </div>
                          <List
                            className="col-7"
                            ref={setTarget({ selector: ".list__item" })}
                            items={work.items}
                            gap="sm"
                          >
                            {(item) => <span className="col-11">{item}</span>}
                          </List>
                        </div>
                      )}
                    </SlideFadeOutWhenInView>
                  );
                })}
              </div>
            </RevealWhenInView>
            <div
              className={
                "offset-auto order-xl-3 order-3 " + s["aunchor-button"]
              }
            >
              <RevealWhenInView>
                <ScrollDownButton
                  scrollTo=".services-item-swiper-section"
                  duration={0.7}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default ServicesItemLeadSection;
