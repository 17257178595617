import Image, { ImageConfig } from "components/common/Image";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

interface ViewPortfolioDomaVekaGallerySectionProps
  extends ViewPortfolioItemSectionConfigProps {
  images: ImageConfig[];
}

const ViewPortfolioDomaVekaGallerySection = ({
  images,
  ...sectionProps
}: ViewPortfolioDomaVekaGallerySectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-doma-veka-gallery-section"
    >
      <div className="container">
        <div className="row">
          <List
            className="col-11"
            itemClassName="view-portfolio-doma-veka-gallery-section__item"
            items={images}
          >
            {(image) => (
              <RevealWhenInView>
                <Image {...image} />
              </RevealWhenInView>
            )}
          </List>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioDomaVekaGallerySection;
