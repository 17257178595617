import Print from "components/common/Print";
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

export interface ViewPortfolioSkessEquipmentCardSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  images: ViewPortfolioItemImageData[];
  caption: [string, string];
}

const ViewPortfolioSkessEquipmentCardSection = ({
  images,
  caption,
  ...sectionProps
}: ViewPortfolioSkessEquipmentCardSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-skess-equipment-card-section u-overflow-hidden"
    >
      <div className="container">
        <div className="row gaps--horizontal-medium gaps--horizontal-extra-large gaps--vertical-extra-large">
          <ViewPortfolioItemImage
            className="view-portfolio-skess-equipment-card-section__col-with-image view-portfolio-skess-equipment-card-section__col-with-image--lg-press-down order-2 order-lg-1 offset-3 offset-lg-0 col-5 col-lg-3"
            {...images[0]}
          />

          <div className="view-portfolio-skess-equipment-card-section__col-with-image-and-description order-1 order-lg-2 offset-md-1 offset-lg-0 col-md-9 col-lg-8">
            <div className="row">
              <ViewPortfolioItemImage className="col-11" {...images[1]} />

              <div className="view-portfolio-skess-equipment-card-section__col-with-description view-portfolio-skess-equipment-card-section__col-with-description--desktop col offset-1">
                <SlideFadeOutWhenInView>
                  {({ setTarget, setTriggerEl }) => (
                    <div ref={setTriggerEl} className="row u-max-w-600">
                      <p ref={setTarget()} className="col-11">
                        <Print translate={false} data={caption[0]} />
                      </p>
                      <p ref={setTarget()} className="col offset-2">
                        <Print translate={false} data={caption[1]} />
                      </p>
                    </div>
                  )}
                </SlideFadeOutWhenInView>
              </div>
            </div>
          </div>

          <div className="view-portfolio-skess-equipment-card-section__col-with-description view-portfolio-skess-equipment-card-section__col-with-description--mobile order-3 col-md-9 offset-md-1">
            <SlideFadeOutWhenInView>
              {({ setTarget, setTriggerEl }) => (
                <div ref={setTriggerEl} className="row u-max-w-600">
                  <p ref={setTarget()} className="col-11">
                    <Print translate={false} data={caption[0]} />

                  </p>
                  <p ref={setTarget()} className="col offset-md-2">
                    <Print translate={false} data={caption[1]} />
                  </p>
                </div>
              )}
            </SlideFadeOutWhenInView>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioSkessEquipmentCardSection;
