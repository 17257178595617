import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemMobileVersionSection from '../ViewPortfolioItemMobileVersionSection';
import ViewPortfolioItemParallaxSection from '../ViewPortfolioItemParallaxSection';
import ViewPortfolioSkessEquipmentCardSection from './ViewPortfolioSkessEquipmentCardSection';
import ViewPortfolioSkessGallerySection from './ViewPortfolioSkessGallerySection';
import { useTranslation } from 'react-i18next';

export interface ViewPortfolioSkessProps {}

const ViewPortfolioSkess = (props: ViewPortfolioSkessProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);

  const { t } = useTranslation();
  console.log(t('portfolioItem:skess.section-3.0'));
  return (
    <>
      <ViewPortfolioItemAboutSection
        name={data.name}
        video={{
          src: 'https://vimeo.com/542710891',
        }}
        description={data.description}
        caption={[
          t('portfolioItem:skess.about.0'),
          t('portfolioItem:skess.about.1'),
          t('portfolioItem:skess.about.2'),
        ]}
        style={{
          backgroundColor: '#fff',
        }}
      />
      <ViewPortfolioSkessEquipmentCardSection
        caption={[
          t('portfolioItem:skess.section-3.0'),
          t('portfolioItem:skess.section-3.1'),
        ]}
        images={[
          {
            src: buildPageImageSrc('screen-3-1'),
          },
          {
            src: buildPageImageSrc('screen-3-2'),
          },
        ]}
        size={{
          top: 'lg',
          bottom: 'xl',
        }}
      />
      <ViewPortfolioSkessGallerySection
        style={{ backgroundColor: '#191722', color: '#fff' }}
        size="lg"
        images={Array.from({ length: 8 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-4-${i + 1}`),
          };
        })}
      />
      <ViewPortfolioItemParallaxSection
        style={{ backgroundColor: '#fff' }}
        dynamicBackgroundColor={false}
        image={{ src: buildPageImageSrc('screen-6') }}
      />
      {data.pageContent?.sections.mobileVersion && (
        <ViewPortfolioItemMobileVersionSection
          portfolioId={data.id}
          style={data.pageContent?.sections.mobileVersion.theme}
          {...data.pageContent?.sections.mobileVersion}
        />
      )}
    </>
  );
};

export default ViewPortfolioSkess;
