import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { Image } from "types/global";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

import { Swiper, SwiperSlide } from "swiper/react";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";
import Print from "components/common/Print";

export interface ViewPortfolioWomanSportSiteAllPagesSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption: string;
  description: string;
  images: Image[][];
}

const ViewPortfolioWomanSportSiteAllPagesSection = ({
  caption,
  description,
  images,
  ...sectionProps
}: ViewPortfolioWomanSportSiteAllPagesSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-wss-all-pages"
    >
      <div className="view-portfolio-item-section__container">
        <header className="view-portfolio-item-section__header container">
          <div className="row">
            <div className="col-md-2">
              <RevealWhenInView>
                <p className="label side-brackets">{caption}</p>
              </RevealWhenInView>
            </div>
            <div className="offset-md-1 col-md-8 u-max-w-480 u-text-indent-md-quarter">
              <RevealWhenInView>
                <p><Print data={description} /></p>
              </RevealWhenInView>
            </div>
          </div>
        </header>
        {/* <div className="view-portfolio-item-section__body row">
          {images.map((imagesRows, i) => {
            return (
              <div className="col-11" key={i}>
                <div className="view-portfolio-item-section__body-subrow row">
                  {imagesRows.map((image, l) => {
                    return (
                      <div className="col" key={l}>
                        <RevealWhenInView>
                          <img className="u-w-full" {...image} />
                        </RevealWhenInView>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div> */}

        <div className="view-portfolio-item-section__body row">
          {images.map((imagesRows, i) => {
            return (
              <Swiper
                loop
                key={i}
                speed={3000}
                className="view-portfolio-item-section__slider"
                slidesPerView={3}
                spaceBetween={20}
                breakpoints={{
                  991: {
                    // loopAdditionalSlides: 6,
                    slidesPerView: 6,
                  },
                  1440: {
                    // loopAdditionalSlides: 8,
                    slidesPerView: 8,
                  },
                }}
                autoplay={{
                  delay: 0,
                  stopOnLastSlide: false,
                  waitForTransition: true,
                  reverseDirection: !!(i % 2),
                  disableOnInteraction: false,
                }}
              >
                {imagesRows.map((image, l) => {
                  return (
                    <SwiperSlide key={l}>
                      <ViewPortfolioItemImage className="col" {...image} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            );
          })}
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportSiteAllPagesSection;
/*
<div className="view-portfolio-item-section__body row">
          {images.map((imagesRows, i) => {
            return (
              <Swiper
                loop
                key={i}
                speed={400}
                className="view-portfolio-item-section__slider container"
                slidesPerView="auto"
              >
                {imagesRows.map((image, l) => {
                  return (
                    <SwiperSlide key={i} className="">
                      <div className="col" key={l}>
                        <RevealWhenInView>
                          <img className="u-w-full" {...image} />
                        </RevealWhenInView>
                      </div>
                    </SwiperSlide>
                  );
                })}

                </Swiper>
                );
              })}
            </div>
          </div>

*/
