export const ROOT_PATH = process.env.PUBLIC_URL;
export const ASSETS_PATH = `${ROOT_PATH}/assets`;
export const IMAGES_PATH = `${ROOT_PATH}/images`;
export const VIDEOS_PATH = `${ROOT_PATH}/videos`;
export const PORTFOLIOS_PATH = `${ROOT_PATH}/portfolios`;
export const VIEWS_PATH = `${ASSETS_PATH}/views`;

interface GetPublicFileSrcBuilderBuilderConfig {
  root: string;
  defaults?: {
    file?: { name?: string; ext?: string };
    path?: string[] | string;
  };
}

export interface FileFullName {
  name: string;
  ext: string;
}

export interface PublicFileSrcBuilderConfig {
  file: FileFullName | string;
  path?: string[] | string;
}

export const getPublicFileSrcBuilder = (
  builderConfigOrRoot: GetPublicFileSrcBuilderBuilderConfig | string
) => {
  const root =
    typeof builderConfigOrRoot === "string"
      ? builderConfigOrRoot
      : builderConfigOrRoot.root;

  return (configOrFile: PublicFileSrcBuilderConfig | string) => {
    let path: string[] | string = "";
    let file: FileFullName = {
      name: "",
      ext: "",
    };

    if (
      typeof builderConfigOrRoot === "object" &&
      builderConfigOrRoot.defaults
    ) {
      const { path: defaultPath, file: defaultFile } =
        builderConfigOrRoot.defaults;

      if (defaultPath) {
        path = defaultPath;
      }

      if (defaultFile) {
        file = {
          ...file,
          ...defaultFile,
        };
      }
    }

    if (typeof configOrFile === "object") {
      if (configOrFile.path) {
        path = configOrFile.path;
      }

      if (typeof configOrFile.file === "object") {
        file = {
          ...file,
          ...configOrFile.file,
        };
      }
    }

    let pathStr = Array.isArray(path) ? path.join("/") : path;
    let fileStr: string;

    if (typeof configOrFile === "object") {
      if (typeof configOrFile.file === "string") {
        fileStr = configOrFile.file;
      } else {
        fileStr = `${file.name}.${file.ext}`;
      }
    } else {
      fileStr = configOrFile;
    }

    if (pathStr) {
      pathStr += "/";
    }

    const result = `${root}/${pathStr}${fileStr}`;
    console.log("Generated path:", result);
    return result;
  };
};

export const buildPublicImageSrc = getPublicFileSrcBuilder(IMAGES_PATH);
export const buildPublicVideoSrc = getPublicFileSrcBuilder({
  root: VIDEOS_PATH,
  defaults: { file: { ext: "mp4" } },
});

// export const buildPublicPortfolioFileSrc = getPublicFileSrcBuilder(PORTFOLIOS_PATH);

// export const buildSpecificPortfolioItemFileScr = (portfolioItemId: string | number, config: PublicFileSrcBuilderConfig) => {
//   return buildPublicPortfolioFileSrc({

//   });
// }
