import { RefObject, useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { PortfolioItemMediaTriggerProp } from "./PortfolioItemPreview";
import VimeoPlayer from "components/common/VimeoPlayer";
import { EffectTrigger } from "types/global";

export interface PortfolioItemPreviewVideoData {
  src: string;
  poster?: string;
}

interface PortfolioItemPreviewVideoProps {
  src?: string;
  trigger?: EffectTrigger;
  triggerElRef?: RefObject<HTMLElement>;
}

const PortfolioItemPreviewVideo = ({
  src = "",
  triggerElRef,
}: PortfolioItemPreviewVideoProps) => {
  const [play, setPlay] = useState(false);
  const playerElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const triggerEl = triggerElRef
      ? triggerElRef.current!
      : playerElRef.current!;

    let scrollTrigger: gsap.plugins.ScrollTriggerInstance;

    scrollTrigger = ScrollTrigger.create({
      trigger: triggerEl,
      start: "top+=50% bottom",
      end: "bottom-=50% top",
      onToggle: ({ isActive }) => {
        if (isActive) {
          setPlay(true);
        } else {
          setPlay(false);
        }
      },
    });

    return () => {
      scrollTrigger.kill();
    };
  }, [triggerElRef]);

  return (
    <VimeoPlayer
      ref={playerElRef}
      size="fill"
      play={play}
      url={src}
      loop
      muted
      controls={false}
    />
  );
};

export default PortfolioItemPreviewVideo;
