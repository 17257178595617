import {
  Redirect,
  RouteComponentProps,
  useParams,
  useHistory,
} from "react-router-dom";
import { useMemo, useRef, useEffect, useState } from "react";
import { notFound as notFoundRoute } from "routes";
import ViewPortfolioItemNextSection from "./ViewPortfolioItemNextSection";

import {
  PortfolioItemData,
  PortfolioItemDataWithComponent,
} from "data/portfolio/types";
import portfolio from "data/portfolio";
import {
  getPublicFileSrcBuilder,
  IMAGES_PATH,
  PublicFileSrcBuilderConfig,
} from "helpers/path";
import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "contexts/helpers";
import ViewPortfolioItemLeadSection from "./ViewPortfolioItemLeadSection";

import Head from "components/layouts/Head";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import { memo } from "react";
import i18n from "i18next";
import { LangCodeType } from "i18n/types";
import { ReactNode } from "react";

interface ContextValue {
  data: PortfolioItemData;
  getImageSrc: (configOrFile: string | PublicFileSrcBuilderConfig) => string;
}

const Context = createSafeContext<ContextValue>();

export const useViewPortfolioItemContext = () => useSafeContext(Context);
export const ViewPortfolioItemConsumer = createSafeConsumer(Context);

export interface ViewPortfolioItemProps extends RouteComponentProps {}

export type {
  ViewSpecificPortfolioItemProps,
  ViewSpecificPortfolioItem,
} from "data/portfolio/types";

const ViewPortfolioItem = (props: ViewPortfolioItemProps) => {
  const params = useParams<{ portfolioItemId: string; locale?: string }>();
  const history = useHistory();
  const viewRef = useRef<HTMLDivElement>(null);
  const [forceUpdate, setForceUpdate] = useState(0);

  // Устанавливаем язык на основе параметра locale из URL
  useEffect(() => {
    if (params.locale === "en" && i18n.language !== "en") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else if (!params.locale && i18n.language !== "ru") {
      i18n.changeLanguage("ru");
      localStorage.setItem("language", "ru");
    }
  }, [params.locale]);

  // Обработчик события storage для синхронизации языка между вкладками
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "language" && event.newValue) {
        const newLanguage = event.newValue;
        const currentLanguage = i18n.language;

        // Если язык изменился
        if (newLanguage !== currentLanguage) {
          // Изменяем язык
          i18n.changeLanguage(newLanguage);

          // Обновляем URL
          const pathWithoutLang =
            window.location.pathname === "/en"
              ? ""
              : window.location.pathname.replace("/en/", "");

          const newPath =
            "/" +
            (newLanguage === "ru" ? "" : newLanguage + "/") +
            (pathWithoutLang.startsWith("/")
              ? pathWithoutLang.substring(1)
              : pathWithoutLang);

          // Перенаправляем на новый URL
          history.push(newPath);

          // Принудительно обновляем компонент для перезагрузки данных
          setForceUpdate((prev) => prev + 1);
        }
      }
    };

    // Добавляем обработчик события storage
    window.addEventListener("storage", handleStorageChange);

    // Удаляем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [history]);

  const portfolioItem = useMemo(() => {
    const result: {
      current?: PortfolioItemDataWithComponent;
      next?: PortfolioItemDataWithComponent;
    } = {};

    //текущий язык на основе параметра locale из URL
    const language = params.locale === "en" ? "en" : "ru";

    const currentPortfolioIndex = portfolio[language].findIndex(
      (portfolioItemData) => portfolioItemData.id === params.portfolioItemId
    );

    let portfolioItems = portfolio[language];

    if (currentPortfolioIndex !== -1) {
      const current = portfolioItems[currentPortfolioIndex];
      let nextPortfolioItemIndex = currentPortfolioIndex + 1;

      if (nextPortfolioItemIndex >= portfolio[language].length) {
        nextPortfolioItemIndex = 0;
      }

      if (
        portfolioItems[nextPortfolioItemIndex].id.length === 0 &&
        nextPortfolioItemIndex + 1 < portfolio[language].length
      ) {
        nextPortfolioItemIndex++;
      }

      const next = portfolioItems[nextPortfolioItemIndex];

      return {
        current,
        next,
      };
    }

    return result;
  }, [params.portfolioItemId, params.locale, forceUpdate]);

  if (portfolioItem.current) {
    const SpecificPortfolioItemView = portfolioItem.current.component;

    const getImageSrc = getPublicFileSrcBuilder(
      `${IMAGES_PATH}/portfolios/${portfolioItem.current.id}`
    );

    return (
      <Context.Provider
        value={{
          data: portfolioItem.current,
          getImageSrc: getImageSrc,
        }}
      >
        <Head
          seo={{
            ...portfolioItem.current.seo,
            alternative:
              i18n.language === "ru"
                ? `https://sagirov.com/en/portfolio/${portfolioItem.current.id}`
                : `https://sagirov.com/portfolio/${portfolioItem.current.id}`,
          }}
        />

        <View ref={viewRef} className="view view--portfolio-item">
          <ViewPortfolioItemLeadSection />

          <SpecificPortfolioItemView data={portfolioItem.current} />

          {portfolioItem.next && (
            <ViewPortfolioItemNextSection portfolioItem={portfolioItem.next} />
          )}

          <Footer />
        </View>
      </Context.Provider>
    );
  }

  return <Redirect to={notFoundRoute.path} />;
};

export default memo(ViewPortfolioItem);
