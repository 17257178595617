import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import ViewPortfolioWomanSportSiteAccountPageSection from "./ViewPortfolioWomanSportSiteAccountPageSection";
import ViewPortfolioWomanSportSiteTrainerAccountSection from "./ViewPortfolioWomanSportSiteTrainerAccountSection";
import ViewPortfolioWomanSportSiteElementsSection from "./ViewPortfolioWomanSportSiteElementsSection";
import ViewPortfolioWomanSportSiteMainPagesSection from "./ViewPortfolioWomanSportSiteMainPagesSection";
import ViewPortfolioItemMobileVersionSection from "../ViewPortfolioItemMobileVersionSection";
import ViewPortfolioWomanSportSiteAllPagesSection from "./ViewPortfolioWomanSportSiteAllPagesSection";
import { Image } from "types/global";
import ViewPortfolioItemRelatedSection from "../ViewPortfolioItemRelatedSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import {useTranslation} from "react-i18next";

export interface ViewPortfolioWomanSportSiteProps { }

const ViewPortfolioWomanSportSite = (
  props: ViewPortfolioWomanSportSiteProps
) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#DEDEE1" }}
        name={data.name}
        video={{
          src: "https://vimeo.com/542713244"
        }}
        size={{
          top: "lg",
          bottom: "lg",
        }}
        description={data.description}
        caption={[
            t("portfolioItem:woman-sport-site.about.0"),
            t("portfolioItem:woman-sport-site.about.1"),
            t("portfolioItem:woman-sport-site.about.2"),
        ]}
      />

      <ViewPortfolioWomanSportSiteAccountPageSection
        size="lg"
        style={{
          backgroundColor: "#EAEDEE",
        }}
        image={{
          src: buildPageImageSrc("screen-3")
        }}
        caption={[
            t("portfolioItem:woman-sport-site.account-section.0"),
          [
              t("portfolioItem:woman-sport-site.account-section.1.0"),
              t("portfolioItem:woman-sport-site.account-section.1.1"),
              t("portfolioItem:woman-sport-site.account-section.1.2")
          ],
        ]}
      />

      <ViewPortfolioWomanSportSiteTrainerAccountSection
        size={{
          top: "md",
          bottom: "md",
        }}
        caption={[
            t("portfolioItem:woman-sport-site.trainer-section.0"),
            t("portfolioItem:woman-sport-site.trainer-section.1")
        ]}
        images={
          Array.from({ length: 5 }, (_, i) => {
            return {
              src: buildPageImageSrc(`screen-4-${i + 1}`)
            };
          }) as Image[]
        }
      />

      <ViewPortfolioWomanSportSiteElementsSection
        size={{
          top: "lg",
          bottom: "md",
        }}
        style={{ backgroundColor: "#151617", color: "#fff" }}
        description={t("portfolioItem:woman-sport-site.elements-section.description")}
        label={t("portfolioItem:woman-sport-site.elements-section.label")}
        images={
          [
            {
              src: buildPageImageSrc("elements-1"),
            },
            ...Array.from({ length: 5 }, (_, i) => {
              return {
                src: buildPageImageSrc(`screen-5-${i + 1}`)
              };
            }) as Image[]]
        }
      />

      <ViewPortfolioWomanSportSiteMainPagesSection
        size={{
          top: "lg",
          bottom: "md",
        }}
        style={{ backgroundColor: "#EAEDEE" }}
        images={
          Array.from({ length: 5 }, (_, i) => {
            return {
              src: buildPageImageSrc(`screen-6-${i + 1}`)
            };
          }) as Image[]
        }
      />

      <ViewPortfolioWomanSportSiteAllPagesSection
        size={{
          top: "md",
          bottom: "xl",
        }}
        style={{
          backgroundColor: "#A8004C",
          color: "#fff",
        }}
        caption={t("portfolioItem:woman-sport-site.all-pages-section.caption")}
        description={t("portfolioItem:woman-sport-site.all-pages-section.description")}
        images={Array.from({ length: 4 }, (_, i) => {
          return Array.from({ length: 11 }, (_, l) => {
            // const imageId = `${i + 1}-${l + 1}`;

            return {
              src: buildPageImageSrc(`screen-7-${i * 11 + l + 1}`)
            };
          });
        })}
      />

      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc("screen-8")
        }}
      />

      {data.pageContent?.sections.mobileVersion && <ViewPortfolioItemMobileVersionSection
        portfolioId={data.id}
        style={data.pageContent?.sections.mobileVersion.theme}
        {...data.pageContent?.sections.mobileVersion}
      />}

      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          id={data.relatedId}
          style={{ backgroundColor: "#BC0055", color: "#fff" }}
          preview={{
            backgroundColor: "#F3F4F4",
            // frame: "#A8004C",
            image: {
              src: buildPageImageSrc("related")
            },
          }}
        />
      )}
    </>
  );
};

export default ViewPortfolioWomanSportSite;
