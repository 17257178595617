import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import List from "components/common/List";
import PortfolioItem from "components/layouts/PortfolioItem/PortfolioItem";
import {
  PortfolioItemData,
  PortfolioItemSubtype,
  PortfolioItemTitlesType,
  PortfolioItemType,
} from "data/portfolio/types";
import { useElemRef } from "hooks/useElemRef";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import portfolio from "data/portfolio";
import Print from "components/common/Print";
import i18n from "i18next";

import "./_portfolio-showcase-section.scss";
import { LangCodeType } from "i18n/types";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioShowcaseSectionProps {}

const getPortfolioSubtypeFiltersWithCount = (
  portfolio: PortfolioItemData[]
): { all?: number } & {
  [key in PortfolioItemSubtype]?: number;
} => {
  const result: { all: number } & {
    [key in PortfolioItemSubtype]?: number;
  } = {
    all: 0,
  };

  for (const { subtype } of portfolio) {
    ++result.all;

    if (subtype) {
      result[subtype] = (result[subtype] || 0) + 1;
    }
  }

  return {
    all: result.all,
    site: result.site,
    branding: result.branding,
    application: result.application,
    portal: result.portal,
    interfaces: result.interfaces,
    ecommerce: result.ecommerce,
  };
};

const ViewPortfolioShowcaseSection = (
  props: ViewPortfolioShowcaseSectionProps
) => {
  const [filter, setFilter] = useState<PortfolioItemSubtype | "all">("all");
  const currentLanguage = i18n.language as LangCodeType;

  // Добавим отладочное логирование
  useEffect(() => {
    console.log("Current Language:", currentLanguage);
    console.log("Portfolio Data:", portfolio[currentLanguage]);
  }, [currentLanguage]);

  // Сбрасываем фильтр при смене языка
  useEffect(() => {
    setFilter("all");
  }, [currentLanguage]);

  // Получаем актуальные фильтры для текущего языка
  const filtersBySubtypeWithCount = useMemo(
    () => getPortfolioSubtypeFiltersWithCount(portfolio[currentLanguage]),
    [currentLanguage]
  );

  // Делаем из фильтров массив
  const subtypeFilterAndCountList = useMemo(
    () => Object.entries(filtersBySubtypeWithCount),
    [filtersBySubtypeWithCount]
  );

  // Фильтруем портфолио
  const filteredPortfolio = useMemo(() => {
    const portfolioItems = portfolio[currentLanguage];

    if (filter === "all") {
      return portfolioItems;
    }

    return portfolioItems.filter(
      (item) => item.subtype === filter || item.type === filter
    );
  }, [filter, currentLanguage]);

  const scrollTo = () => {
    ScrollTrigger.refresh();
    gsap.to(window, {
      duration: 1,
      scrollTo: ".portfolio-showcase-section__portfolio-items",
    });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1100);
  };

  return (
    <DynamicBackgroundTrigger>
      <section className="portfolio-showcase-section background--gray">
        <div className="portfolio-showcase-section__container">
          <div className="portfolio-showcase-section__header container row">
            <div className="portfolio-showcase-section__filter col-11 offset-md-3 col-md-8 offset-xl-6 col-xl-4">
              <SlideFadeOutWhenInView>
                {({ setTriggerEl, setTarget }) => (
                  <div ref={setTriggerEl} className={"row"}>
                    <div className="col-5 col-sm-4 col-xl-3">
                      <p className="label side-brackets">
                        <Print data="view:portfolio.section.showcase.filter.label" />
                      </p>
                    </div>

                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={subtypeFilterAndCountList}
                      className="col-6 col-sm-7 col-xl"
                    >
                      {([filter, count], i) => (
                        <button
                          onClick={() => {
                            setFilter(filter as PortfolioItemSubtype);
                            scrollTo();
                          }}
                          className="portfolio-showcase-section__filter-button button label "
                        >
                          <span className="link--normal">
                            <Print
                              data={`portfolio.section.showcase.filter.type.${filter}`}
                            />
                            {/* <span className="side-brackets">{count}</span> */}
                          </span>
                        </button>
                      )}
                    </List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
          </div>
          <ul className="list portfolio-showcase-section__portfolio-items row">
            {filteredPortfolio.map((portfolioItem, i) => {
              const isActive =
                portfolioItem.subtype === filter || filter === "all";
              const isWide = !((i % 4) % 3);
              return (
                <li
                  key={portfolioItem.id}
                  className={classNames(
                    `portfolio-showcase-section__col-with-portfolio-item container col-11`,
                    isWide ? "col-md-6" : "col-md-5",
                    `portfolio-showcase-section__col-with-portfolio-item--${
                      !isActive && "filtered"
                    }`
                  )}
                >
                  <PortfolioItem
                    id={portfolioItem.id}
                    name={portfolioItem.name}
                    preview={portfolioItem.preview}
                    subtype={portfolioItem.subtype}
                    trigger={{ type: "scroll" }}
                    noPadding={portfolioItem.noPadding}
                    className={classNames(
                      "portfolio-showcase-section__portfolio-item",
                      `portfolio-showcase-section__portfolio-item--${
                        isWide ? "wide" : "narrow"
                      }`
                    )}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewPortfolioShowcaseSection;
