import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import React from "react";
import {useTranslation} from "react-i18next";

export interface ViewPortfolioEnsembleProps { }

const ViewPortfolioEnsemble = (props: ViewPortfolioEnsembleProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#619294", color: "#fff" }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc("screen-2", "svg"),
        }}
        description={data.description}
      />

      <ViewPortfolioItemSection style={{ backgroundColor: "#619294"}} className="view-portfolio-ensemble-section portfolio-item-section--type-ens-2">
          <div className={"portfolio-item-section__inner offset-1 col-9"}>
              <div className={"u-ratio u-ratio--9/16"}>
                  <VimeoPlayer size="fill" className="view-portfolio-item-about-section__video" url={'575917071'} play muted controls={false} loop={true} />
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc("screen-3"),
          sources: [
            {
              type: "webp",
              srcSet: buildPageImageSrc("screen-3")
            }
          ]
        }}
      />

      <ViewPortfolioItemSection style={{ backgroundColor: "#D0D2D4"}} className="view-portfolio-ensemble-section container portfolio-item-section--type-ens-4">
          <div className="portfolio-item-section__inner">
              <div>
                  <img src={buildPageImageSrc('screen-4-1')} alt={'ensemble'}/>
              </div>
              <div>
                  <img src={buildPageImageSrc('screen-4-2')} alt={'ensemble'}/>
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
          dynamicBackgroundColor={false}
          image={{
              src: buildPageImageSrc("screen-5"),
              sources: [
                  {
                      type: "webp",
                      srcSet: buildPageImageSrc("screen-5")
                  }
              ]
          }}
        />

      <ViewPortfolioItemSection style={{ backgroundColor: "#F3F4F4"}} className="view-portfolio-ensemble-section container portfolio-item-section--type-ens-6">
          <figure className="portfolio-item-section__figure row">
              <div className="portfolio-item-section__figure-content col-11 col-md-6">
                  <img src={buildPageImageSrc('screen-6')} alt={'ensemble'}/>
              </div>

              <figcaption className="portfolio-item-section__figure-caption col-11 offset-md-1 col-md">
                  <RevealWhenInView>
                      <p className="portfolio-item-section__figure-caption-item portfolio-item-section__figure-caption-item--top">
                          {t("portfolioItem:ensemble.section-6.text")}
                      </p>
                  </RevealWhenInView>
                  <RevealWhenInView>
                      <p className="portfolio-item-section__figure-caption-item portfolio-item-section__figure-caption-item--bottom">
                          {t("portfolioItem:ensemble.section-6.caption")}
                      </p>
                  </RevealWhenInView>
              </figcaption>
          </figure>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection style={{ backgroundColor: "#FFF"}} className="view-portfolio-ensemble-section container portfolio-item-section--type-ens-7">
          <div className="portfolio-item-section__inner row">
              <div className="portfolio-item-section__center col-md-7 offset-md-2">
                  <div className="view-portfolio-ensemble-7__img-center">
                      <div>
                          <img src={buildPageImageSrc('screen-7-1')} alt={'ensemble'}/>
                      </div>
                  </div>
              </div>
              <div className="portfolio-item-section__left-side col-xl-3 col-md-5 offset-xl-1 offset-0 order-md-1 order-2">
                  <figcaption className="portfolio-item-section__figure-caption">
                      <RevealWhenInView>
                          <p className="portfolio-item-section__figure-caption-item portfolio-item-section__figure-caption-item--top">
                              {t("portfolioItem:ensemble.section-7.text.0")}
                          </p>
                      </RevealWhenInView>
                      <RevealWhenInView>
                          <p className="portfolio-item-section__figure-caption-item portfolio-item-section__figure-caption-item--bottom">
                              {t("portfolioItem:ensemble.section-7.text.1")}
                          </p>
                      </RevealWhenInView>
                  </figcaption>

                  <div className="view-portfolio-ensemble-7__img-overlay">
                      <div>
                          <img src={buildPageImageSrc('screen-7-3')} alt={'ensemble'}/>
                      </div>
                  </div>
              </div>
              <div className="portfolio-item-section__right-side col-xl-5 col-md-5 offset-xl-2 offset-md-1 offset-0 order-md-2 order-1">
                  <div className="view-portfolio-ensemble-7__img">
                      <div>
                          <img src={buildPageImageSrc('screen-7-2')} alt={'ensemble'}/>
                      </div>
                  </div>
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
          dynamicBackgroundColor={false}
          image={{
              src: buildPageImageSrc("screen-8"),
              sources: [
                  {
                      type: "webp",
                      srcSet: buildPageImageSrc("screen-8")
                  }
              ]
            }}
      />

      <ViewPortfolioItemSection style={{ backgroundColor: "#FFF"}} className="view-portfolio-ensemble-section portfolio-item-section--type-ens-9">
          <div className="portfolio-item-section__inner">
              <div className={"u-ratio top-video"}>
                  <VimeoPlayer size="fill" className="view-portfolio-item-about-section__video" url={'673102888'} play muted controls={false} loop={true} />
              </div>
              <div className={"u-ratio bottom-video"}>
                  <VimeoPlayer size="fill" className="view-portfolio-item-about-section__video" url={'673106817'} play muted controls={false} loop={true} />
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
          dynamicBackgroundColor={false}
          image={{
              src: buildPageImageSrc("screen-10"),
              sources: [
                  {
                      type: "webp",
                      srcSet: buildPageImageSrc("screen-10")
                  }
              ]
          }}
      />

      <ViewPortfolioItemSection style={{ backgroundColor: "#FFF"}} className="view-portfolio-ensemble-section container portfolio-item-section--type-ens-11">
          <div className="portfolio-item-section__inner">
              <div className="portfolio-item-section__inner">
                  <figcaption className="portfolio-item-section__figure-caption col-xl-5 offset-xl-6 col-md-5 offset-md-5">
                      <RevealWhenInView>
                          <p className="portfolio-item-section__figure-caption-item portfolio-item-section__figure-caption-item--top">
                              {t("portfolioItem:ensemble.section-11.text.0")}
                          </p>
                      </RevealWhenInView>
                      <RevealWhenInView>
                          <p className="portfolio-item-section__figure-caption-item portfolio-item-section__figure-caption-item--bottom">
                              {t("portfolioItem:ensemble.section-11.text.1")}
                          </p>
                      </RevealWhenInView>
                  </figcaption>
                  <div className="view-portfolio-ensemble-11__block col-xl-7 col-md-8 offset-xl-2 offset-md-1">
                      <div className="view-portfolio-ensemble-11__img-cont">
                          <div>
                              <img src={buildPageImageSrc('screen-11')} alt={'ensemble'}/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
          dynamicBackgroundColor={false}
          image={{
              src: buildPageImageSrc("screen-12"),
              sources: [
                  {
                      type: "webp",
                      srcSet: buildPageImageSrc("screen-12")
                  }
              ]
          }}
      />
    </>
  );
};

export default ViewPortfolioEnsemble;
