import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import React from "react";

export interface ViewPortfolioSk10Props {}

const ViewPortfolioCryptoro = (props: ViewPortfolioSk10Props) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-cryptoro"
        caption={[t("portfolioItem:cryptoro.about.text.0"), t("portfolioItem:cryptoro.about.text.1"), t("portfolioItem:cryptoro.about.text.2"), ]}
        image={{
            isLogo: false,
            src: buildPageImageSrc(t("portfolioItem:cryptoro.about.image"), 'webp'),
        }}
        style={{
          backgroundColor: '#4FCA9C',
          color: '#FFF',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }} className={'view-portfolio-cryptoro-section portfolio-item-section--type-cryptoro-3 container'}>
          <div className="description">
              <RevealWhenInView>
                  <div>
                      <p>{t("portfolioItem:cryptoro.section-3.description.0")}</p>
                      <p className={'list'}>
                          <span>{t("portfolioItem:cryptoro.section-3.description.1.0")}</span>
                          <span>{t("portfolioItem:cryptoro.section-3.description.1.1")}</span>
                          <span>{t("portfolioItem:cryptoro.section-3.description.1.2")}</span>
                          <span>{t("portfolioItem:cryptoro.section-3.description.1.3")}</span>
                      </p>
                  </div>
              </RevealWhenInView>
              <RevealWhenInView><p>{t("portfolioItem:cryptoro.section-3.description.2")}</p></RevealWhenInView>
          </div>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-3.image'))} alt={'cryptoro'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

        {/* 4ий экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'view-portfolio-cryptoro-section portfolio-item-section--type-cryptoro-4'}>
            <div className="description container">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:cryptoro.section-4.description.0")}</p>
                        <p className={'list'}>
                            <span>{t("portfolioItem:cryptoro.section-4.description.1.0")}</span>
                            <span>{t("portfolioItem:cryptoro.section-4.description.1.1")}</span>
                            <span>{t("portfolioItem:cryptoro.section-4.description.1.2")}</span>
                        </p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:cryptoro.section-4.description.2")}</p></RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-4.image'))} alt={'cryptoro'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 5ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }} className={'view-portfolio-cryptoro-section'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-5.image'))} alt={'cryptoro'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

        {/* 6ий экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'view-portfolio-cryptoro-section portfolio-item-section--type-cryptoro-6 container'}>
            <div className="description">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:cryptoro.section-6.description.0")}</p>
                        <p className={'list'}>{t("portfolioItem:cryptoro.section-6.description.1")}</p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:cryptoro.section-6.description.2")}</p></RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-6.image'))} alt={'cryptoro'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection dynamicBackgroundColor={true} style={{ backgroundColor: '#F3F4F4' }} className={'container'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-7.image'))} alt={'cryptoro'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

        {/* 8ий экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'view-portfolio-cryptoro-section portfolio-item-section--type-cryptoro-8'}>
            <div className="description container">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:cryptoro.section-8.description.0")}</p>
                        <p className={'list'}>{t("portfolioItem:cryptoro.section-8.description.1")}</p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:cryptoro.section-8.description.2")}</p></RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-8.image'))} alt={'cryptoro'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 9ый экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cryptoro.section-9.image'))} alt={'cryptoro'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioCryptoro;
