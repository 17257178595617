import ViewPortfolioSk10Site from "components/views/ViewPortfolioItem/ViewPortfolioSk10Site/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "sk-10-site",
  component: ViewPortfolioSk10Site,
  title: "sk10",
  name: "sk-10",
  altName: "construction company",
  year: 2022,
  awardsList: [],

  link: {
    label: "sk10.ru",
    href: "https://sk10.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/193024329/SK10-Construction-company-Real-estate-developer",
    },
  ],

  preview: {
    label: "corporate site",
    backgroundColor: "#1F1F21",
    media: {
      type: "video",
      src: "https://vimeo.com/1011959005/2036d95287",
    },
  },

  poolOfWorks: ["Research", "UX|UI design", "refinements-of-the-design-system"],

  type: "site",
  subtype: "site",

  description: [
    "company engaged in the construction of residential spaces. Our task was to make a technical requirement for in-house work with hi-fi wireframes, create the logic of interaction with the user interface",
  ],
  shortDescription: "website for the construction company",

  seo: {
    title:
      "Website Design and Support for Developer No. 1 SK10 | Case by SAGIROV.com Studio",
    description:
      "Technical maintenance and design support for the SK10 developer's website. A complete solution for showcasing residential complexes, increasing conversion, and improving user experience, with the implementation of online mortgage application and apartment purchase functionality through the website.",
    keywords: [
      "developer website",
      "SK10 case",
      "websites for developers",
      "web design residential complexes",
      "construction company website",
    ],
    openGraph: {
      type: "article",
      title:
        "Website Design and Support for Developer No. 1 SK10 | Case by SAGIROV.com Studio",
      description:
        "Technical maintenance and design support for the SK10 developer's website. A complete solution for showcasing residential complexes, increasing conversion, and improving user experience, with the implementation of online mortgage application and apartment purchase functionality through the website.",
    },
  },
};

export default porfolioItem;
