import Section from "components/layouts/Section";
import i18n from "i18next";

import s from "../OurExperience/index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import { WhenNeededSection } from "data/services/types";

function ServicesItemWhenNeededSection({
  sectionData,
  color,
}: {
  sectionData?: WhenNeededSection;
  color?: string;
}) {
  const { data } = useViewServicesItemContext();
  const isEnglish = i18n.language === "en";

  let currentSectionData: WhenNeededSection | undefined =
    sectionData || undefined;
  let textColor = "black";

  if (data) {
    currentSectionData = data.whenNeededSection || undefined;
  }

  if (!currentSectionData || !currentSectionData.items) {
    return null;
  }

  return (
    <DynamicBackgroundTrigger>
      <Section
        className={
          s["services-item-our-experience-section"] +
          " " +
          s[textColor] +
          ` view-developer-sites-our-experience-section background--ededed`
        }
        style={{ backgroundColor: "#ededed" }}
      >
        <div className={" section-container container "}>
          <div
            className={
              "view-about-services-section__header " + s["service-about"]
            }
          >
            <p className="view-about-services-section__overline label">
              <RevealWhenInView
                tag="span"
                targetTag="span"
                targetClassName="side-brackets"
                duration={0.6}
              >
                {currentSectionData.headText}
              </RevealWhenInView>
            </p>
            <h2 className="view-about-services-section__heading heading ">
              <span
                className={
                  "view-about-services-section__heading-term " +
                  s["services-item-our-experience-section__title"]
                }
              >
                <RevealWhenInView tag="span" targetTag="span" duration={0.6}>
                  {currentSectionData.title}
                  &nbsp;&nbsp;
                </RevealWhenInView>
              </span>
            </h2>
          </div>
          <div
            className={
              s["services-item-our-experience-section__body"] + " col-11 row"
            }
          >
            <div
              className={
                "offset-lg-0 offset-md-3 order-lg-1 order-2 col-lg-3 col-md-6 col-11 " +
                s.description
              }
            >
              <p>{currentSectionData.description}</p>
            </div>

            <div
              className={
                ` order-lg-1 offset-lg-2 offset-0 col-lg-4 order-md-0 col-md-11  col-11  row ` +
                s["items-container"]
              }
            >
              {currentSectionData.items.map((item, index) => {
                const useEnglishClasses = isEnglish;

                return (
                  <div className={"col-11 row "} key={index}>
                    <span
                      className={
                        useEnglishClasses
                          ? `col-lg-2 offset-lg-0 offset-md-2 col-md-1 side-brackets ${s["side-brackets"]}`
                          : `col-md-5 side-brackets ${s["side-brackets"]}`
                      }
                    >
                      {item.value}
                    </span>
                    <span
                      className={
                        useEnglishClasses
                          ? "col-lg-9 offset-lg-0 col-md-6"
                          : "col-md-6"
                      }
                    >
                      {item.title}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ServicesItemWhenNeededSection;
