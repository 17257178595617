import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from '../ViewPortfolioItemImage';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import { useTranslation } from 'react-i18next';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import VimeoPlayer from '../../../common/VimeoPlayer/VimeoPlayer';

interface ViewPortfolioDomavekaCardSectionProps {
  images: ViewPortfolioItemImageData[];
}

const ViewPortfolioDomavekaCardSection = ({
  images,
}: ViewPortfolioDomavekaCardSectionProps) => {
  const { t } = useTranslation();
  return (
    <ViewPortfolioItemSection
      dynamicBackgroundColor={true}
      style={{ backgroundColor: '#aeb4ab' }}
      className="portfolio-item-section--type-doma-veka-site-9">
      <div className="doma-veka-section">
        <div className="doma-veka-section__images">
          {images.map((image) => (
            <ViewPortfolioItemImage
              className="doma-veka-section__image-wrapper"
              imageClassName="doma-veka-section__image"
              {...image}
            />
          ))}
        </div>
        <div className="doma-veka-section__info-text">
          <RevealWhenInView>
            <span className="doma-veka-section__text">
              {t('portfolioItem:doma-veka-site.section-9.text.0')}
            </span>
            <span>1920px</span>
          </RevealWhenInView>
        </div>
        <div className="doma-veka-section__video">
          <VimeoPlayer
            size="fill"
            url="https://player.vimeo.com/video/645132516?h=e0705336a4"
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioDomavekaCardSection;
