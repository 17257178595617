import { memo, useRef, useEffect } from "react";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import {
  ServicesItemData,
  ServicesItemTitlesType,
  LeadSection,
  SliderSectionType,
  FaqsSectionType,
  JobTypes,
  PriceSectionData,
  WorkStagesSectionType,
  OurExpireanceSectionType,
} from "data/services/types";

import Footer from "components/layouts/Footer";
import View from "components/layouts/View";
import ServicesItemLeadSection from "../../common/services/LeadSection/ServicesItemLeadSection";
import JobPrice from "../../common/services/JobPrice/JobPrice";
import JobTypesComponent from "../../common/services/JobTypes/JobTypes";

import "./index.scss";
import ViewDeveloperSitesPhotoSection from "./ViewDeveloperSitePhotoSection/ViewDeveloperSitesPhotoSectionPhotoSection";
import ServicesFaqsSection from "../../common/services/FaqsSection/FaqsSection";
import ViewDeveloperSitesOurExperienceSection from "./ViewDeveloperSitesOurExpireance/ViewDeveloperSitesOurExpireanceSection";
import ProjectsSwiperSection from "../../common/services/ProjectsSwiper/ProjectsSwiperSection";
import CustomerReviewsSection from "../../common/services/CustomerReviews/CustomerReviewsSection";
import { sectionData } from "data/customerReviews";
import { useParams } from "react-router-dom";
import i18n from "i18next";

interface ViewDeveloperSitesProps {}

const ViewDeveloperSites = (props: ViewDeveloperSitesProps) => {
  const viewElRef = useRef<HTMLDivElement>(null);
  const params = useParams<{ locale?: string }>();

  // Устанавливаем язык на основе параметра locale из URL
  useEffect(() => {
    if (params.locale === "en" && i18n.language !== "en") {
      i18n.changeLanguage("en");
    } else if (!params.locale && i18n.language !== "ru") {
      i18n.changeLanguage("ru");
    }
  }, [params.locale]);

  const defaultData = {
    id: "",
    component: null,
    componentPlace: 1 as const,
    title: "design-support" as ServicesItemTitlesType,
    name: "",
    color: "white",
    customerRewiewsSection: sectionData,
    leadSection: {
      title: "",
      description: "",
    } as LeadSection,
    sliderSection: {
      title: "",
      subtitle: "",
      items: [],
    } as SliderSectionType,
    faqsSection: {
      title: "",
      items: [],
    } as FaqsSectionType,
    jobTypes: {
      title: "",
      subtitle: "",
      items: [],
    } as JobTypes,
    priceSection: {
      title: "",
      subtitle: "",
      items: [],
      dopclasses: "",
    } as PriceSectionData,
    workStagesSection: {
      title: "",
      subtitle: "",
      description: "",
      items: [],
    } as WorkStagesSectionType,
    ourExpireanceSection: {
      title: {},
      subtitle: "",
      headText: "",
      description: "",
      items: [],
    } as OurExpireanceSectionType,
    seo: {
      title: "",
      description: "",
      keywords: [],
      openGraph: {
        type: "article",
        title: "",
        description: "",
      },
    },
  } as ServicesItemData;

  const context = useViewServicesItemContext() || { data: defaultData };
  const data = context.data || defaultData;

  return (
    <View ref={viewElRef} className="view-developer-sites">
      <ServicesItemLeadSection locale={params.locale || "ru"} />
      <ViewDeveloperSitesPhotoSection
        path="views/developer-sites"
        imageSrc="town-homes.webp"
      />
      <ViewDeveloperSitesOurExperienceSection />
      <ProjectsSwiperSection
        slides={[
          {
            title: "Cuprum Golem",
            subtitle: "Сайт-портфолио для студии художественного литья",
            headerItems: ["UX|UI дизайн", "верстка", "разработка сайта"],
            body: {
              title: "Задача",
              items: [
                "создание сайта-презентации",
                "разработка портфолио",
                "удобный интерфейс для демонстрации работ",
              ],
            },
            logo: "/images/developer-sites/cuprum-golem/logo",
            background: "/images/developer-sites/cuprum-golem/background",
          },
          {
            title: "CLT",
            subtitle: "Корпоративный сайт для производителя CLT-панелей",
            headerItems: ["UX|UI дизайн", "верстка", "разработка сайта"],
            body: {
              title: "Задача",
              items: [
                "создание корпоративного сайта",
                "разработка каталога продукции",
                "интеграция с CRM",
              ],
            },
            logo: "/images/developer-sites/clt/logo",
            background: "/images/developer-sites/clt/background",
          },
          {
            title: "Globe-IT",
            subtitle: "Сайт для производителя промышленного оборудования",
            headerItems: [
              "логотип",
              "UX|UI дизайн",
              "верстка",
              "интеграция с 1С-Битрикс",
            ],
            body: {
              title: "Задача",
              items: [
                "редизайн сайта",
                "обновление логотипа",
                "разработка каталога оборудования",
              ],
            },
            logo: "/images/developer-sites/globe-it/logo",
            background: "/images/developer-sites/globe-it/background",
          },
          {
            title: "CFPS",
            subtitle: "Корпоративный сайт для финансовой компании",
            headerItems: ["UX|UI дизайн", "верстка", "разработка сайта"],
            body: {
              title: "Задача",
              items: [
                "создание корпоративного сайта",
                "разработка личного кабинета",
                "интеграция с CRM",
              ],
            },
            logo: "/images/developer-sites/cfps/logo",
            background: "/images/developer-sites/cfps/background",
          },
        ]}
      />
      <CustomerReviewsSection data={data} />
      <ViewDeveloperSitesPhotoSection
        path="views/developer-sites"
        imageSrc="beautiful-home-1.webp"
      />
      <JobTypesComponent />
      <JobPrice />
      <ServicesFaqsSection />
      <Footer className="background--gray" />
    </View>
  );
};

export default memo(ViewDeveloperSites);
