import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useViewAssets } from "hooks/useAssets";
import Parallax from "components/common/Parallax";

interface ViewAboutPortraitSectionProps { }

const ViewAboutPortraitSection = (props: ViewAboutPortraitSectionProps) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const sectionInnerRef = useRef<HTMLDivElement>(null);
  const viewAssets = useViewAssets("about");

  useEffect(() => {
    const sectionElem = sectionRef.current!;
    const sectionInnerElem = sectionInnerRef.current!;

    const timeline = gsap.timeline({
      scrollTrigger: {
        start: `top+=150px bottom`,
        end: `bottom top`,
        trigger: sectionElem,
        toggleActions: "play none none reverse",
        scrub: 0.8,
      },

      defaults: {
        ease: "linear",
        // x: -scrollWidth,
        force3D: true,
      },
    });

    timeline.from(sectionInnerElem, {
      // transformOrigin: "50% 50%",
      opacity: 0,
      scale: 1.5,
      duration: 1,
      ease: "ease-out",
    });

    timeline.to(sectionInnerElem, {
      duration: 0.1,
    });

    timeline.to(sectionInnerElem, {
      // opacity: 0,
      scale: 1.5,
      duration: 1,
      ease: "ease-out",
    });

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="view-about-portrait-section u-overflow-hidden"
    >
      {/* <RevealWhenInView from={{ opacity: 0, scale: 1.5 }} to={{ opacity: 1, scale: 1 }}> */}
      <div ref={sectionInnerRef} className="view-about-portrait-section__inner">

        <Parallax
          image={{
            src: viewAssets.getImageSrc("portrait", "jpg", 1280),
            srcSet: [
              [viewAssets.getImageSrc("portrait", "jpg", 1280), "1x"],
              [viewAssets.getImageSrc("portrait", "jpg", 2560), "2x"],
            ],
            sources: [
              {
                type: "webp",
                srcSet: [
                  [viewAssets.getImageSrc("portrait", "webp", 1280), "1x"],
                  [viewAssets.getImageSrc("portrait", "webp", 2560), "2x"],
                ],
              }
            ]
          }}
        />
      </div>
      {/* </RevealWhenInView> */}
    </section>
  );
};

export default ViewAboutPortraitSection;
