
export interface Position {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export const lerp = (start: number, end: number, amount: number) => (1 - amount) * start + amount * end;

export const distance = (start: Position, end: Position) => Math.sqrt(
  Math.pow(end.x - start.x, 2) +
  Math.pow(end.y - start.y, 2)
);


export const rectDiagonal: {
  (width: number, height: number): number;
  (size: number): number;
} = (width: number, height?: number) => Math.sqrt(
  Math.pow(width, 2) +
  Math.pow(height === undefined ? width : height, 2)
)