import * as THREE from "three";

export const VERTEX_SHADER = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
`;

export const FRAGMENT_SHADER = `
  varying vec2 vUv;

  uniform sampler2D fromTexture;
  uniform sampler2D toTexture;
  uniform sampler2D displacementTexture;
  uniform float dispositionFactor;
  uniform float intensityFactor;
  uniform vec2 scale;
  uniform vec2 imageSize;
  uniform vec2 containerSize;

  void main() {
    vec2 uv = vUv;

    vec2 s = containerSize; // Screen
    vec2 i = imageSize; // Image

    // container ration
    float rs = containerSize.x / containerSize.y;
    // image ration
    float ri = imageSize.x / imageSize.y;

    vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
    vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
    vec2 resultCoordinate = uv * s / new + offset;

    vec4 displacementTexture = texture2D(displacementTexture, uv);
    vec2 distortedPosition = vec2(resultCoordinate.x + dispositionFactor * (displacementTexture.r*intensityFactor), resultCoordinate.y);
    vec2 distortedPosition2 = vec2(resultCoordinate.x - (1.0 - dispositionFactor) * (displacementTexture.r*intensityFactor), resultCoordinate.y);

    vec4 _texture = texture2D(fromTexture, distortedPosition);
    vec4 _texture2 = texture2D(toTexture, distortedPosition2);

    vec4 finalTexture = mix(_texture, _texture2, dispositionFactor);

    gl_FragColor = finalTexture;
  }
`;

export interface DistortionShaderMaterialUniforms {
  intensityFactor: THREE.IUniform;
  dispositionFactor: THREE.IUniform;
  fromTexture: THREE.IUniform;
  toTexture: THREE.IUniform;
  displacementTexture: THREE.IUniform;
  scale: THREE.IUniform;
  containerSize: THREE.IUniform;
  imageSize: THREE.IUniform;
  [uniform: string]: THREE.IUniform;
}

export const getDistortionShaderMaterialParameters = ({
  intensity,
  fromTexture,
  toTexture,
  displacementTexture,
  dispositionFactor = 0,
  scale = new THREE.Vector2(1, 1),
  containerSize = new THREE.Vector2(),
  imageSize = new THREE.Vector2(),
}: {
  intensity: number;
  dispositionFactor?: number;
  scale?: THREE.Vector2;
  containerSize?: THREE.Vector2;
  imageSize?: THREE.Vector2;
  fromTexture: THREE.Texture;
  toTexture: THREE.Texture;
  displacementTexture: THREE.Texture;
}): THREE.ShaderMaterialParameters => ({
  uniforms: {
    intensityFactor: {
      value: intensity,
    },
    dispositionFactor: {
      value: dispositionFactor,
    },
    fromTexture: {
      value: fromTexture,
    },
    toTexture: {
      value: toTexture,
    },
    displacementTexture: {
      value: displacementTexture,
    },
    scale: {
      value: scale,
    },
    containerSize: {
      value: containerSize,
    },
    imageSize: {
      value: imageSize,
    },
  },
  vertexShader: VERTEX_SHADER,
  fragmentShader: FRAGMENT_SHADER,
  transparent: true,
  opacity: 1,
});
