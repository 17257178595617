import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface CollapseProps {
  /**
   * Состояние, если true - отрыто, false - закрыто
   */
  open?: boolean;
  children: ReactNode;
  className?: string;
}

enum CollapseState {
  Collapsed = "collapsed",
  Collapsing = "collapsing",
  Expanded = "expanded",
  Expanding = "expanding",
}

/**
 * Компонент открытия и закрытия контента
 */
const Collapse = ({ open, children, className }: CollapseProps) => {
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const [state, setState] = useState<CollapseState>(
    open ? CollapseState.Expanded : CollapseState.Collapsed
  );
  const innerRef = useRef<HTMLDivElement>(null);
  const collapseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const innerEl = innerRef.current!;

    switch (state) {
      case CollapseState.Collapsed: {
        if (open) {
          setHeight(innerEl.scrollHeight);
          setState(CollapseState.Expanding);
        } else {
          setHeight(0);
        }

        break;
      }

      case CollapseState.Expanded: {
        if (open) {
          setHeight(innerEl.scrollHeight);
        } else {
          setHeight(innerEl.scrollHeight);
          setState(CollapseState.Collapsing);
        }

        break;
      }

      case CollapseState.Expanding: {
        if (open) {
          setHeight(innerEl.scrollHeight);
        } else {
          setState(CollapseState.Collapsing);
        }

        break;
      }
      case CollapseState.Collapsing: {
        if (open) {
          setState(CollapseState.Expanding);
        } else {
          setHeight(0);
        }

        break;
      }
    }
  }, [open, state]);

  const handleTransitionEnd = useCallback(() => {
    // Обновляем скролл триггер после завершения анимации
    ScrollTrigger.refresh();
    setState(open ? CollapseState.Expanded : CollapseState.Collapsed);
  }, [open]);

  return (
    <div
      onTransitionEnd={handleTransitionEnd}
      ref={collapseRef}
      style={{
        height,
      }}
      className={classNames(
        "collapse",
        { [`collapse--${state}`]: state },
        className
      )}
    >
      <div ref={innerRef} className="collapse__inner">
        {children}
      </div>
    </div>
  );
};

export default Collapse;
