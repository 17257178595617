import ViewServicesLeadSection from "./ViewServicesLeadSection";
import ViewServicesServiceSection from "./ViewServicesServiceSection";
import PortfolioLinkSection from "components/layouts/PortfolioLinkSection";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import CustomerReviewsSection from "components/common/services/CustomerReviews/CustomerReviewsSection";
import ProjectsSwiperSection from "components/common/services/ProjectsSwiper/ProjectsSwiperSection";
import ViewServicesListSection from "./ViewServicesListSection";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import { sectionData } from "data/customerReviews";
import { ServicesItemData, ServicesItemTitlesType } from "data/services/types";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import i18n from "i18next";

interface ViewServicesProps {}

const ViewServices = (props: ViewServicesProps) => {
  const params = useParams<{ locale?: string }>();

  // Устанавливаем язык на основе параметра locale из URL
  useEffect(() => {
    if (params.locale === "en" && i18n.language !== "en") {
      i18n.changeLanguage("en");
    } else if (!params.locale && i18n.language !== "ru") {
      i18n.changeLanguage("ru");
    }
  }, [params.locale]);

  const defaultData = {
    id: "",
    component: null,
    componentPlace: 0,
    title: "design-support" as ServicesItemTitlesType,
    name: "",
    color: "white",
    customerRewiewsSection: sectionData,
    leadSection: null,
    sliderSection: null,
    faqsSection: null,
    jobTypes: null,
    priceSection: null,
    workStagesSection: null,
    ourExpireanceSection: null,
    seo: {
      title: "",
      description: "",
      keywords: [],
      openGraph: {
        type: "article",
        title: "",
        description: "",
      },
    },
  } as ServicesItemData;

  const context = useViewServicesItemContext() || { data: defaultData };
  const data = context.data || defaultData;

  // Создаем объект с полными данными для компонента отзывов
  const reviewsData = {
    customerRewiewsSection: {
      ...sectionData,
      reviews: sectionData.reviews.map((review) => ({
        ...review,
        // Убедимся, что текст отзыва полный, а не сокращенный
        text: review.text.endsWith("...")
          ? "Команда студии SAGIROV.com показала себя надежным подрядчиком. Мы сотрудничаем с 2019 года, и за это время было реализовано множество задач по разработке и поддержке нашего сайта. Отдельно хочу отметить оперативность в решении срочных задач и высокое качество работы."
          : review.text,
      })),
    },
  };

  console.log("ViewServices - данные отзывов:", reviewsData);

  return (
    <View className="view--services">
      <ViewServicesLeadSection />
      <ViewServicesListSection />
      <CustomerReviewsSection data={reviewsData} />
      <ProjectsSwiperSection
        slides={[
          {
            title: "SK10",
            subtitle: "Разработка и поддержка проекта с 2019 года по Н.В.",
            headerItems: [
              "разработка сайта",
              "UI/UX дизайн",
              "Тех поддержка",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "проектирование и разработка сайта",
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/sk10/logo",
            background: "/images/developer-sites/sk10/background",
            nextSlideButton: <>Следующий проект</>,
          },
          {
            title: "Дома века",
            subtitle: "Разработка и поддержка проекта с 2018 года по Н.В.",
            headerItems: [
              "разработка сайта",
              "UI/UX дизайн",
              "Тех поддержка",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "проектирование и разработка сайта",
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/doma-veka/logo",
            background: "/images/developer-sites/doma-veka/background",
            nextSlideButton: (
              <>
                Следующий <br /> проект{" "}
              </>
            ),
          },
          {
            title: "AAG",
            subtitle: "Разработка и поддержка проекта с 2023 года по Н.В.",
            headerItems: [
              "Тех поддержка",
              "UI/UX дизайн",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/aag/logo",
            background: "/images/developer-sites/aag/background",
            nextSlideButton: (
              <>
                Следующий <br /> проект{" "}
              </>
            ),
          },
          {
            title: "НОВАЦИЯ",
            subtitle: "Разработка и поддержка проекта с 2014 года по Н.В.",
            headerItems: [
              "разработка сайта",
              "UI/UX дизайн",
              "Тех поддержка",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "проектирование и разработка сайта",
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/nova-group/logo",
            background: "/images/developer-sites/nova-group/background",
            nextSlideButton: (
              <>
                Следующий <br /> проект{" "}
              </>
            ),
          },
        ]}
      />
      <PortfolioLinkSection className="view-services-portfolio-link-section" />
      <Footer />
    </View>
  );
};

export default ViewServices;
