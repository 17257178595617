import classNames from "classnames";
import { ElementType } from "react";

interface RawHtmlProps {
  /**
   * @default "div"
   */
  tag?: ElementType<any>;
  className?: string;
  children: string;
}

/**
 * Рендерит html из строки
 */
const RawHtml = ({ tag: Tag = "div", className, children }: RawHtmlProps) => {
  return (
    //@ts-ignore
    <Tag
      className={classNames("raw-html", className)}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export default RawHtml;
