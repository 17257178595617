import React from "react";
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";

interface ViewPortfolioGosaptekaManagerMonitoringSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  image: ViewPortfolioItemImageData;
  description: string;
}

const ViewPortfolioGosaptekaManagerMonitoringSection = ({
  image,
  description,
  ...sectionProps
}: ViewPortfolioGosaptekaManagerMonitoringSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section--layout-gosapteka-manager-monitoring"
    >
      <div className="view-portfolio-item-section__container container">
        <figure className="view-portfolio-item-section__inner row">
          <div className="view-portfolio-item-section__col-image offset-md-1 col-md-9 offset-lg-0 col-lg-6">
            <ViewPortfolioItemImage {...image} />
          </div>

          <div className="view-portfolio-item-section__col-description offset-md-1 col-md-9 offset-lg-1 col-lg">
            <figcaption
              className="view-portfolio-item-section__description u-max-w-480"
            >
              <RevealTextByLine wordClassName={(l, w) => l === 0 && w === 0 ? "offset-md-3" : undefined}>
                {description}
              </RevealTextByLine>
            </figcaption>
          </div>
        </figure>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioGosaptekaManagerMonitoringSection;
