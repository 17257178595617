import ViewPortfolioAAA from "components/views/ViewPortfolioItem/ViewPortfolioAAA/ViewPortfolioAAA";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "aaa",
  component: ViewPortfolioAAA,
  title: "aaa",
  name: "aaa agency",
  altName: "banners and posters",
  year: "2024",
  awardsList: [],

  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/213808373/AAA-Agency-Banners-posters",
    },
  ],

  preview: {
    label: "banners and posters",
    media: {
      type: "image",
      fromSrc: "/assets/portfolios/aaa/preview/1.webp",
      toSrc: "/assets/portfolios/aaa/preview/2.webp",
    },
  },

  poolOfWorks: ["Research", "Design"],

  type: "site",
  subtype: "site",

  description: [
    "We provided comprehensive design support and outsourcing for AAA Agency: developing unique posts and banners to promote brands via influencer campaigns.",
    " Our work involved research, concept creation, and refining visual assets. We paid special attention to aligning with the client's brand style and meeting the target audience's interests.",
  ],
  shortDescription: "Influencer Campaigns and Advertising Banners",

  seo: {
    title:
      "Design Support and Outsourcing for AAA Agency: Influencer Campaigns and Advertising Banners | SAGIROV",
    description:
      "We provided comprehensive design support and outsourcing for AAA Agency: developing unique posts and banners to promote brands via influencer campaigns. Our work involved research, concept creation, and refining visual assets. We paid special attention to aligning with the client's brand style and meeting the target audience's interests.",
    keywords: [
      "AAA Agency",
      "design support",
      "design outsourcing",
      "visual content",
      "influencer campaigns",
      "advertising banners",
      "unique posts",
      "brand promotion",
      "retro gaming trend",
    ],
    openGraph: {
      type: "article",
      title:
        "Design Support and Outsourcing for AAA Agency: Influencer Campaigns and Advertising Banners | SAGIROV",
      description:
        "We provided comprehensive design support and outsourcing for AAA Agency: developing unique posts and banners to promote brands via influencer campaigns. Our work involved research, concept creation, and refining visual assets. We paid special attention to aligning with the client's brand style and meeting the target audience's interests.",
    },
  },
};

export default porfolioItem;
