import ViewHomeLeadSection from "./ViewHomeLeadSection";
import ViewHomePortfolioListSection from "./ViewHomePortfolioSection";
import PortfolioLinkSection from "components/layouts/PortfolioLinkSection";
import { memo, useRef, useEffect } from "react";

import Footer from "components/layouts/Footer";
import View from "components/layouts/View";
import AwardsSection from "../../layouts/AwardsSection";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface ViewHomeProps {}

const ViewHome = (props: ViewHomeProps) => {
  const viewElRef = useRef<HTMLDivElement>(null);
  const portfolioLinkSectionElRef = useRef<HTMLDivElement>(null);

  const resetFlipBookState = () => {
    // Сброс состояния flip-book
    if (viewElRef.current) {
      const flipBook = viewElRef.current.querySelector(".flip-book");
      if (flipBook) {
        // Сброс состояния
        gsap.set(flipBook, { clearProps: "all" });
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      }
    }
  };

  useEffect(() => {
    return () => {
      resetFlipBookState();
    };
  }, []);

  return (
    <View ref={viewElRef}>
      <ViewHomeLeadSection />
      <ViewHomePortfolioListSection />
      <PortfolioLinkSection ref={portfolioLinkSectionElRef} />
      <AwardsSection />
      <Footer />
    </View>
  );
};

export default memo(ViewHome);
