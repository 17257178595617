import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ArrowDiagonal from "./Arrow/ArrowDiagonal";
import ArrowCsdLeft from "./Arrow/ArrowCsdLeft";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import { ViewSpecificPortfolioItemProps } from "data/portfolio/types";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import { useTranslation } from "react-i18next";
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import ViewPortfolioCsdModel from "./ViewPortfolioCsdModel";

const ViewPortfolioCsd = (props: ViewSpecificPortfolioItemProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      {/* экран 2 */}
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#FFFFFF" }}
        caption={[
          t("portfolioItem:csd.about.0"),
          t("portfolioItem:csd.about.1"),
          t("portfolioItem:csd.about.2"),
        ]}
        name={data.name}
        description={data.description}
        video={{ src: t("portfolioItem:csd.section-2.video") }}
        className="csd-site"
      />
      {/* экран 3 */}
      <ViewPortfolioItemSection
        style={{ color: "#fff", backgroundColor: "#124FDC" }}
        className="view-portfolio-csd-section portfolio-item-section--type-csd-3 container  "
      >
        <div className="portfolio-item-section__item-sm row">
          <div className="portfolio-item-section__item-left">
            <RevealWhenInView delay={0.3}>
              <p className="portfolio-item-section__col-with-description row ">
                <p className="col-xl-1 col-md-2 ">
                  {t("portfolioItem:csd.section-3.about.0")}
                </p>
                {t("portfolioItem:csd.section-3.about.1")}
              </p>
            </RevealWhenInView>
            <RevealWhenInView>
              <div className="img-wrap border">
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-3.image")
                  )}
                  alt={"csd"}
                />
              </div>
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__item-right">
            <RevealWhenInView delay={0.3}>
              <p className="portfolio-item-section__col-with-description row col-sm">
                <p className="col-xl-1 col-md-2">
                  {" "}
                  {t("portfolioItem:csd.section-3.about.2")}
                </p>
                {t("portfolioItem:csd.section-3.about.1")}
              </p>
            </RevealWhenInView>
            <RevealWhenInView>
              <div className={"u-ratio border"}>
                <VimeoPlayer
                  size="fill"
                  url={t("portfolioItem:csd.section-3.video")}
                  play
                  muted
                  controls={false}
                  loop={true}
                />
              </div>
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 4 */}
      <ViewPortfolioItemSection className="view-portfolio-csd-section portfolio-item-section--type-csd-4 container  ">
        <div className=" row">
          <div className="col-xl-8  offset-0 ">
            <RevealWhenInView>
              <p className="portfolio-item-section__description row">
                <p className="col-xl-1 ">
                  {t("portfolioItem:csd.section-4.caption.0")}
                </p>
                {t("portfolioItem:csd.section-4.caption.1")}
              </p>
            </RevealWhenInView>
            <div className={"u-ratio border"}>
              <VimeoPlayer
                size="fill"
                url={t("portfolioItem:csd.section-4.video")}
                play
                muted
                controls={false}
                loop={true}
              />
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 5 */}
      <ViewPortfolioItemSection className="view-portfolio-csd-section container portfolio-item-section--type-csd-5 row">
        <div className="portfolio-item-section__img  portfolio-item-section__img-item--left">
          <RevealWhenInView>
            <img
              className="border"
              src={buildPageImageSrc(t("portfolioItem:csd.section-5.image.0"))}
              alt={"csd"}
            />
          </RevealWhenInView>
        </div>
        <div className="portfolio-item-section__img  portfolio-item-section__img-item--center ">
          <RevealWhenInView>
            <div className="portfolio-item-section__btn">
              <ArrowDiagonal scrollTo={{ y: ".video-screen5", offsetY: 50 }} />
              {t("portfolioItem:csd.section-5.caption.0")}
            </div>
          </RevealWhenInView>
          <div className="video-screen5">
            <RevealWhenInView>
              <div className={"u-ratio"}>
                <VimeoPlayer
                  size="fill"
                  url={t("portfolioItem:csd.section-5.video")}
                  play
                  muted
                  controls={false}
                  loop={true}
                />
              </div>
            </RevealWhenInView>
          </div>
        </div>
        <div className="portfolio-item-section__img  portfolio-item-section__img-item--right">
          <RevealWhenInView>
            <img
              className="border"
              src={buildPageImageSrc(t("portfolioItem:csd.section-5.image.1"))}
              alt={"csd"}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 6 */}
      <ViewPortfolioItemSection
        className="view-portfolio-csd-section container portfolio-item-section--type-csd-6 container "
        style={{ color: " #1F1F21", backgroundColor: "#F3F4F4;" }}
      >
        <div className=" row">
          <div className=" col-xl-8  col-md-9  offset-xl-3 offset-md-2 portfolio-item-section__item--right">
            <RevealWhenInView>
              <p className="item--right__description row">
                <p className="col-xl-1 col-md-2">
                  {t("portfolioItem:csd.section-6.caption.0")}
                </p>
                {t("portfolioItem:csd.section-6.caption.1")}
              </p>
            </RevealWhenInView>
            <RevealWhenInView>
              <div className={"u-ratio border"}>
                <VimeoPlayer
                  size="fill"
                  url={t("portfolioItem:csd.section-6.video")}
                  play
                  muted
                  controls={false}
                  loop={true}
                />
              </div>
            </RevealWhenInView>
          </div>
        </div>
        <div className="row portfolio-item-section__item--left">
          <RevealWhenInView className="offset-0 ">
            {t("portfolioItem:csd.section-6.caption.2")}
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* экран7 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#f7f7f7" }}
        className="view-portfolio-csd-section  portfolio-item-section--type-csd-7 container row"
      >
        <div className=" portfolio-item-section__left-side  col-xl-5 col-lg-5 col-md-5 offset-xl-0">
          <RevealWhenInView>
            <img
              className="border"
              src={buildPageImageSrc(t("portfolioItem:csd.section-7.image.0"))}
              alt={"csd"}
            />
          </RevealWhenInView>
        </div>
        <div className="portfolio-item-section__right-side offset-xl-2 col-xl-4 offset-lg-1 col-lg-5  offset-md-1 col-md-5 col-sm-9 offset-sm-1">
          <div className="portfolio-item-section__right-side--gif">
            <RevealWhenInView>
              <img src={t("portfolioItem:csd.section-7.gif")} alt={"csd"} />
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__right-side--img">
            <RevealWhenInView>
              <img
                className="border"
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-7.image.1")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 8 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFF" }}
        className="view-portfolio-csd-section  portfolio-item-section--type-csd-8 row container "
      >
        <figcaption className="portfolio-item-section__figure-caption offset-xl-8  offset-md-0 col-lg-7 col-md-7">
          <RevealWhenInView>
            <p className="portfolio-item-section__figure-caption-item ">
              <div className="portfolio-item-section__figure-caption-item--top">
                <p>{t("portfolioItem:csd.section-8.caption.0")} </p>
                <p>{t("portfolioItem:csd.section-8.caption.1")} </p>
              </div>
              <div>
                <p>{t("portfolioItem:csd.section-8.caption.2")} </p>
                <p>{t("portfolioItem:csd.section-8.caption.3")} </p>
              </div>
            </p>
          </RevealWhenInView>
        </figcaption>
        <div className="view-portfolio-csd-8__block row col-11 ">
          <div className="view-portfolio-csd-8__img-cont col-xl-7  offset-xl-0 col-lg-7  offset-lg-0 col-md-7  offset-md-0 ">
            <div>
              <RevealWhenInView>
                <img
                  className="border"
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-8.image.0")
                  )}
                  alt={"csd"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="view-portfolio-csd-8__img col-xl-2 col-lg-3 col-md-3 ">
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-8.image.1")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 9 */}
      <ViewPortfolioItemSection
        style={{ color: "#fff", backgroundColor: "#1445d6" }}
        className=" view-portfolio-csd-section  portfolio-item-section--type-csd-9 container"
      >
        <div className="portfolio-item-section__inner-top row">
          <figcaption className="portfolio-item-section__figure-caption-md  ">
            <RevealWhenInView>
              <p className="portfolio-item-section__figure-caption-item--top">
                <p>{t("portfolioItem:csd.section-9.text")} </p>
                <div className="figure-caption-item__text--bottom">
                  <p>{t("portfolioItem:csd.section-9.caption.0")} </p>
                  <p>{t("portfolioItem:csd.section-9.caption.1")} </p>
                </div>
              </p>
            </RevealWhenInView>
          </figcaption>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <RevealWhenInView>
              <div className=" portfolio-item-section__inner-top--img row">
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-9.image.0")
                  )}
                  alt={"csd"}
                />
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-9.image.1")
                  )}
                  alt={"csd"}
                />
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-9.image.2")
                  )}
                  alt={"csd"}
                />
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-9.image.3")
                  )}
                  alt={"csd"}
                />
              </div>
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__inner-top--gif offset-xl-1 col-xl-6 offset-lg-1 col-lg-4 offset-md-1 col-md-4 ">
            <figcaption className="portfolio-item-section__figure-caption  ">
              <RevealWhenInView>
                <p className="portfolio-item-section__figure-caption-item--top">
                  <p>{t("portfolioItem:csd.section-9.text")} </p>
                  <div className="figure-caption-item__text--bottom">
                    <p>{t("portfolioItem:csd.section-9.caption.0")} </p>
                    <p>{t("portfolioItem:csd.section-9.caption.1")} </p>
                  </div>
                </p>
              </RevealWhenInView>
            </figcaption>
            <div className="view-portfolio-csd-9__block offset-xl-4 ">
              <RevealWhenInView>
                <img src={t("portfolioItem:csd.section-9.gif")} alt={"csd"} />
              </RevealWhenInView>
            </div>
          </div>
        </div>
        <div className=" portfolio-item-section__inner-bottom row ">
          <div className="portfolio-item-section__inner-bottom--left col-xl-2  ">
            <div className="portfolio-item-section__arrow">
              <RevealWhenInView>
                <ArrowDiagonal
                  scrollTo={{
                    y: ".portfolio-item-section__inner-bottom--img",
                    offsetY: 50,
                  }}
                />
              </RevealWhenInView>
            </div>
            <p className="portfolio-item-section__inner-bottom--img">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-9.image.5")
                  )}
                  alt={"csd"}
                />
              </RevealWhenInView>
            </p>
          </div>
          <div className="portfolio-item-section__inner-bottom--right col-xl-6 offset-xl-3 ">
            <RevealWhenInView>
              <p className="portfolio-item-section__description row">
                <p className="col-1">
                  {t("portfolioItem:csd.section-9.caption.2")}
                </p>
                {t("portfolioItem:csd.section-9.caption.3")}
              </p>
            </RevealWhenInView>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-9.image.4")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 'экран 10*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={true}
        image={{
          src: buildPageImageSrc(t("portfolioItem:csd.section-10.image")),
          sources: [
            {
              type: "webp",
              srcSet: buildPageImageSrc(
                t("portfolioItem:csd.section-10.image")
              ),
            },
          ],
        }}
      />
      {/* экран 11 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFF" }}
        className="view-portfolio-csd-section portfolio-item-section--type-csd-11  container"
      >
        <div className="portfolio-item-section__first-group row ">
          <div className="portfolio-item-section__first-group-image col-xl-3 ">
            <div className="portfolio-item-section__first-group-image--left">
              <div className="portfolio-item-section__image-top">
                <RevealWhenInView>
                  <img
                    src={t("portfolioItem:csd.section-11.gif.0")}
                    alt={"csd"}
                  />
                </RevealWhenInView>
              </div>
              <RevealWhenInView>
                <div className="portfolio-item-section__description">
                  {t("portfolioItem:csd.section-11.caption.0")}
                </div>
              </RevealWhenInView>
              <div className="portfolio-item-section__image-bottom">
                <RevealWhenInView>
                  <img
                    className="border"
                    src={buildPageImageSrc(
                      t("portfolioItem:csd.section-11.image.0")
                    )}
                    alt={"csd"}
                  />
                </RevealWhenInView>
              </div>
            </div>
          </div>
          <div className="portfolio-item-section__first-group-image--right offset-xl-1">
            <div className="portfolio-item-section__first-group-image--top">
              <RevealWhenInView>
                <p className="portfolio-item-section__first-group-image--description row">
                  <p>{t("portfolioItem:csd.section-11.caption.1")}</p>
                  <p>{t("portfolioItem:csd.section-11.caption.2")}</p>
                </p>
              </RevealWhenInView>
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-11.image.1")
                  )}
                  alt={"csd"}
                />
              </RevealWhenInView>
            </div>
            <div className="portfolio-item-section__first-group-image--bottom row">
              <div className="portfolio-item-section__inner--arrow ">
                <RevealWhenInView>
                  <ArrowCsdLeft
                    scrollTo={{
                      y: ".portfolio-item-section__second-group-image--description",
                      offsetY: 50,
                    }}
                  />
                </RevealWhenInView>
              </div>
              <div className="portfolio-item-section__inner--image ">
                <RevealWhenInView>
                  <img
                    src={buildPageImageSrc(
                      t("portfolioItem:csd.section-11.image.2")
                    )}
                    alt={"csd"}
                  />
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-item-section__second-group-image row ">
          <div className="col-xl-7">
            <RevealWhenInView>
              <p className="portfolio-item-section__second-group-image--description row">
                <p>{t("portfolioItem:csd.section-11.caption.3")}</p>
                <p>{t("portfolioItem:csd.section-11.caption.4")}</p>
              </p>
            </RevealWhenInView>
            <RevealWhenInView>
              <img
                className="border"
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-11.image.3")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__second-group-image--right-side offset-1 col-xl-3 ">
            <div className="view-portfolio-csd-11__block">
              <RevealWhenInView>
                <img
                  src={t("portfolioItem:csd.section-11.gif.1")}
                  alt={"csd"}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 12 */}
      <ViewPortfolioItemSection
        className="view-portfolio-csd-section  portfolio-item-section--type-csd-12 container "
        style={{ color: " #FFFFFF", backgroundColor: "#2A2C2F" }}
      >
        <div className="portfolio-item-section__item--top row ">
          <div className="col-xl-6">
            <RevealWhenInView>
              <div className="portfolio-item-section__img row">
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-12.image.0")
                  )}
                  alt={"csd"}
                />
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-12.image.1")
                  )}
                  alt={"csd"}
                />
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-12.image.2")
                  )}
                  alt={"csd"}
                />
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:csd.section-12.image.3")
                  )}
                  alt={"csd"}
                />
              </div>
            </RevealWhenInView>
          </div>
          <div className="offset-xl-0">
            <RevealWhenInView>
              <ArrowDiagonal scrollTo=".video-screen5" />
            </RevealWhenInView>
          </div>
        </div>
        <div className="portfolio-item-section__item--bottom row">
          <div className="portfolio-item-section__description col-2 col-xl-2">
            <RevealWhenInView>
              {t("portfolioItem:csd.section-12.caption.0")}
              <p className="portfolio-item-section__description--item-text">
                {t("portfolioItem:csd.section-12.caption.1")}
              </p>
              {t("portfolioItem:csd.section-12.caption.2")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-5 offset-xl-2">
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-12.image.4")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 13 */}
      <ViewPortfolioItemSection
        className="view-portfolio-csd-section  portfolio-item-section--type-csd-13 container row "
        style={{ backgroundColor: "#124FDC" }}
      >
        <div className="view-portfolio-csd-gallery-section__group-image--col-1 col-xl-5  row">
          <div className="view-portfolio-csd-gallery-section__first-group-image-col">
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.0")
                )}
                alt={"csd"}
              />
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.1")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.2")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.3")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.4")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
        </div>
        <div className="view-portfolio-csd-gallery-section__group-image--col-2 col-xl-6  row">
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.5")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.6")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.7")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
          <div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:csd.section-13.image.8")
                )}
                alt={"csd"}
              />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* экран 14 */}
      <ViewPortfolioItemSection
        className="view-portfolio-csd-section  portfolio-item-section--type-csd-14 container-fluid "
        style={{ color: " #FFFFFF", backgroundColor: "#124FDC" }}
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(t("portfolioItem:csd.section-14.image"))}
            alt={"csd"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* экран 15 */}
      <ViewPortfolioItemSection
        className="view-portfolio-csd-section  portfolio-item-section--type-csd-15 u-overflow-hidden"
        style={{ backgroundColor: "#F3F4F4" }}
      >
        <figcaption className="portfolio-item-section__figure-caption container">
          <RevealWhenInView>
            <div className="row">
              <p className="col-auto">
                {t("portfolioItem:csd.section-15.caption.0")}{" "}
              </p>
              <p className="offset-auto">
                {t("portfolioItem:csd.section-15.caption.1")}{" "}
              </p>
            </div>
          </RevealWhenInView>
        </figcaption>
        <ViewPortfolioCsdModel />
      </ViewPortfolioItemSection>
      {/* экран 16*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc("screen-15"),
          sources: [
            {
              type: "webp",
              srcSet: buildPageImageSrc("screen-15"),
            },
          ],
        }}
      />
    </>
  );
};

export default ViewPortfolioCsd;
