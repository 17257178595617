import Section from "components/layouts/Section";
import i18n from "i18next";

import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import { OurExpireanceSection } from "data/services/types";

function ServicesItemOurExperienceSection({
  sectionData,
  color,
}: {
  sectionData?: OurExpireanceSection;
  color?: string;
}) {
  const { data } = useViewServicesItemContext();
  const isEnglish = i18n.language === "en";

  console.log("Current language:", i18n.language, "isEnglish:", isEnglish);

  let currentSectionData: OurExpireanceSection | undefined =
    sectionData || undefined;
  let currentColor = color || "white";
  let textColor = "white";

  if (data) {
    currentSectionData = data.ourExpireanceSection || undefined;
    currentColor = data.color!;
  } else {
    textColor = "black";
  }

  if (!currentSectionData || !currentSectionData.items) {
    return null;
  }

  return (
    <DynamicBackgroundTrigger>
      <Section
        className={
          s["services-item-our-experience-section"] +
          " " +
          s[textColor] +
          ` background--${currentColor} view-developer-sites-our-experience-section`
        }
      >
        <div className={" section-container container "}>
          <div
            className={
              "view-about-services-section__header " + s["service-about"]
            }
          >
            <p className="view-about-services-section__overline label">
              <RevealWhenInView
                tag="span"
                targetTag="span"
                targetClassName="side-brackets"
                duration={0.6}
              >
                {currentSectionData.headText}
              </RevealWhenInView>
            </p>
            <h2 className="view-about-services-section__heading heading ">
              <span
                className={
                  "view-about-services-section__heading-term " +
                  s["services-item-our-experience-section__title"]
                }
              >
                <RevealWhenInView tag="span" targetTag="span" duration={0.6}>
                  {currentSectionData.title}
                  &nbsp;&nbsp;
                </RevealWhenInView>
              </span>
            </h2>
          </div>
          <div
            className={
              s["services-item-our-experience-section__body"] + " col-11 row"
            }
          >
            {currentSectionData.description === "aunchor" || currentSectionData.description === "anchor" ? (
              <div
                className={
                  "order-lg-1 order-2 col-lg-3 col-md-6 col-11 " + s.description
                }
              >
                <div className={s.aunchor}>
                  <RevealWhenInView>
                    <ScrollDownButton
                      scrollTo=".footer"
                      duration={2}
                      className={"scroll-down-button-with-text text-left"}
                    >
                      <p>
                        {isEnglish ? (
                          <>
                            Contact <br />us
                          </>
                        ) : (
                          <>
                            Связаться <br />с нами
                          </>
                        )}
                      </p>
                    </ScrollDownButton>
                  </RevealWhenInView>
                </div>
              </div>
            ) : (
              <div
                className={
                  "offset-lg-0 offset-md-3 order-lg-1 order-2 col-lg-3 col-md-6 col-11 " +
                  s.description
                }
              >
                <p>{currentSectionData.description}</p>
              </div>
            )}

            {/* Начало второго листа */}
            {currentSectionData.description === "aunchor" || currentSectionData.description === "anchor" ? (
              <div
                className={
                  `order-lg-2 order-1 col-lg-6 col-md-9 col-11 row ` +
                  s["items-container"]
                }
              >
                {/* @ts-ignore */}
                {currentSectionData.items.map((item) => {
                  return (
                    <div className={"col-11 row "}>
                      <span className={"col-md-5 side-brackets"}>
                        {item.title}
                      </span>
                      <span className="col-md-6">{item.description}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className={
                  ` order-lg-1 offset-lg-2 offset-0 col-lg-4 order-md-0 col-md-11  col-11  row ` +
                  s["items-container"]
                }
              >
                {/* @ts-ignore */}
                {currentSectionData.items.map((item, index) => {
                  // Проверяем, содержит ли заголовок длинные английские слова, характерные для английской версии
                  const hasLongEnglishWords =
                    item.title &&
                    (item.title.includes("Rebranding") ||
                      item.title.includes("Expansion") ||
                      item.title.includes("Digital") ||
                      item.title.includes("Marketing") ||
                      item.title.includes("Seasonal"));

                  // Используем либо isEnglish из i18n, либо определяем по содержимому
                  const useEnglishClasses = isEnglish || hasLongEnglishWords;

                  return (
                    <div className={"col-11 row "} key={index}>
                      <span
                        className={
                          useEnglishClasses
                            ? `col-lg-2 offset-lg-0 offset-md-2 col-md-1 side-brackets ${s["side-brackets"]}`
                            : `col-md-5 side-brackets ${s["side-brackets"]}`
                        }
                      >
                        {item.title}
                      </span>
                      <span
                        className={
                          useEnglishClasses
                            ? "col-lg-9 offset-lg-0 col-md-6"
                            : "col-md-6"
                        }
                      >
                        {item.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ServicesItemOurExperienceSection;
