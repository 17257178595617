import ViewPortfolioAlfaleads from "components/views/ViewPortfolioItem/ViewPortfolioAlfaleads/ViewPortfolioAlfaleads";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "alfa",
  component: ViewPortfolioAlfaleads,
  title: "alfa",
  name: "alfaleads network",
  altName: "баннеры и постеры",
  year: "2024",
  awardsList: [],

  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/213836291/Alfaleads-Network-Banners-posters",
    },
  ],

  preview: {
    label: "фирменный стиль",
    // backgroundColor: "#131313",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/alfa/preview",
        file: "1.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/alfa/preview",
        file: "2.webp",
      }),
    },
  },

  poolOfWorks: ["Research", "Design"],

  type: "site",
  subtype: "site",

  description: [
    "серия из 10 баннеров, которые соответствуют общей концепции и фирменному стилю компании Alfaleads.",
    "Каждый баннер имеет уникальный дизайн, привлекающий внимание потенциальных клиентов (маркетологов, рекламодателей и владельцев бизнеса, заинтересованных в продвижении своих товаров и услуг через рекламные сети)",
  ],
  shortDescription: "Инвестиционно-строительная холдинговая компания",

  seo: {
    title:
      "Дизайн-поддержка для AlfaLeads: баннеры, креативы и фирменный стиль | SAGIROV",
    description:
      "Реализовали комплексную дизайн-поддержку для AlfaLeads: разработка баннеров, креативов для соцсетей, элементов фирменного стиля и айдентики. Выполняли широкий спектр задач: от промоматериалов до рекламных макетов.",
    keywords: [
      "дизайн-поддержка",
      "баннеры",
      "креативы",
      "креативы для соцсетей",
      "фирменный стиль",
      "айдентика",
      "промоматериалы",
      "рекламные материалы",
      "дизайн AlfaLeads",
    ],
    openGraph: {
      type: "article",
      title:
        "Дизайн-поддержка для AlfaLeads: баннеры, креативы и фирменный стиль | SAGIROV",
      description:
        "Реализовали комплексную дизайн-поддержку для AlfaLeads: разработка баннеров, креативов для соцсетей, элементов фирменного стиля и айдентики. Выполняли широкий спектр задач: от промоматериалов до рекламных макетов.",
    },
  },
};

export default porfolioItem;
