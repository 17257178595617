import ViewPortfolioAag from "components/views/ViewPortfolioItem/ViewPortfolioAag/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "aag",
  component: ViewPortfolioAag,
  title: "aag",
  name: "aag",
  altName: "Инвестиционно-строительная \n холдинговая компания",
  year: "2024",
  awardsList: [],

  link: {
    label: "aag.company",
    href: "https://aag.company/",
  },

  socials: [
    {
      label: "",
      href: "",
    },
  ],

  preview: {
    label: "корпоративный сайт застройщика",
    backgroundColor: "#00387A",
    media: {
      type: "video",
      src: "https://vimeo.com/998697276/e03e46295d",
    },
  },

  poolOfWorks: [
    "technical support",
    "new pages",
    "admin panel",
    "UX|UI design",
  ],

  type: "site",
  subtype: "site",

  description: [
    "это инвестиционно-строительная холдинговая компания, работающая в Санкт-Петербурге и Москве, занимающаяся строительством жилых комплексов высокого и премиум-класса.",
    "Нам необходимо было доработать пользовательский интерфейс и интегрировать новые функциональные разделы.",
  ],
  shortDescription: "Инвестиционно-строительная холдинговая компания",

  seo: {
    title: "Разработка сайта для девелопера застройщика AAG",
    description:
      "Создали сайт для строительной компании AAG, специализирующегося на строительстве многоэтажных домов в Москве и Санкт-Петербурге. Проект включал полную разработку backend, административной панели и обновление frontend для улучшения функциональности и пользовательского опыта",
    keywords: [
      "разработка сайта для девелопера",
      "кейс AAG",
      "девелопер из Санкт-Петербурга",
      "многоэтажные дома",
      "разработка backend для застройщиков",
      "административная панель для сайта застройщика",
      "сайт по продаже квартир",
      "сайт для строительной компании",
    ],
    openGraph: {
      type: "article",
      title: "Разработка сайта для девелопера застройщика AAG",
      description:
        "Создали сайт для строительной компании AAG, специализирующегося на строительстве многоэтажных домов в Москве и Санкт-Петербурге. Проект включал полную разработку backend, административной панели и обновление frontend для улучшения функциональности и пользовательского опыта",
    },
  },
};

export default porfolioItem;
