export type FilePathType = string | { name: string | number; ext: string };
export type FolderPathType = string | string[];

class PathBuilder {
  private _root: string;
  private _path: string;

  constructor(_root: string = "") {
    this._root = _root.replace(/\/*$/, "");
    this._path = this._root;
  }

  public reset() {
    this._path = this._root;

    return this;
  }

  public add(folder: FolderPathType) {
    this._path = this.get({ folder });

    return this;
  }

  public get({
    folder,
    file,
  }: { folder?: FolderPathType; file?: FilePathType } = {}) {
    let path = [this._path];

    if (folder) {
      if (typeof folder === "string") {
        path.push(folder);
      } else {
        path = path.concat(folder);
      }
    }

    if (file) {
      path.push(typeof file === "string" ? file : `${file.name}.${file.ext}`);
    }
    // console.log(path);

    return path.join("/");
  }
}

export default PathBuilder;
