import ViewPortfolioNovaGroup from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "nova-group",
  component: ViewPortfolioNovaGroup,
  title: "nova-group",
  name: "Nova group",
  altName: "Московская архитектурно-\nстроительная компания",
  year: 2020,
  awardsList: [{ imgSrc: "/images/awards/gray-bg/reiting-runeta.png" }],
  link: "novagroup.ru",
  type: "site",
  subtype: "ecommerce",

  preview: {
    label: "корпоративный сайт, e-commerce",
    backgroundColor: "#1F1F21",
    media: {
      type: "video",
      src: "https://vimeo.com/542710265",
    },
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/93319129/WEBSITE-Construction-company-Nova-Group",
    },
  ],

  poolOfWorks: [
    "UX|UI design",
    "Branding",
    "Page making",
    "Integration with 1c-bitrix",
  ],

  relatedId: "nova-group-brand-style",

  description: [
    "архитектурно-строительная компания, занимающаяся строительством частных домов в Москве и Московской области.",
    "Нужно было создать сайт, демонстрирующий качественный подход компании к строительству, подробно рассказать о процессе взаимодействия клиента с компанией",
  ],
  shortDescription: "сайт для Московской архитектурно-строительной компании",
  seo: {
    title: "Разработка сайта для строительной компании NovaGroup",
    description:
      "Дизайн и разработка сайта для московской архитетурно-строительной компании Nova Group специализирующейся на частном строительстве. Аналитика и проработка user flow ЦА при разработке дизайна сайта, интеграция с CMS 1С Битрикс и сквозной аналитикой",
    keywords: [
      "создание сайта строительной компании",
      "пример сайта строительной компании",
      "лучшие сайты для строителей",
      "разработка сайта для строителей в Москве",
      "строительство частных домов",
      "дизайн строительного сайта",
      "разработка сайта нова груп",
    ],
    openGraph: {
      type: "article",
      title: "Разработка сайта для строительной компании NovaGroup",
      description:
        "Дизайн и разработка сайта для московской архитетурно-строительной компании Nova Group специализирующейся на частном строительстве. Аналитика и проработка user flow ЦА при разработке дизайна сайта, интеграция с CMS 1С Битрикс и сквозной аналитикой",
    },
  },
  pageContent: {
    sections: {
      mobileVersion: {
        theme: {
          color: "#fff",
          backgroundColor: "#0242EC",
        },
        frameCount: 161,
      },
    },
  },
};

export default porfolioItem;
