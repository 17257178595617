import { useEffect, useRef, useState } from "react";

import gsap from "gsap";

import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { MorphSvgWord } from "components/common/MorphSvg";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import Print from "components/common/Print";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import Section from "components/layouts/Section";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ViewServicesLeadSectionProps {}

const ViewServicesLeadSection = (props: ViewServicesLeadSectionProps) => {
  const { t, i18n } = useTranslation();
  const [morph, setMorph] = useState(false);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const servicesGroupsRef = useRef<(HTMLElement | null)[]>([]);
  const costBtnRef = useRef<HTMLButtonElement>(null);
  const costBottomBtnRef = useRef<HTMLButtonElement>(null);
  const bottomTitleRef = useRef<HTMLParagraphElement>(null);
  const arrowRef = useRef<HTMLElement>(null);

  const servicesGroupsLabelsRef = useRef<(HTMLElement | null)[]>([]);
  const servicesGroupsListsRef = useRef<(HTMLElement | null)[]>([]);

  // Жестко закодированные данные для английской версии
  const englishData = {
    title: "Our Services",
    description:
      "We create digital products that help businesses grow and achieve their goals",
    servicesGroups: [
      {
        label: "Development",
        items: [
          { text: "Websites", link: "/en/services/website-development" },
          { text: "Mobile apps", link: "/en/services/mobile-apps" },
          { text: "Web applications", link: "/en/services/web-applications" },
          { text: "Online stores", link: "/en/services/online-stores" },
        ],
      },
      {
        label: "Design",
        items: [
          { text: "UX/UI", link: "/en/services/ux-ui" },
          { text: "Branding", link: "/en/services/branding" },
          { text: "Graphic design", link: "/en/services/graphic-design" },
          { text: "Motion design", link: "/en/services/motion-design" },
        ],
      },
      {
        label: "Support",
        items: [
          { text: "Technical support", link: "/en/services/technical-support" },
          { text: "Design support", link: "/en/services/design-support" },
          {
            text: "Content management",
            link: "/en/services/content-management",
          },
          { text: "SEO optimization", link: "/en/services/seo-optimization" },
        ],
      },
    ],
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorph(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Получаем данные для отображения в зависимости от языка
  const getServicesGroups = () => {
    if (i18n.language === "en") {
      return englishData.servicesGroups;
    } else {
      try {
        // Для русской версии используем оригинальный ключ локализации
        const data = t("view:services.section.lead.items", {
          returnObjects: true,
        });

        // Проверяем, что данные являются массивом
        if (!Array.isArray(data)) {
          console.error("Данные не являются массивом:", data);
          return [];
        }

        return data;
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
        return [];
      }
    }
  };

  // Получаем группы услуг
  const servicesGroups = getServicesGroups();

  // Отладочная информация
  console.log("Текущий язык:", i18n.language);
  console.log("Группы услуг:", servicesGroups);
  console.log(
    "Тип данных:",
    Array.isArray(servicesGroups) ? "Массив" : typeof servicesGroups
  );

  useEffect(() => {
    const titleElem = titleRef.current!;
    const descriptionElem = descriptionRef.current!;
    const servicesGroupsElem = servicesGroupsRef.current!;
    const costBtnElem = costBtnRef.current!;
    const costBottomBtnElem = costBottomBtnRef.current!;
    const bottomTitleElem = bottomTitleRef.current!;
    const arrowElem = arrowRef.current!;

    const servicesGroupsLabelsElem = servicesGroupsLabelsRef.current!;
    const servicesGroupsListsElem = servicesGroupsListsRef.current!;

    const timeline = gsap.timeline({
      defaults: { ease: "ease-out", duration: 0.6 },
    });
    const isTablet = matchMedia("(min-width: 768px)").matches;

    const getServicesGroupsTimeline = () => {
      const servicesGroupsTimeline = gsap.timeline({ ease: "none" });

      servicesGroupsElem.forEach((servicesGroupElem, i) => {
        const servicesGroupLabelElem = servicesGroupElem?.children[0];
        const servicesItemsElems =
          servicesGroupElem?.children[1].querySelectorAll(".list__item");
        const servicesGroupTimeline = gsap.timeline({
          ease: "none",
          defaults: { ease: "ease-out", duration: 0.8 },
        });

        servicesGroupTimeline.from(servicesGroupElem, {
          y: 25,
          duration: (servicesItemsElems?.length || 1) * 0.2 + 0.8 - 0.2,
        });

        if (servicesGroupLabelElem) {
          servicesGroupTimeline.from(
            servicesGroupLabelElem,
            {
              y: 15,
              opacity: 0,
            },
            "<"
          );
        }

        if (servicesItemsElems) {
          servicesGroupTimeline.from(
            servicesItemsElems,
            {
              y: 15,
              opacity: 0,
              stagger: 0.2,
            },
            "<"
          );
        }

        servicesGroupsTimeline.add(servicesGroupTimeline, 0);
      });

      return servicesGroupsTimeline;
    };

    if (isTablet) {
      timeline.from(descriptionElem, {
        y: 15,
        opacity: 0,
        delay: 0.5,
      });

      timeline.from(
        costBtnElem,
        {
          y: 15,
          opacity: 0,
          delay: 0.15,
        },
        "<"
      );

      timeline.from(
        servicesGroupsLabelsElem,
        {
          y: 15,
          opacity: 0,
          stagger: 0.2,
        },
        "<"
      );

      timeline.from(
        servicesGroupsListsElem,
        {
          y: 15,
          opacity: 0,
          stagger: 0.2,
        },
        "<"
      );

      timeline.from(
        bottomTitleElem,
        {
          y: 15,
          opacity: 0,
        },
        "<"
      );

      timeline.from(
        arrowElem,
        {
          y: -15,
          opacity: 0,
        },
        "<"
      );
    } else {
      timeline.from(titleElem, {
        y: 15,
        opacity: 0,
        delay: 0.5,
      });

      timeline.add(getServicesGroupsTimeline(), "<");

      timeline.from(
        costBottomBtnElem,
        {
          y: 15,
          opacity: 0,
        },
        "<"
      );

      timeline.from(
        arrowElem,
        {
          y: -15,
          opacity: 0,
        },
        "<"
      );
    }
  }, []);

  return (
    <DynamicBackgroundTrigger>
      <Section viewportHeight isLead className="view-services-lead-section">
        <div className="view-services-lead-section__container lead-section__container container">
          <div className="view-services-lead-section__inner lead-section__inner">
            <header className="view-services-lead-section__header">
              <h1
                ref={titleRef}
                className="view-services-lead-section__title lead-section__title title"
              >
                {i18n.language === "en" ? (
                  englishData.title
                ) : (
                  <RevealWhenInView>
                    <MorphSvgWord
                      className="svg-title"
                      word={t("view:services.section.lead.title")}
                      morph={morph}
                    />
                  </RevealWhenInView>
                )}
              </h1>
            </header>

            <div className="view-services-lead-section__body lead-section__body">
              <div className="view-services-lead-section__body-inner row">
                <div className="view-services-lead-section__body-top">
                  <div className="view-services-lead-section__col-with-description">
                    <RevealWhenInView className="subheading" tag="span">
                      {i18n.language === "en" ? (
                        englishData.description
                      ) : (
                        <Print data="view:services.section.lead.description" />
                      )}
                    </RevealWhenInView>
                  </div>
                </div>

                <div className="view-services-lead-section__body-middle offset-md-4 col-auto offset-xl-0">
                  {Array.isArray(servicesGroups) &&
                  servicesGroups.length > 0 ? (
                    <List
                      items={servicesGroups}
                      itemClassName="view-services-lead-section__services-group"
                    >
                      {(group, i) => (
                        <RevealWhenInView>
                          <div
                            ref={(el) => (servicesGroupsRef.current[i] = el)}
                            className="row"
                          >
                            <div
                              ref={(el) =>
                                (servicesGroupsLabelsRef.current[i] = el)
                              }
                              className="view-services-lead-section__col-with-service-type col-4"
                            >
                              <p className="label side-brackets">
                                {i18n.language === "en" ? (
                                  group.label
                                ) : (
                                  <Print data={group.type} />
                                )}
                              </p>
                            </div>

                            {Array.isArray(group.items) &&
                            group.items.length > 0 ? (
                              <List
                                ref={(el) =>
                                  (servicesGroupsListsRef.current[i] = el)
                                }
                                items={group.items}
                                className="view-services-lead-section__col-with-service-list col offset-1"
                              >
                                {(serviceItem) => {
                                  if (i18n.language === "en") {
                                    const item = serviceItem as {
                                      link: string;
                                      text: string;
                                    };

                                    if (!item || !item.link || !item.text) {
                                      console.error(
                                        "Некорректный элемент списка услуг:",
                                        serviceItem
                                      );
                                      return null;
                                    }

                                    return (
                                      <Link
                                        to={item.link}
                                        className="label text--not-empty link"
                                      >
                                        <p className="link--normal">
                                          {item.text}
                                        </p>
                                      </Link>
                                    );
                                  } else {
                                    // Для русской версии используем оригинальную структуру
                                    const item = serviceItem as {
                                      link: string;
                                      text: string;
                                    };

                                    return item ? (
                                      <Link
                                        to={item.link}
                                        className="label text--not-empty link"
                                      >
                                        <p className="link--normal">
                                          <Print data={item.text} />
                                        </p>
                                      </Link>
                                    ) : null;
                                  }
                                }}
                              </List>
                            ) : (
                              <div className="view-services-lead-section__col-with-service-list col offset-1">
                                <p>Нет доступных услуг</p>
                              </div>
                            )}
                          </div>
                        </RevealWhenInView>
                      )}
                    </List>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <p>Нет доступных групп услуг</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="view-services-lead-section__body-bottom col-xl-11">
                  <p
                    ref={bottomTitleRef}
                    className="view-services-lead-section__title view-services-lead-section__body-bottom-title display title--line-height-none"
                  >
                    <RevealWhenInView tag="span">
                      {i18n.language === "en" ? (
                        englishData.title
                      ) : (
                        <MorphSvgWord
                          className="svg-title"
                          word={t("view:services.section.lead.title")}
                          morph={morph}
                        />
                      )}
                    </RevealWhenInView>
                  </p>
                  <RevealWhenInView className="offset-auto">
                    <ScrollDownButton
                      scrollTo=".view-services-services-section"
                      duration={0.7}
                    />
                  </RevealWhenInView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewServicesLeadSection;
