import { PortfolioItemData } from "data/portfolio/types";
import classNames from "classnames";
import { useCallback } from "react";
import Icon from "components/common/Icon";
import { Link } from "react-router-dom";
import { buildLinkToPortfolioItem } from "routes/helpers";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "./ViewPortfolioItemSection";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import portfolio from "data/portfolio";
import Print from "components/common/Print";
import Image, { ImageConfig } from "components/common/Image";

interface ViewPortfolioItemRelatedSectionProps
  extends ViewPortfolioItemSectionConfigProps,
  Pick<PortfolioItemData, "id"> {
  currentId: PortfolioItemData["id"];
  preview: {
    backgroundColor?: string;
    frame?: string;
    image: ImageConfig;
  };
}

const ViewPortfolioItemRelatedSection = ({
  id,
  currentId,
  preview,

  ...sectionProps
}: ViewPortfolioItemRelatedSectionProps) => {
  const relatedPortfolio = portfolio.ru.find(
    (portfolioItem) => portfolioItem.id === id
  );

  const translateCaption = useCallback(
    (data, t) => {
      return t(data, {
        name: relatedPortfolio?.name,
        type: t([
          `portfolioItem:${currentId}.related.captionType.${relatedPortfolio?.type}`,
          `view:portfolioItem.related.captionType.${relatedPortfolio?.type}`,
        ]),
      });
    },
    [relatedPortfolio, currentId]
  );

  if (!relatedPortfolio) {
    return null;
  }

  return (
    <ViewPortfolioItemSection
      dynamicBackgroundColor={false}
      {...sectionProps}
      className="view-portfolio-item-related-section"
    >
      <Link
        to={buildLinkToPortfolioItem(relatedPortfolio.id)}
        className="view-portfolio-item-related-section__inner link row"
      >
        <div className="view-portfolio-item-related-section__col-with-preview col-11 col-lg-5 col-xl-4">
          <div
            style={{ backgroundColor: preview.backgroundColor || preview.frame }}
            className={classNames(
              "view-portfolio-item-related-section__preview",
              preview.frame &&
              "view-portfolio-item-related-section__preview--frame"
            )}
          >
            <div
              className={classNames(
                "view-portfolio-item-related-section__preview-container",
                preview.frame && "container"
              )}
            >
              <RevealWhenInView className="view-portfolio-item-related-section__preview-reveal-container">
                <Image
                  className="view-portfolio-item-related-section__preview-image"
                  {...preview.image}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
        <div className="view-portfolio-item-related-section__col-with-info col-11 col-lg-5 col-xl-6 offset-lg-1">
          <div className="view-portfolio-item-related-section__info">
            <div className="view-portfolio-item-related-section__info-container container">
              <div className="view-portfolio-item-related-section__info-inner">
                <div className="view-portfolio-item-related-section__info-top">
                  <RevealWhenInView>
                    <p className="view-portfolio-item-related-section__description footnote text--pre-line u-max-w-md-480">
                      <Print
                        data="view:portfolioItem.related.caption"
                        translate={translateCaption}
                      />
                    </p>
                  </RevealWhenInView>
                </div>
                <div className="view-portfolio-item-related-section__info-bottom">
                  <div className="view-portfolio-item-related-section__info-about">
                    <RevealWhenInView>
                      <h3 className="view-portfolio-item-related-section__info-title">
                        <span className="view-portfolio-item-related-section__info-name u-euclid-flex-indent-fix u-euclid-flex-feature">
                          {relatedPortfolio.name}
                        </span>
                        <span className="view-portfolio-item-related-section__info-type label side-brackets">
                          {relatedPortfolio.type}
                        </span>
                      </h3>
                    </RevealWhenInView>
                  </div>
                  <RevealWhenInView
                    from={{ opacity: 0, x: -20 }}
                    to={{ opacity: 1, x: 0 }}
                  >
                    <Icon
                      name="arrow-right"
                      className="view-portfolio-item-related-section__arrow"
                    />
                  </RevealWhenInView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioItemRelatedSection;
