import { SeoOpenGraphItem, Seo } from "types/global";

/**
 * Возвращает массив состоящий из массивов, которые состоят из свойства og и контента og
 * Используется для рекурсивной трансформации объекта в og
 *
 * @example
 *
 * // returns [
 * //   ["og:image:width", "400"],
 * //   ["og:image:height", "400"]
 * // ]
 * makeOgItems("og:image", {width: "400", height: "400"})
 *
 */
const makeOgItems = (
  name: string,
  content: string | SeoOpenGraphItem
): [string, string][] => {
  let result: [string, string][] = [];

  if (typeof content === "string") {
    result = [[name, content]];
  } else {
    for (const ogItemName in content) {
      const ogItemContent = content[ogItemName];
      if (ogItemContent) {
        result = [
          ...result,
          ...makeOgItems(`${name}:${ogItemName}`, ogItemContent),
        ];
      }
    }
  }

  return result;
};

/**
 * Возвращает массив состоящий из массивов, которые состоят из свойства og и контента og
 *
 * @example
 *
 * // returns [
 * //   ["og:title", "title"],
 * //   ["og:image:width", "400"],
 * //   ["og:image:height", "400"]
 * // ]
 * makeOgItems({title: "title", image: {width: "400", height: "400"}})
 *
 */
const getOgItems = (og?: SeoOpenGraphItem): [string, string][] => {
  if (!og) {
    return [];
  }

  let result: [string, string][] = [];

  for (let ogName in og) {
    const ogContent = og[ogName];
    if (ogContent) {
      const fullOgName = `og:${ogName}`;
      result = [...result, ...makeOgItems(fullOgName, ogContent)];
    }
  }

  return result;
};

export default getOgItems;
