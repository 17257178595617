import { memo, useEffect, useRef } from "react";
import VacancyAboutSection from "./VacancyAboutSection";
import VacancyBonusesSection from "./VacancyBonusesSection";
import VacancyCatalogSection from "./VacancyCatalogSection";
import VacancyExperienceSection from "./VacancyExperienceSection";
import VacancyLeadSection from "./VacancyLeadSection";
import gsap from "gsap";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import {useTranslation} from "react-i18next";

export interface VacancyProps { }

const Vacancy = memo((props: VacancyProps) => {
  const viewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const viewElem = viewRef.current!;
    const leadSectionTitle = viewElem.querySelector(
      ".vacancy-lead-section__title"
    );
    const leadSectionHeaderEmailLink = viewElem.querySelector(
      ".vacancy-lead-section__header-email-link"
    );
    const leadSectionBodyEmailLink = viewElem.querySelector(
      ".vacancy-lead-section__body-email-link"
    );
    const leadSectionLookingFor = viewElem.querySelector(
      ".vacancy-lead-section__looking-for"
    );
    const leadSectionDescription = viewElem.querySelector(
      ".vacancy-lead-section__description"
    );
    const leadSectionArrow = viewElem.querySelector(
      ".vacancy-lead-section__arrow"
    );

    const timeline = gsap.timeline({
      delay: 0.4,
      defaults: { ease: "ease-out", duration: 0.8 },
    });

    const isMinMd = matchMedia("(min-width: 768px)").matches;
    const isMinXxl = matchMedia("(min-width: 1440px)").matches;

    timeline.from(leadSectionTitle, {
      y: 20,
      opacity: 0,
    });

    if (isMinMd) {
      timeline.from(
        leadSectionHeaderEmailLink,
        {
          y: 20,
          opacity: 0,
        },
        "<"
      );
    }

    timeline.from(
      leadSectionLookingFor,
      {
        y: 20,
        opacity: 0,
      },
      /* `-=0.6` */ 0
    );

    if (isMinMd) {
      timeline.from(
        leadSectionDescription,
        {
          y: 30,
          opacity: 0,
        },
        isMinXxl ? "<" : /* `-=0.6` */ 0
      );
    } else {
      timeline.from(
        leadSectionBodyEmailLink,
        {
          y: 20,
          opacity: 0,
        },
        /* `-=0.6` */ 0
      );
    }

    timeline.from(
      leadSectionArrow,
      {
        y: -20,
        opacity: 0,
      },
      "<"
    );
  }, []);

  const { t } = useTranslation();

  return (
    <View ref={viewRef} className="view--vacancy">
      <VacancyLeadSection />
      <VacancyAboutSection />
      <VacancyCatalogSection />
      <VacancyExperienceSection />
      <VacancyBonusesSection />

      <Footer className="view-vacancy-footer" backgroundColor="gray">
        <div className="row">
          <div className="view-vacancy-footer__header col-lg-4">
            <h3 className="footer__heading heading">
              <span className="text--muted">{t("view:vacancies.footer.title.0")}</span><br />
              <span className="text--muted">{t("view:vacancies.footer.title.1")}</span><br />
              {t("view:vacancies.footer.title.2")}
            </h3>
          </div>

          <div className="footer__content col-11 offset-lg-1 col-lg">
            <p className="view-vacancy-footer__content-label sublabel sublabel--secondary">{t("view:vacancies.footer.mail.text")}</p>
            <div className="row u-max-w-480">
              <div className="col-3">
                <p className="view-vacancy-footer__content-item-label label side-brackets text--lowercase">
                  {t("view:vacancies.footer.mail.label")}
                </p>
              </div>
              <div className="col">
                <a href={"mailto:" + t("view:vacancies.footer.mail.link")} className="view-vacancy-footer__content-item-link label link link--normal">
                  {t("view:vacancies.footer.mail.link")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </View>
  );
});

export default Vacancy;
