import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { DEFAULT_LOCALE } from "config/i18n";

// Импортируем только типы, чтобы избежать циклической зависимости
import type { RouteItem } from "./";

// Функции для получения маршрутов динамически
const getRoutes = () => {
  const routesModule = require("./");
  return routesModule.routes;
};

const getPortfolio = () => {
  const routesModule = require("./");
  return routesModule.portfolio;
};

/**
 * Создает url по id портфолио
 */
export const buildLinkToPortfolioItem = (
  portfolioItemId: string | number
): string => {
  const portfolio = getPortfolio();
  return `${portfolio.path.replace("/:locale( |en)?", "")}/${portfolioItemId}`;
};

/**
 * Хук возвращает текущий раут
 */
export const useCurrentRoute = () => {
  const location = useLocation();

  const currentRoute = useMemo(() => {
    const routes = getRoutes();
    return routes.find((route: RouteItem) =>
      matchPath(location.pathname, route)
    );
  }, [location.pathname]);
  return currentRoute;
};

/**
 * Создает локализованный URL
 */
export const createLocalizedUrl = (
  path: string,
  locale: string = DEFAULT_LOCALE
): string => {
  // Убираем начальный слэш если есть
  const cleanPath = path.startsWith("/") ? path.slice(1) : path;

  // Для дефолтной локали не добавляем префикс
  if (locale === DEFAULT_LOCALE) {
    return `/${cleanPath}`;
  }

  return `/${locale}/${cleanPath}`;
};

/**
 * Извлекает локаль из URL
 */
export const extractLocaleFromUrl = (url: string): string => {
  const parts = url.split("/").filter(Boolean);
  return parts[0] || DEFAULT_LOCALE;
};

/**
 * Извлекает путь без локали из URL
 */
export const extractPathFromUrl = (url: string): string => {
  const parts = url.split("/").filter(Boolean);
  return parts.slice(1).join("/");
};
