import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import ViewPortfolioItemRelatedSection from "../ViewPortfolioItemRelatedSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";

import { ViewPortfolioItemSectionConfigProps } from "../ViewPortfolioItemSection";

export interface ViewPortfolioRostProps { }

const ViewPortfolioRost = (props: ViewPortfolioRostProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);


  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{
          backgroundColor: "#111111",
          color: "#fff",
        }}
        image={{
          isLogo: true,
          src: buildPageImageSrc("about", "svg"),
        }}
        name={data.name}
        description={data.description}
      />
      {[
        {
          size: "md",
          image: {
            src: buildPageImageSrc("screen-3"),
          },
          backgroundColor: "#fff",
        },
        {
          size: "sm",
          image: {
            src: buildPageImageSrc("screen-4"),
          },
          backgroundColor: "#111111",
        },
        {
          size: {
            top: "xl",
            bottom: "xxl",
          },
          image: {
            src: buildPageImageSrc("screen-5"),
          },
        },
      ].map(({ image, backgroundColor, size }, i) => (
        <ViewPortfolioItemSection
          key={i}
          style={{ backgroundColor }}
          size={size as ViewPortfolioItemSectionConfigProps["size"]}
        >
          <ViewPortfolioItemImage {...image} />
        </ViewPortfolioItemSection>
      ))}

      {/* <ViewPortfolioRostGallerySection /> */}
      <ViewPortfolioItemParallaxSection
        image={{
          src: buildPageImageSrc("screen-6"),
        }}
      />

      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          id={data.relatedId}
          dynamicBackgroundColor
          style={{ backgroundColor: "#1F1F1F", color: "#fff" }}
          preview={{
            frame: "#111111",
            image: {
              src: buildPageImageSrc("related"),
            },
          }}
        />
      )}
    </>
  );
};

export default ViewPortfolioRost;
