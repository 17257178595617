import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemParallaxSection from '../ViewPortfolioItemParallaxSection';
import ViewPortfolioDomaVekaEquipmentCardSection from './ViewPortfolioDomaVekaEquipmentCardSection';
import ViewPortfolioDomaVekaGallerySection from './ViewPortfolioDomaVekaGallerySection';
import { useTranslation } from 'react-i18next';
import ViewPortfolioItemRelatedSection from '../ViewPortfolioItemRelatedSection';

export interface ViewPortfolioDomaVekaProps {}

const ViewPortfolioDomaVeka = (props: ViewPortfolioDomaVekaProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: '#57644F', color: '#fff' }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc('screen-2', 'svg'),
        }}
        description={data.description}
      />
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-3', 'jpg'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-3'),
            },
          ],
        }}
      />
      <ViewPortfolioDomaVekaGallerySection
        style={{ backgroundColor: '#383838' }}
        images={[
          {
            src: buildPageImageSrc('screen-4', 'jpg'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-4'),
              },
            ],
          },
          {
            src: buildPageImageSrc('screen-5', 'jpg'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-5'),
              },
            ],
          },
        ]}
        size={{
          top: 'xl',
          bottom: 'xxl',
        }}
      />
      <ViewPortfolioDomaVekaEquipmentCardSection
        style={{
          backgroundColor: '#CCCCCC',
        }}
        image={{
          src: buildPageImageSrc('screen-6', 'jpg'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-6'),
            },
          ],
        }}
        caption={[
          t('portfolioItem:doma-veka.equipment-section.0'),
          t('portfolioItem:doma-veka.equipment-section.1'),
        ]}
        size={{
          top: 'xxs',
          bottom: 'sm',
        }}
      />
      <ViewPortfolioDomaVekaGallerySection
        style={{ backgroundColor: '#fff' }}
        images={[
          {
            src: buildPageImageSrc('screen-7', 'gif'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-7'),
              },
            ],
          },
        ]}
        size="lg"
      />
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-8', 'jpg'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-8'),
            },
          ],
        }}
      />
      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          id={data.relatedId}
          style={{ backgroundColor: '#57644F', color: '#fff' }}
          preview={{
            backgroundColor: '#F3F4F4',
            image: {
              src: buildPageImageSrc('related'),
            },
          }}
        />
      )}
    </>
  );
};

export default ViewPortfolioDomaVeka;
