import React, { useEffect, useRef, useState } from "react";
import DistortionImage from "../../common/DistortionImage";
import { PortfolioItemMediaTriggerProp } from "./PortfolioItemPreview";
import { DistortionImageProps } from "components/common/DistortionImage/DistortionImage";
import { EffectTrigger } from "types/global";

interface PortfolioItemPreviewImageDataImage {
  src: string;
  sources?: { media: string; src: string }[];
}

export interface PortfolioItemPreviewImageData {
  image: Record<
    "first" | "second" | "displacement",
    PortfolioItemPreviewImageDataImage
  >;
  alt?: string;
}

interface PortfolioItemPreviewImageProps
  extends Pick<DistortionImageProps, "image"> {
  trigger?: EffectTrigger;
}

const PortfolioItemPreviewImage = ({
  trigger,
  ...otherProps
}: PortfolioItemPreviewImageProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [to, setTo] = useState<0 | 1>(0);

  useEffect(() => {
    const containerEl = ref.current!;

    const handleMouseEnter = () => {
      setTo((prevState) => (prevState === 1 ? 0 : 1));
    };

    const handleMouseLeave = () => {
      setTo((prevState) => (prevState === 1 ? 0 : 1));
    };

    containerEl.addEventListener("mouseenter", handleMouseEnter);
    containerEl.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      containerEl.removeEventListener("mouseenter", handleMouseEnter);
      containerEl.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div ref={ref} style={{ width: "100%" }}>
      <DistortionImage
        to={to}
        className="portfolio-item__preview-media"
        intensity={-0.1}
        {...otherProps}
      />
    </div>
  );
};

export default PortfolioItemPreviewImage;
