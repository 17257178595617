import { PortfolioItemData } from "data/portfolio/types";
import { ASSETS_PATH, FileFullName, VIEWS_PATH } from "helpers/path";
import PathBuilder, { FilePathType, FolderPathType } from "helpers/PathBuilder";
import { useCallback, useMemo } from "react";

export const usePortfolioItemAssets = (
  portfolioItemId: PortfolioItemData["id"]
) => {
  const portfolioItemPathBuilder = useMemo(() => {
    const pathBuilder = new PathBuilder(`${ASSETS_PATH}/portfolios`);

    return pathBuilder.add(portfolioItemId);
  }, [portfolioItemId]);

  const getter = useMemo(() => {
    const buildSrc = (file: FilePathType, folder?: FolderPathType) => {
      return portfolioItemPathBuilder.get({ file, folder });
    };

    const buildPageImageSrc = (fileName: string, ext: string = "webp") => {
      if (!fileName) return "";

      const timestamp = new Date().getTime();
      const path = `/assets/portfolios/${portfolioItemId}/page/${fileName}.${ext}`;

      return `${path}?v=${timestamp}`;
    };

    const getPreviewSrc = (file: FilePathType) => {
      return buildSrc(file, "preview");
    };

    const getViewSrc = (file: FilePathType) => {
      return buildSrc(file, "view");
    };

    const getViewImage = (file: FileFullName) => {
      return {
        src: getViewSrc({
          name: `${file.name}-1440w`,
          ext: file.ext,
        }),
        sources: [1440, 768, 414].map((breakpoint) => ({
          media: `(min-width: ${breakpoint}px)`,
          srcSet: getViewSrc({
            name: `${file.name}-${breakpoint}w`,
            ext: file.ext,
          }),
        })),
      };
    };

    return {
      buildSrc,
      getPreviewSrc,
      getViewSrc,
      getViewImage,
      buildPageImageSrc,
    };
  }, [portfolioItemPathBuilder]);

  return getter;
};

export const useViewAssets = (view: string) => {
  const viewAssetPathBuilder = useMemo(() => {
    const pathBuilder = new PathBuilder(VIEWS_PATH);

    return pathBuilder.add(view);
  }, [view]);

  const getAsset: typeof viewAssetPathBuilder.get = useCallback(
    (params) => {
      return viewAssetPathBuilder.get(params);
    },
    [viewAssetPathBuilder]
  );

  const getImageSrc = useCallback(
    (name: string, ext: string = "webp", size?: number) => {
      return viewAssetPathBuilder.get({
        folder: name,
        file: { name: size ? `${name}-${size}w` : name, ext },
      });
    },
    [viewAssetPathBuilder]
  );

  return {
    getImageSrc,
    getAsset,
  };
};
