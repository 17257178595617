export const sectionData = {
  title: "отзывы клиентов",
  subtitle:
    "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
  reviews: [
    {
      title: "Мартынович Михаил",
      subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
      point: 5,
      text: "Команда студии SAGIROV.com показала себя надежным подрядчиком. Мы сотрудничаем с 2019 года, и за это время было реализовано множество задач по разработке и поддержке нашего сайта. Отдельно хочу отметить оперативность в решении срочных задач и высокое качество работы.",
      icon: "sk10-paginator.svg",
    },
    {
      title: "Иванов Алексей",
      subtitle: "директор по маркетингу - компания 'Дома Века'",
      point: 5,
      text: "Работаем со студией SAGIROV.com уже более 5 лет. За это время был разработан и несколько раз обновлен наш корпоративный сайт. Команда всегда предлагает современные решения и оперативно реагирует на наши запросы. Рекомендую как надежного партнера в сфере веб-разработки.",
      icon: "doma-veka-paginator.svg",
    },
    {
      title: "Петрова Елена",
      subtitle: "руководитель отдела продаж - AAG",
      point: 5,
      text: "Благодарим команду SAGIROV.com за профессиональный подход к разработке нашего сайта. Особенно ценим внимание к деталям и готовность учитывать все наши пожелания. Сайт получился не только красивым, но и функциональным, что положительно сказалось на конверсии и продажах.",
      icon: "aag-paginator.svg",
    },
  ],
  footerText: "следующий отзыв",
};
