import Parallax from "components/common/Parallax";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Print from "components/common/Print";
import { buildPublicImageSrc } from "helpers/path";

interface VacancyAboutSectionProps {}

const VacancyAboutSection = (props: VacancyAboutSectionProps) => {
  return (
    <section className="vacancy-about-section background--red">
      <div className="vacancy-about-section__container-with-image">
        <Parallax
          className="vacancy-about-section__parallax"
          image={{
            src: buildPublicImageSrc({
              path: "views/vacancy",
              file: "working-man-1440w.webp",
            }),
            sources: [
              {
                srcSet: buildPublicImageSrc({
                  path: "views/vacancy",
                  file: "working-man-1440w.webp",
                }),
                media: "(min-width: 1440px)",
              },
              {
                srcSet: buildPublicImageSrc({
                  path: "views/vacancy",
                  file: "working-man-1440w.webp",
                }),
                media: "(min-width: 768px)",
              },
            ],
          }}
        />
      </div>
      <div className="vacancy-about-section__container-with-text container">
        <RevealWhenInView>
          <p className="text text--indent-third text--light">
            <Print data="view:vacancies.lead.description" />
          </p>
        </RevealWhenInView>
      </div>
    </section>
  );
};

export default VacancyAboutSection;
