import Button from "components/common/Button";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { isMenuOpen } from "redux_/store/app/selectors";
import { closeMenu, openMenu } from "redux_/store/app/actions";
import gsap from "gsap";

import { useEffect, useRef, useState } from "react";
import Print from "components/common/Print";
import { useTranslation } from "react-i18next";

interface MenuToggleProps {
  className?: string;
}

const MenuToggle = ({ className }: MenuToggleProps) => {
  const { t, i18n } = useTranslation(["translation"]);
  const menuOpen = useSelector(isMenuOpen);
  const dispatch = useDispatch();

  const getMenuToggleLabel = (state: boolean) =>
    state ? t("Menu") : t("Close");

  const [label, setLabel] = useState(getMenuToggleLabel(!menuOpen));

  useEffect(() => {
    setLabel(getMenuToggleLabel(!menuOpen));
  }, [i18n.language]);

  const isFirstAppear = useRef(true);
  const toggleInnerRef = useRef<HTMLDivElement>(null);
  const toggleLabelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Отключаем проигрывание анимации при входе на страницу
    if (isFirstAppear.current) {
      isFirstAppear.current = false;

      return;
    }

    const timeline = gsap.timeline();
    const toggleInnerElem = toggleInnerRef.current!;
    const toggleLabelElem = toggleLabelRef.current!;
    const prevLabelWidth = toggleLabelElem.getBoundingClientRect().width;

    const hidePrevLabelTween = gsap.fromTo(
      toggleInnerElem,
      {
        yPercent: 0,
        opacity: 1,
      },
      {
        yPercent: -80,
        opacity: 0,
        duration: 0.3,
        ease: "ease-in",
        width: toggleLabelElem.getBoundingClientRect().width,
      }
    );

    const updateLabelWidthTween = gsap.fromTo(
      toggleInnerElem,
      {
        width: prevLabelWidth,
      },
      {
        width: () => toggleLabelElem.getBoundingClientRect().width,
        ease: "ease-in-out",
        duration: 0.2,
      }
    );

    const showNextLabelTween = gsap.fromTo(
      toggleInnerElem,
      {
        yPercent: 80,
        opacity: 0,
      },
      {
        yPercent: 0,
        opacity: 1,
        duration: 0.3,
        ease: "ease-out",
      }
    );

    const resetLabelWidthTween = gsap.set(toggleInnerElem, {
      clearProps: "all",
    });

    const changeLabel = () => setLabel(getMenuToggleLabel(!menuOpen));

    timeline.add(hidePrevLabelTween);
    timeline.add(changeLabel);
    timeline.add(updateLabelWidthTween);
    timeline.add(showNextLabelTween);
    timeline.add(resetLabelWidthTween);

    return () => {
      timeline.kill();
    };
  }, [menuOpen, t]);

  const toggleMenu = () => {
    const action = menuOpen ? closeMenu : openMenu;

    dispatch(action());
  };

  return (
    <Button
      onClick={toggleMenu}
      className={classNames(
        "menu__toggle",
        `menu__toggle--${menuOpen ? "opened" : "closed"}`,
        "side-brackets side-brackets--hover-expand",
        className
      )}
    >
      <div ref={toggleInnerRef} className="menu__toggle-inner">
        <span ref={toggleLabelRef} className="menu__toggle-label label">
          <Print data={label} />
        </span>
      </div>
    </Button>
  );
};

export default MenuToggle;
