import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import gsap from "gsap";
import { NavLink, useHistory, useLocation } from "react-router-dom";

interface LanguageSwitcherProps {
  className?: string;
}

/**
 * Переключатель языка
 */
const LanguageSwitcher = ({ className }: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();
  const langCodeElRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const locationSite = useLocation();
  const [handleToggle, setToggle] = useState(false);

  const nextLangCode = useMemo(() => {
    return i18n.language === "en" ? "ru" : "en";
  }, [i18n.language]);

  let languageBrowser = window.navigator ? window.navigator.language : "ru";
  languageBrowser =
    languageBrowser.substr(0, 2).toLowerCase() === "ru" ? "ru" : "en";
  if (localStorage.getItem("language") === null) {
    if (languageBrowser !== i18n.language) {
      const pathWithoutLang =
        locationSite.pathname === "/en"
          ? ""
          : locationSite.pathname.replace("/en/", "");

      history.push(
        "/" +
          (languageBrowser === "ru" ? "" : languageBrowser + "/") +
          (pathWithoutLang.startsWith("/")
            ? pathWithoutLang.substring(1)
            : pathWithoutLang)
      );
      i18n.changeLanguage(languageBrowser);
      localStorage.setItem("language", languageBrowser);
    }
  } else if (
    localStorage.getItem("language") !== i18n.language &&
    !handleToggle
  ) {
    const pathWithoutLang =
      locationSite.pathname === "/en"
        ? ""
        : locationSite.pathname.replace("/en/", "");

    history.push(
      "/" +
        (localStorage.getItem("language") === "ru"
          ? ""
          : localStorage.getItem("language") + "/") +
        (pathWithoutLang.startsWith("/")
          ? pathWithoutLang.substring(1)
          : pathWithoutLang)
    );
    // @ts-ignore
    i18n.changeLanguage(localStorage.getItem("language"));
  }

  const location = useLocation();

  const toggleLanguage = useCallback(() => {
    setToggle(true);
    localStorage.setItem("language", nextLangCode);
    setTimeout(() => {
      const langCodeEl = langCodeElRef.current!;
      const timeline = gsap.timeline();

      const hidePrevLabelTween = gsap.fromTo(
        langCodeEl,
        {
          yPercent: 0,
          opacity: 1,
        },
        {
          yPercent: -80,
          opacity: 0,
          duration: 0.3,
          ease: "ease-in",
        }
      );

      const showNextLabelTween = gsap.fromTo(
        langCodeEl,
        {
          yPercent: 80,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          duration: 0.3,
          ease: "ease-out",
        }
      );

      timeline.add(hidePrevLabelTween);
      timeline.add(() => {
        i18n.changeLanguage(nextLangCode);
      });
      timeline.add(showNextLabelTween);
    }, 1000);
  }, [nextLangCode, i18n]);

  return (
    <div className={classNames("language-switcher", className)}>
      <Button className="language-switcher__button" onClick={toggleLanguage}>
        <NavLink
          to={
            "/" +
            (nextLangCode === "ru" ? "" : nextLangCode + "/") +
            (location.pathname === "/en"
              ? ""
              : location.pathname.replace("/en/", "").startsWith("/")
              ? location.pathname.replace("/en/", "").substring(1)
              : location.pathname.replace("/en/", ""))
          }
        >
          <span ref={langCodeElRef} className="language-switcher__lang-code">
            {nextLangCode}
          </span>
        </NavLink>
      </Button>
    </div>
  );
};

export default LanguageSwitcher;
