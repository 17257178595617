import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import VacancyItem from "./VacancyItem";
import { vacancies } from "data";

interface VacancyCatalogSectionProps { }

const VacancyCatalogSection = (props: VacancyCatalogSectionProps) => {
  return (
    <DynamicBackgroundTrigger>
      <section className="vacancy-catalog-section">
        {vacancies.items.map((vacancy: any) => (
          <VacancyItem key={vacancy.id} vacancy={vacancy} />
        ))}
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default VacancyCatalogSection;
