import Section from "components/layouts/Section";
import Icon from "components/common/Icon";
import { useTranslation } from "react-i18next";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

function ViewServicesListSection() {
  const { t, i18n } = useTranslation();

  // Жестко закодированный список услуг для английской версии
  const englishServicesList = [
    {
      title: "Website Development",
      description: "We develop websites, online stores, and online services",
      link: "/en/services/website-development",
      items: ["JavaScript", "Python", "PHP", "Java", "React", "Vue.js"],
    },
    {
      title: "UX/UI Design",
      description:
        "Design that solves business challenges and evokes emotions in users",
      link: "/en/services/ux-ui",
      items: ["Figma", "Illustrator", "Photoshop", "Principle"],
    },
    {
      title: "Branding",
      description: "We create only the best first impression of your company",
      link: "/en/services/branding",
      items: ["Figma", "Illustrator", "Photoshop", "InDesign"],
    },
    {
      title: "Technical Support",
      description:
        "Technical support and development of projects using modern frameworks",
      link: "/en/services/technical-support",
      items: ["JavaScript", "Python", "PHP", "Java", "React", "Vue.js"],
    },
    {
      title: "Design Support",
      description: "We strengthen or completely replace design departments",
      link: "/en/services/design-support",
      items: ["UX/UI", "Analysts", "Managers"],
    },
    {
      title: "Outstaffing",
      description: "We select suitable specialists or teams for your project",
      link: "/en/services/outstaffing",
      items: ["Designers", "Developers", "Analysts", "Managers"],
    },
  ];

  // Пробуем получить данные из локализации для русской версии
  let servicesList = [];
  try {
    if (i18n.language === "ru") {
      servicesList = t("view:services.section.servicesList", {
        returnObjects: true,
      }) as any[];

      if (!Array.isArray(servicesList) || servicesList.length === 0) {
        console.warn(
          "Не удалось получить список услуг из локализации, используем заглушку"
        );
        servicesList = [];
      }
    } else {
      // Для английской версии используем жестко закодированный список
      servicesList = englishServicesList;
    }
  } catch (error) {
    console.error("Ошибка при получении данных из локализации:", error);
    servicesList = i18n.language === "en" ? englishServicesList : [];
  }

  // Если список пуст, используем заглушку
  const displayList =
    Array.isArray(servicesList) && servicesList.length > 0
      ? servicesList
      : i18n.language === "en"
      ? englishServicesList
      : [];

  return (
    <DynamicBackgroundTrigger>
      <Section className="view-services-services-section row">
        {displayList.map((item, index) => {
          // Пропускаем элементы без свойства link
          if (!item || !item.link) {
            console.error(
              `Пропускаем элемент ${index} без свойства link:`,
              item
            );
            return null;
          }

          return (
            <a
              key={index}
              href={item.link}
              className="view-services-services-section__link-item row col-11"
            >
              <h4 className="col-md-4 col-11 subheading">
                {item.title || "No title"}
              </h4>
              <div className="col-md-5 col-10 offset-md-1 offset-0 description-container">
                <h6 className="heading-6">
                  {item.description || "No description"}
                </h6>
                <div className="row subitem-container">
                  {item.items &&
                    Array.isArray(item.items) &&
                    item.items.map((option: string, optionIndex: number) => {
                      return (
                        <p key={optionIndex} className="subitem footnote">
                          {option || ""}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className="col-1 arrow-link">
                <Icon name="arrow-down" className="link_arrow" />
              </div>
            </a>
          );
        })}
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ViewServicesListSection;
