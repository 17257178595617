import List from 'components/common/List';
import { DynamicBackgroundTrigger } from 'components/common/DynamicBackground';
import RevealWhenInView from 'components/common/RevealInView/RevealWhenInView';
import { MorphSvgWord } from 'components/common/MorphSvg';
import { memo, useEffect, useState } from 'react';
import Print from 'components/common/Print';
import ScrollDownButton from 'components/layouts/ScrollDownButton';
import { useViewAssets } from 'hooks/useAssets';
// import Parallax from "components/common/Parallax";
// import { Parallax } from "react-scroll-parallax";
import Parallax from 'components/common/Parallax';
import Section from 'components/layouts/Section';
interface ViewAboutLeadSectionProps {}

const stats = [
  {
    label: 'view:about.lead.stat-label.projects',
    number: 89,
  },
  {
    label: 'view:about.lead.stat-label.experience',
    number: 9,
  },
];

const ViewAboutLeadSection = (props: ViewAboutLeadSectionProps) => {
  const [morphTitle, setMorphTitle] = useState(false);
  const viewAssets = useViewAssets('about');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorphTitle(true);
    }, 1600);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <DynamicBackgroundTrigger>
      <Section
        viewportHeight
        isLead
        className="view-about-lead-section background--body">
        <div className="view-about-lead-section__background background container row">
          <div className="view-about-lead-section__background-col-with-parallax col-5">
            <div className="view-about-lead-section__background-container">
              <Parallax
                image={{
                  src: viewAssets.getImageSrc('portrait', 'jpg', 1280),
                  srcSet: [
                    [viewAssets.getImageSrc('portrait', 'jpg', 1280), '1x'],
                    [viewAssets.getImageSrc('portrait', 'jpg', 2560), '2x'],
                  ],
                  sources: [
                    {
                      type: 'webp',
                      srcSet: [
                        [
                          viewAssets.getImageSrc('portrait', 'webp', 1280),
                          '1x',
                        ],
                        [
                          viewAssets.getImageSrc('portrait', 'webp', 2560),
                          '2x',
                        ],
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="view-about-lead-section__content">
          <div className="view-about-lead-section__container lead-section__container container">
            <div className="view-about-lead-section__inner lead-section__inner">
              <header className="view-about-lead-section__header">
                <div className="view-about-lead-section__title">
                  <MorphSvgWord
                    className="svg-title"
                    word="sagirov.com"
                    morph={morphTitle}
                  />
                  {/* <span className="view-about-lead-section__title-text u-euclid-flex-feature">
                  {t("view:about.lead.title")}
                </span> */}
                </div>
              </header>
              <div className="view-about-lead-section__body lead-section__body row">
                <div className="view-about-lead-section__body-inner col-11 col-md-auto">
                  <List
                    items={stats}
                    className="view-about-lead-section__stats-list">
                    {(stat) => (
                      <RevealWhenInView>
                        <p className="view-about-lead-section__stat">
                          <span className="view-about-lead-section__stat-number title title--line-height-none">
                            {stat.number}
                          </span>
                          <span className="view-about-lead-section__stat-label label side-brackets">
                            <Print data={stat.label} />
                          </span>
                        </p>
                      </RevealWhenInView>
                    )}
                  </List>
                  <p className="view-about-lead-section__description offset-sm-4 offset-md-0">
                    <RevealWhenInView
                      tag="span"
                      className="u-max-w-360"
                      targetTag="span">
                      <Print data="view:about.lead.description" />
                    </RevealWhenInView>
                  </p>
                </div>
              </div>
              <footer className="view-about-lead-section__footer">
                <div className="view-about-lead-section__footer-title">
                  <div className="view-about-lead-section__title">
                    <MorphSvgWord
                      className="svg-title"
                      word="sagirov.com"
                      morph={morphTitle}
                    />
                  </div>
                  {/* <span className="view-about-lead-section__title-text">
                  <RevealWhenInView tag="span">
                    {t("view:about.lead.title")}
                  </RevealWhenInView>
                </span> */}
                  <RevealWhenInView
                    className="view-about-lead-section__arrow"
                    from={{ y: -15, opacity: 0 }}
                    to={{ y: 0, opacity: 1 }}
                    tag="span">
                    <ScrollDownButton scrollTo=".view-about-services-section" />
                    {/* <Icon
                      name="arrow-down"
                      className="view-about-lead-section__arrow lead-section__arrow"
                    /> */}
                  </RevealWhenInView>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default memo(ViewAboutLeadSection);
