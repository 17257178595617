import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import React from "react";

export interface ViewPortfolioV6Props {}

const ViewPortfolioV6 = (props: ViewPortfolioV6Props) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-v6-site"
        caption={[t("portfolioItem:v6.about.0"), t("portfolioItem:v6.about.1"), t("portfolioItem:v6.about.2"), ]}
        video={{
            src: 'https://vimeo.com/1036289149',
        }}
        style={{
          backgroundColor: '#575759',
          color: '#FFF',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#575759' }} className={'row view-portfolio-v6-section portfolio-item-section--type-v6-3'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:v6.section-3.image'))} alt={'v6'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#616063' }} className={'view-portfolio-v6-section portfolio-item-section--type-v6-4'}>
          <RevealWhenInView>
              <div className={"u-ratio"}>
                  <VimeoPlayer  size="fill"  url={'https://vimeo.com/997658771/698f97d0a0'} play muted controls={false} loop={true} />
              </div>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

        {/* 5ий экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#575759' }} className={'row view-portfolio-v6-section portfolio-item-section--type-v6-5'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:v6.section-5.image'))} alt={'v6'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 6ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#575759', color: '#fff' }} className={'view-portfolio-v6-section portfolio-item-section--type-v6-6 container'}>
            <div className="description">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:v6.section-6.description.0")}</p>
                        <p className={'list'}>{t("portfolioItem:v6.section-6.description.1")}</p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:v6.section-6.description.2")}</p></RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:v6.section-6.image'))} alt={'v6'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection dynamicBackgroundColor={true} style={{ backgroundColor: '#fff' }}
                                className={'view-portfolio-v6-section portfolio-item-section--type-v6-7 row'}>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:v6.section-7.image.0'))} alt={'v6'}/>
              </RevealWhenInView>
          </div>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:v6.section-7.image.1'))} alt={'v6'}/>
              </RevealWhenInView>
          </div>
          <div className={'col-11'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:v6.section-7.image.2'))} alt={'v6'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 8ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#fff' }} className={'view-portfolio-v6-section portfolio-item-section--type-v6-8 row'}>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:v6.section-8.image.0'))} alt={'v6'}/>
              </RevealWhenInView>
          </div>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:v6.section-8.image.1'))} alt={'v6'}/>
              </RevealWhenInView>
          </div>
          <div className={'col-11'}>
              <RevealWhenInView>
                  <div className={"u-ratio"}>
                      <VimeoPlayer  size="fill"  url={'https://vimeo.com/997657732/0287552a59'} play muted controls={false} loop={true} />
                  </div>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 9ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#F3F4F4' }}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:v6.section-9.image'))} alt={'v6'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>


    </>
  );
};

export default ViewPortfolioV6;
