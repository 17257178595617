import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioGosaptekaPharmacyListSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  image: ViewPortfolioItemImageData;
  caption: [string, string];
}

const ViewPortfolioGosaptekaPharmacyListSection = ({
  image,
  caption,
  ...sectionProps
}: ViewPortfolioGosaptekaPharmacyListSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section--layout-gosapteka-pharmacy-list"
    >
      <div className="view-portfolio-item-section__container container">
        <figure className="view-portfolio-item-section__inner row">
          <div className="view-portfolio-item-section__col-caption col-lg-4">
            <figcaption className="view-portfolio-item-section__caption u-max-w-480">
              <SlideFadeOutWhenInView>
                {({ setTarget, setTriggerEl }) => (
                  <div ref={setTriggerEl} className="row">
                    <p ref={setTarget()} className="col-11">
                      {caption[0]}
                    </p>
                    <p ref={setTarget()} className="col-11 offset-md-3 col-md">
                      {caption[1]}
                    </p>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </figcaption>
          </div>

          <div className="view-portfolio-item-section__col-image offset-2 col-7 offset-lg-1 col-lg">
            <ViewPortfolioItemImage {...image} />
          </div>
        </figure>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioGosaptekaPharmacyListSection;
