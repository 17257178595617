import { Resource } from "i18next";
import ru from "./ru";
import en from "./en";
import { LangCodeType } from "i18n/types";

const resources: { [key in LangCodeType]: Resource } = {
  en,
  ru,
};

export default resources;
