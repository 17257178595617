import React, { forwardRef, memo, Ref } from "react";
import classNames from "classnames";

interface CopyrightProps {
  className?: string;
}

/**
 * Копирайт
 */
const Copyright = (
  { className }: CopyrightProps,
  ref: Ref<HTMLParagraphElement>
) => {
  return (
    <p
      ref={ref}
      className={classNames(
        "copyright sublabel sublabel--secondary",
        className
      )}
    >
      sagirov.com &copy;&nbsp;<span className="side-brackets">{new Date().getFullYear()}</span>
    </p>
  );
};
export default memo(
  forwardRef<HTMLParagraphElement, CopyrightProps>(Copyright)
);
