import { usePortfolioItemAssets } from "hooks/useAssets";

import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";

import { useTranslation } from "react-i18next";
import React from "react";

export interface ViewPortfolioAAAProps {}

const ViewPortfolioAAA = (props: ViewPortfolioAAAProps) => {
  const { data } = useViewPortfolioItemContext();

  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);

  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-aaa"
        caption={[
          t("portfolioItem:aaa.about.0"),
          t("portfolioItem:aaa.about.1"),
          t("portfolioItem:aaa.about.2"),
        ]}
        image={{
          isLogo: false,
          src: buildPageImageSrc(
            t("portfolioItem:aaa.section-2.image"),
            "webp"
          ),
        }}
        style={{
          backgroundColor: "#A10707",
          color: "#FFF",
          backgroundPosition: "top left",
          backgroundSize: "cover",
        }}
        name={data.name}
        description={data.description}
      />
      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#A10707",
        }}
        className={
          " view-portfolio-aaa-section portfolio-item-section--type-aaa-3"
        }
      >
        <div className={"img-wrap"}>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(
                t("portfolioItem:aaa.section-3.image.1"),
                "gif"
              )}
              alt={"aaa"}
              onError={(e) => {
                // При ошибке загрузки пробуем прямой путь
                const target = e.target as HTMLImageElement;
                if (target.src.includes("?v=")) {
                  const base = target.src.split("?v=")[0];
                  target.src = base;
                }
              }}
            />
          </RevealWhenInView>
        </div>
        <div className={"img-wrap"}>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(
                t("portfolioItem:aaa.section-3.image.0"),
                "webp"
              )}
              alt={"aaa"}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-aaa-section portfolio-item-section--type-aaa-4"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:aaa.section-4.image"),
              "webp"
            )}
            alt={"aaa"}
            onError={(e) => {
              // При ошибке загрузки пробуем прямой путь
              const target = e.target as HTMLImageElement;
              if (target.src.includes("?v=")) {
                const base = target.src.split("?v=")[0];
                target.src = base;
              }
            }}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 5ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-aaa-section portfolio-item-section--type-aaa-5"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:aaa.section-5.image"),
              "webp"
            )}
            alt={"aaa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-aaa-section portfolio-item-section--type-aaa-6"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:aaa.section-6.image"),
              "webp"
            )}
            alt={"aaa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-aaa-section portfolio-item-section--type-aaa-7"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:aaa.section-7.image"),
              "webp"
            )}
            alt={"aaa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-aaa-section portfolio-item-section--type-aaa-8"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:aaa.section-8.image"),
              "webp"
            )}
            alt={"aaa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioAAA;
