/**
 * Добавляет элемент item после каждого элемента массива
 *
 * @param arr - Массив которому нужно после каждого элемента добавить элемент item
 * @param item - Элемент который нужно добавить после каждого элемента массива
 *
 * @returns Новый массив
 */
export const addAfterEach = <T>(arr: T[], item: T): T[] => {
  const result: T[] = [];

  for (const arrItem of arr) {
    result.push(arrItem, item);
  }

  return result;
};

/**
 * Повторяет определенный элемент в массиве определенное кол-во раз
 *
 * @param arr - Массив элементы которого нужно повторить
 * @param repeat - Объект состоящий из ключей, которые представляет собой индексы элементов,
 * которые нужно повторить, а значение кол-во повторений
 */
export const repeatItem = <T>(arr: T[], repeat: { [key: number]: number }) => {
  let result = arr;
  let repeatedCount = 0;

  for (const index in repeat) {
    const count = repeat[index];
    const repeatedItems: T[] = Array(count).fill(arr[index]);
    const insertIndex = +index + repeatedCount;

    repeatedCount += count;

    result = [
      ...result.slice(0, insertIndex),
      ...repeatedItems,
      ...result.slice(insertIndex, result.length),
    ];
  }

  return result;
};