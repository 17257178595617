import ViewPortfolioBanners from "components/views/ViewPortfolioItem/ViewPortfolioBanners/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "banners",
  component: ViewPortfolioBanners,
  title: "banners",
  name: "Баннеры",
  altName: "баннеры для различных \n компаний",
  year: "2023-2024",
  awardsList: [],

  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "",
      href: "",
    },
  ],

  preview: {
    label: "фирменный стиль",
    backgroundColor: "#00387A",
    media: {
      type: "video",
      src: "https://vimeo.com/1005381964/4d94c87543",
    },
  },

  poolOfWorks: ["Research", "Design"],

  type: "branding",
  subtype: "branding",

  description: [
    "этот проект был создан в рамках обучения стажеров. Ниже представлены баннеры в следующих категориях fashion, косметика, фармакология, развления, еда, напитки, e-commerce, строительство",
    "Проект создавался для некоммерческого использования. Все права принадлежат их авторам.",
  ],
  shortDescription: "баннеры для различных компаний",

  seo: {
    title: "Создание дизайна баннеров для различных отраслей",
    description:
      "Реализовали дизайн баннеров для широкого спектра отраслей: мода, косметика, медицина, маркетплейсы, недвижимость и другие. Креативная дизайн поддержка брендов для эффективной визуальной коммуникации и продвижения товаров",
    keywords: [
      "дизайн баннеров",
      "баннеры для моды",
      "баннеры для косметики",
      "баннеры для медицины",
      "баннеры для маркетплейсов",
      "баннеры для Wildberries",
      "баннеры для недвижимости",
      "дизайн для e-commerce",
      "визуальная реклама",
    ],
    openGraph: {
      type: "article",
      title: "Создание дизайна баннеров для различных отраслей",
      description:
        "Реализовали дизайн баннеров для широкого спектра отраслей: мода, косметика, медицина, маркетплейсы, недвижимость и другие. Креативная дизайн поддержка брендов для эффективной визуальной коммуникации и продвижения товаров",
    },
  },
};

export default porfolioItem;
