import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import Copyright from "components/layouts/Copyright/Copyright";
import List from "components/common/List";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Print from "components/common/Print";

import { contacts, socials, socialsEn } from "data/contacts";

import { Link } from "react-router-dom";
import { privacyPolicy } from "routes";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

interface FooterProps {
  backgroundColor?: string;
  renderTitle?: () => JSX.Element;
  children?: ReactNode;
  className?: string;
}

/**
 * Футер
 */
const Footer = ({
  backgroundColor,
  renderTitle,
  children,
  className,
}: FooterProps) => {
  const socialsElRef = useRef<HTMLDivElement>(null);
  const contactsElRef = useRef<HTMLDivElement>(null);
  const [arSocials, setSocials] = useState(socials);
  const { t } = useTranslation(["translation"]);

  const nextLangCode = useMemo(() => {
    return i18n.language === "en" ? "/en" : "";
  }, [i18n.language]);

  useEffect(() => {
    if (i18n.language === "en") {
      setSocials(socialsEn);
    } else {
      setSocials(socials);
    }
  }, [i18n.language]);

  return (
    <DynamicBackgroundTrigger>
      <footer
        className={classNames(
          "footer",
          className,
          backgroundColor && `background--${backgroundColor}`
        )}
      >
        <div className="footer__container container">
          {children ? (
            children
          ) : (
            <div className="footer__main row">
              <div className="footer__header col-11 col-md-5 col-xl-4">
                <RevealWhenInView delay={0.3}>
                  <h2 className="footer__heading heading u-euclid-flex-indent-fix-block">
                    {renderTitle ? (
                      renderTitle()
                    ) : (
                      <>
                        <span className="text--muted">
                          <Print data="layout:footer.title.first-part" />
                        </span>{" "}
                        <Print data="layout:footer.title.second-part" />
                      </>
                    )}
                  </h2>
                </RevealWhenInView>
              </div>

              <div className="footer__contacts-and-socials row col-11 offset-md-1 col-md-5 col-xl-6">
                <div
                  ref={contactsElRef}
                  className="footer__contacts col-11 col-xl-7"
                >
                  <List
                    itemClassName="footer__contacts-group row"
                    items={contacts}
                  >
                    {({ name, label, href }) => (
                      <>
                        <div className="col-4 col-md-3">
                          <RevealWhenInView>
                            <p className="label side-brackets">
                              <Print data={t(name!)} />
                            </p>
                          </RevealWhenInView>
                        </div>
                        <div className="col-7 col-md">
                          <RevealWhenInView>
                            {href ? (
                              <a
                                href={href}
                                target="_blank"
                                rel="noreferrer"
                                className="link label"
                              >
                                <span className="link--normal">
                                  {name === "Address" ? (
                                    <Print data={t(label)} />
                                  ) : (
                                    <Print data={label} />
                                  )}
                                </span>
                              </a>
                            ) : (
                              <p className="label text--pre-line">
                                {name === "Address" ? (
                                  <Print data={t(label)} />
                                ) : (
                                  <Print data={label} />
                                )}
                              </p>
                            )}
                          </RevealWhenInView>
                        </div>
                      </>
                    )}
                  </List>
                </div>

                <div
                  ref={socialsElRef}
                  className="footer__socials offset-4 col-7 offset-md-3 col-md offset-xl-0 col-xl-4"
                >
                  <List itemClassName="footer__socials-item" items={arSocials}>
                    {({ label, href }, i) => (
                      <RevealWhenInView>
                        <a
                          target="_blank"
                          rel="noreferrer nofollow"
                          href={href}
                          className="link link--normal label"
                        >
                          <Print data={label} />
                        </a>
                      </RevealWhenInView>
                    )}
                  </List>
                </div>
              </div>
            </div>
          )}

          <div className="footer__copyright-and-policy-link sublabel sublabel--secondary text--fs-xl-small row">
            <div className="footer__col-with-copyright col-11 col-md-6 col-xl-5">
              <RevealWhenInView>
                <Copyright className="footer__copyright" />
              </RevealWhenInView>
            </div>
            <div className="footer__col-with-policy-link col-11 col-md">
              <RevealWhenInView>
                <Link
                  to={
                    nextLangCode +
                    privacyPolicy.path.replace("/:locale( |en)?", "")
                  }
                  className="footer__policy-link link link--normal"
                >
                  <Print data="Privacy policy" />
                </Link>
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </footer>
    </DynamicBackgroundTrigger>
  );
};

export default Footer;
