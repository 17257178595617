import ViewServicesLeadSection from "./ViewServicesLeadSection";
import ViewServicesServiceSection from "./ViewServicesServiceSection";
import PortfolioLinkSection from "components/layouts/PortfolioLinkSection";

const serviceSitesAndMobile = {
  name: "view:services.section.sites-and-mobile.title",
  description: "view:services.section.sites-and-mobile.description",
  services: {
    types: ["Web-applications", "Landings", "Online-shops", "Ios", "Android"],
    items: [
      "Audit and analytics",
      "Planning",
      "Design",
      "Development and launch of a new product",
      "Redesign of an existing product",
      "Filling with content",
      "Maintenance",
    ],
  },
  integrations: [
    "bitrix 24",
    "amocrm",
    "1c-фитнес",
    "roistat",
    "comagic",
    "mango",
  ],
  technologies: ["reactjs", "redux", "nodejs", "swift", "php"],
};

const sectionBranding = {
  name: "view:services.section.branding.title",
  description: "view:services.section.branding.description",
  services: {
    types: ["Logotype", "Brand style", "Brand book", "Packing"],
    items: [
      "Audit and analytics",
      "Corporate identity development",
      "Infographic design",
      "Redesign of the existing corporate identity",
      "Content for a site or application",
      "Promotional materials",
    ],
  },
};

interface ViewServicesCopyProps {}

const ViewServicesCopy = (props: ViewServicesCopyProps) => {
  return (
    <div className="view view--services">
      <ViewServicesLeadSection />
      <ViewServicesServiceSection
        {...serviceSitesAndMobile}
        className="background--gray"
      />
      <ViewServicesServiceSection {...sectionBranding} />
      <PortfolioLinkSection className="view-services-portfolio-link-section" />
    </div>
  );
};

export default ViewServicesCopy;
