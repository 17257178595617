import { debounce } from "debounce";
import { useEffect, useState } from "react";

export type BreakpointDataType<T> =
  | {
    media: string;
    data: T;
  }[]
  | { [key: number]: T };

const getBreakpointData = <T>(breakpointDataItems: BreakpointDataType<T>, defaultDataItems: T) => {
  let result: T = defaultDataItems;

  if (Array.isArray(breakpointDataItems)) {
    for (const { media, data } of breakpointDataItems) {
      if (window.matchMedia(media).matches) {
        result = data;
      }
    }
  } else {
    for (const breakpoint in breakpointDataItems) {
      if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
        result = breakpointDataItems[breakpoint];
      }
    }
  }

  return result;
};

/**
 * Хук позволяющий выбрать данные в зависимости от активного медиа выражения
 *
 * @param breakpointDataItems Медиа выражения и данные к ним
 * @param defaultData Дефолтный данные которые будут возвращаться в случае если ни одно из медиа выражений не подошло
 * @returns данные для активного медиа выражения
 */
const useBreakpointData = <T>(
  breakpointDataItems: BreakpointDataType<T>,
  defaultData: T
) => {
  const [breakpointData, setBreakpointData] = useState<T>(
    getBreakpointData(breakpointDataItems, defaultData));

  useEffect(() => {
    const updateBreakpointData = () => {
      if (!breakpointDataItems) {
        return;
      }

      const data = getBreakpointData(breakpointDataItems, defaultData);

      setBreakpointData(data);
    };

    updateBreakpointData();

    const handleWindowResize = debounce(updateBreakpointData, 200);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [breakpointDataItems, defaultData]);

  return breakpointData;
};

export default useBreakpointData;
