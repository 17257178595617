import React, { forwardRef, memo, Ref } from "react";
import classNames from "classnames";
import { ReactComponent as CaretRight } from "assets/images/icons/caret-right.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as ArrowDiagonal } from "assets/images/icons/arrow-diagonal.svg";
import { ReactComponent as ArrowDiagonalLeft } from "assets/images/icons/arrow-csd.svg";
import { ReactComponent as XIcon } from "assets/images/icons/x.svg";

const icons = {
  "caret-right": CaretRight,
  "circle-caret-right": CaretRight,
  "arrow-down": ArrowDown,
  "arrow-right": ArrowRight,
  "arrow-diagonal":ArrowDiagonal,
  "arrow-csd":ArrowDiagonalLeft,
  x: XIcon,
};

interface IconProps {
  /**
   * название иконки
   */
  name: keyof typeof icons;
  className?: string;
}

type IconRefElement = HTMLElement;

/**
 * Создает иконку
 */
const Icon = ({ name, className }: IconProps, ref: Ref<IconRefElement>) => {
  const SpecificIcon = icons[name];

  return (
    <i ref={ref} className={classNames("icon", `icon--${name}`, className)}>
      <SpecificIcon className="icon__svg" />
    </i>
  );
};

export default memo(forwardRef<IconRefElement, IconProps>(Icon));
