import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';

export interface ViewPortfolioSk10Props {}

const ViewPortfolioSk10 = (props: ViewPortfolioSk10Props) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={false}
        className="view-portfolio-item-about-section--type-sk10"
        image={{
          isLogo: true,
          src: buildPageImageSrc('about'),
        }}
        style={{
          backgroundColor: '#131313',
          color: '#FFF',
          backgroundImage: `url(${buildPageImageSrc('about-background')})`,
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />
      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t('portfolioItem:sk10.section-3.image'))}
              alt={'sk10'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t('portfolioItem:sk10.section-4.image'))}
              alt={'sk10'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 5ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t('portfolioItem:sk10.section-5.image'))}
              alt={'sk10'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img src={buildPageImageSrc('screen-6')} alt={'sk10'} />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t('portfolioItem:sk10.section-7.image'))}
              alt={'sk10'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 8ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img src={buildPageImageSrc('screen-8')} alt={'sk10'} />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 9ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t('portfolioItem:sk10.section-9.image'))}
              alt={'sk10'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 10ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img src={buildPageImageSrc('screen-10')} alt={'sk10'} />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 11ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#131313' }}>
        <div>
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t('portfolioItem:sk10.section-11.image'))}
              alt={'sk10'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioSk10;
