import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import React from "react";
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";

export interface ViewPortfolioCltProps {}

const ViewPortfolioClt = (props: ViewPortfolioCltProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-clt"
        caption={[t("portfolioItem:clt.about.0"), t("portfolioItem:clt.about.1"), t("portfolioItem:clt.about.2"), ]}
        video={{
            src: 'https://vimeo.com/1008375149/e6165f6b18',
        }}
        style={{
          backgroundColor: '#212123',
          color: '#FFF',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection dynamicBackgroundColor={true} style={{ backgroundColor: '#212123' }}
                                className={'container portfolio-item-section--type-clt-3'}>
          <div className={'col-md-9 offset-md-1'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:clt.section-3.image'))} alt={'clt'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#f1ede9' }} className={'portfolio-item-section--type-clt-4'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:clt.section-4.image'))} alt={'clt'}/>

              <div className={"u-ratio"}>
                  <VimeoPlayer  size="fill"  url={'https://vimeo.com/1008381193/7f06f46b72'} play muted controls={false} loop={true} />
              </div>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 5ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true} style={{ backgroundColor: '#212123'}}
            className={' container portfolio-item-section--type-clt-5'}>
            <div className={'col-md-9 offset-md-1'}>
                <div className={'img-wrap'}>
                    <RevealWhenInView>
                        <img src={buildPageImageSrc(t('portfolioItem:clt.section-5.image.0'))} alt={'clt'}/>
                    </RevealWhenInView>
                </div>
                <div className={'col-md-9 offset-md-1'}>
                    <RevealWhenInView>
                        <img src={buildPageImageSrc(t('portfolioItem:clt.section-5.image.1'))} alt={'clt'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

      {/* 6ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#212123' }} className={'portfolio-item-section--type-clt-6'}>
            <RevealWhenInView>
                <div className={"u-ratio"}>
                    <VimeoPlayer  size="fill"  url={'https://vimeo.com/1008383545/10ee870e5f'} play muted controls={false} loop={true} />
                </div>

                <img src={buildPageImageSrc(t('portfolioItem:clt.section-6.image'))} alt={'clt'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#212123' }} className={'container portfolio-item-section--type-clt-7'}>
            <div className={'col-md-9 offset-md-1'}>
                <div className={'img-wrap'}>
                    <RevealWhenInView>
                        <img src={buildPageImageSrc(t('portfolioItem:clt.section-7.image.0'))} alt={'clt'}/>
                    </RevealWhenInView>
                </div>
                <div className={'col-9 offset-1'}>
                    <RevealWhenInView>
                        <img src={buildPageImageSrc(t('portfolioItem:clt.section-7.image.1'))} alt={'clt'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

      {/* 8ый экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#212123' }} >
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:clt.section-8.image'))} alt={'clt'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 9ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#212123' }} className={'container portfolio-item-section--type-clt-9'}>
            <div className={'col-md-9 offset-md-1 img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:clt.section-9.image.0'))} alt={'clt'}/>
                </RevealWhenInView>
            </div>
            <div className={'col-md-9 offset-md-1 img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:clt.section-9.image.1'))} alt={'clt'}/>
                </RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:clt.section-9.image.2'))} alt={'clt'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 10ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#212123' }} className={'portfolio-item-section--type-clt-10'}>
            <RevealWhenInView>
                <div className={"u-ratio"}>
                    <VimeoPlayer  size="fill"  url={'https://vimeo.com/1008386984/f9f0e9b37a'} play muted controls={false} loop={true} />
                </div>

                <img src={buildPageImageSrc(t('portfolioItem:clt.section-10.image'))} alt={'clt'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 11ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#212123' }} className={'container portfolio-item-section--type-clt-11'}>
            <div className={'col-md-3 offset-md-4 col-5 offset-3 img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:clt.section-11.image.0'))} alt={'clt'}/>
                </RevealWhenInView>
            </div>
            <div className={'col-md-9 offset-md-1'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:clt.section-11.image.1'))} alt={'clt'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

    </>
  );
};

export default ViewPortfolioClt;
