import classNames from "classnames";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { useTranslation } from "react-i18next";

interface ViewServicesSectionProps {
  name: string;
  description: string;
  services: {
    types: string[];
    items: string[];
  };
  technologies?: string[];
  integrations?: string[];
  backgroundColor?: string;
  className?: string;
}

const ViewServicesSection = ({
  name,
  description,
  className,
  services,
  integrations,
  technologies,
}: ViewServicesSectionProps) => {
  const { t } = useTranslation();

  return (
    <section className={classNames("view-services-section section", className)}>
      <div className="view-services-section__container container">
        <header className="view-services-section__header row">
          <RevealWhenInView className="view-services-section__col-with-heading col-xxl-5">
            <h2 className="view-services-section__heading heading">
              {t(name)}
            </h2>
          </RevealWhenInView>
          <RevealWhenInView className="view-services-section__col-with-description offset-md-4 col-md offset-xxl-0">
            <p>
              <span className="offset-md-2 offset-xxl-3" />
              {t(description)}
            </p>
          </RevealWhenInView>
        </header>
        <div className="view-services-section__body row text--lowercase">
          <div className="view-services-section__col-with-services-types col-11 row order-md-0 col-md-4 col-xxl-5">
            <RevealWhenInView className="view-services-section__col-with-services-types-label view-services-section__col-with-label col-11 col-sm-4">
              <p className="label side-brackets">{t("We do")}</p>
            </RevealWhenInView>
            <RevealWhenInView>
              <List
                items={services.types.map((serviceType) => t(serviceType))}
                className="view-services-section__services-types-list sublabel sublabel--secondary col-11 col-sm"
              />
            </RevealWhenInView>
          </div>

          <div className="view-services-section__col-with-services row col-11 order-md-1 col-md-7 col-xxl-6">
            <RevealWhenInView className="view-services-section__col-with-label col-11 col-xxl-3">
              <p className="label side-brackets">{t("We do")}</p>
            </RevealWhenInView>
            <RevealWhenInView>
              <List
                items={services.items.map((servicesItem) => t(servicesItem))}
                marked
                gap="lg"
                className="view-services-section__services-list col-11 col-xxl"
              />
            </RevealWhenInView>
          </div>

          {integrations && (
            <div className="view-services-section__col-with-integrations row col-11 col-sm-7 offset-sm-4 offset-md-0 order-md-3 col-md-7 col-xxl-6">
              <RevealWhenInView className="view-services-section__col-with-label col-11 col-xxl-3">
                <p className="label side-brackets">{t("Integrations")}</p>
              </RevealWhenInView>
              <RevealWhenInView>
                <List
                  items={integrations.map((integration) => t(integration))}
                  marked
                  gap="lg"
                  className="view-services-section__integrations-list col-11 col-xxl"
                />
              </RevealWhenInView>
            </div>
          )}

          {technologies && (
            <div className="view-services-section__col-with-technologies row col-11 col-sm-7 offset-sm-4 offset-md-0 order-md-2 col-md-4 col-xxl-5">
              <div className="view-services-section__col-with-technologies-label col-11">
                <RevealWhenInView>
                  <p className="sublabel sublabel--secondary">
                    {t("Technology stack")}
                  </p>
                </RevealWhenInView>
              </div>
              <RevealWhenInView>
                <List
                  items={technologies}
                  marked
                  className="view-services-section__technologies-list col-11 sublabel sublabel--secondary"
                />
              </RevealWhenInView>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ViewServicesSection;
