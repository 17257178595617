import { useEffect, useRef } from "react";

import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import gsap from "gsap";
import {useTranslation} from "react-i18next";

interface ViewPortfolioNovaGroupProjectCardSectionProps {
  images: ViewPortfolioItemImageData[];
}

const ViewPortfolioNovaGroupProjectCardSection = ({
  images,
}: ViewPortfolioNovaGroupProjectCardSectionProps) => {
  const captionFirstGroupElRef = useRef<HTMLDivElement>(null);
  const captionSecondGroupElRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const captionFirstGroupEl = captionFirstGroupElRef.current!;
    const captionSecondGroupEl = captionSecondGroupElRef.current!;

    const getCaptionTimeline = (captionGroupEl: HTMLElement) => {
      const captionFirstGroupItemElms = captionGroupEl.querySelectorAll("p");

      const captionFirstGroupTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: captionGroupEl,
          toggleActions: "play reset play reset",
        },
      });

      captionFirstGroupTimeline.from(captionGroupEl, {
        y: 40,
        ease: "ease-out",
        duration: 0.6 + 0.2 * (captionFirstGroupItemElms.length - 1),
      });

      captionFirstGroupTimeline.from(
        captionFirstGroupItemElms,
        {
          y: 20,
          opacity: 0,
          ease: "ease-out",
          duration: 0.6,
          stagger: 0.2,
        },
        "<"
      );

      return captionFirstGroupTimeline;
    };

    const captionFirstGroupTimeline = getCaptionTimeline(captionFirstGroupEl);
    const captionSecondGroupTimeline = getCaptionTimeline(captionSecondGroupEl);

    return () => {
      captionFirstGroupTimeline.kill();
      captionSecondGroupTimeline.kill();
    };
  }, []);

  return (
    <ViewPortfolioItemSection
      size="lg"
      style={{ backgroundColor: "#3D3A37" }}
      className="view-portfolio-nova-group-gallery-section__second-group background--dark-gray view-portfolio-nova-group-gallery-section__inner-pages-group"
    >
      <div className="view-portfolio-nova-group-gallery-section__second-group-container container">
        <div className="row gaps--medium-xxl">
          <div className="view-portfolio-nova-group-gallery-section__second-group-col-with-first-image col-11 col-xxl-4 order-xxl-1 col-xxl-7">
            <ViewPortfolioItemImage
              imageClassName="view-portfolio-nova-group-gallery-section__second-group-image"
              {...images[0]}
            />
            <div className="view-portfolio-nova-group-gallery-section__second-group-col-with-info view-portfolio-nova-group-gallery-section__second-group-col-with-info--first text--light">
              {/* <RevealWhenInView> */}
              <div
                ref={captionFirstGroupElRef}
                className="view-portfolio-nova-group-gallery-section__second-group-info"
              >
                <p className="view-portfolio-item__text">
                  {t('portfolioItem:nova-group.card-section.caption.0')}
                </p>
                <p className="offset-md-2">
                  {t('portfolioItem:nova-group.card-section.caption.1')}
                </p>
              </div>
              {/* </RevealWhenInView> */}
            </div>
          </div>
          <div className="view-portfolio-nova-group-gallery-section__second-group-col-with-second-image col-7 offset-2 offset-xxl-0 order-xxl-0 col-xxl-4">
            <ViewPortfolioItemImage
              className="view-portfolio-nova-group-gallery-section__second-group-image view-portfolio-nova-group-gallery-section__second-group-second-image"
              {...images[1]}
            />
          </div>
          <div className="view-portfolio-nova-group-gallery-section__second-group-col-with-info view-portfolio-nova-group-gallery-section__second-group-col-with-info--second text--light col-11 offset-md-auto">
            {/* <RevealWhenInView> */}
            <div
              ref={captionSecondGroupElRef}
              className="view-portfolio-nova-group-gallery-section__second-group-info offset-md-auto"
            >
              <p>{t('portfolioItem:nova-group.card-section.caption.0')}</p>
              <p className="offset-md-2">
                {t('portfolioItem:nova-group.card-section.caption.1')}
              </p>
            </div>
            {/* </RevealWhenInView> */}
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioNovaGroupProjectCardSection;
