import List from "components/common/List";
import { memo, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";

import PortfolioItem from "components/layouts/PortfolioItem";

import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

import portfolio from "data/portfolio";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import Print from "components/common/Print";
import Section from "components/layouts/Section";
import i18n from "i18next";
import { useLocation } from "react-router-dom";
import { LangCodeType } from "i18n/types";
import { PortfolioItemData } from "data/portfolio/types";

interface ViewHomePortfolioListSectionProps {}

const ViewHomePortfolioListSection = memo(
  (props: ViewHomePortfolioListSectionProps) => {
    const language = i18n.language as LangCodeType;

    let homePortfolio = useMemo(() => {
      let portfolioItems = portfolio[language];
      return portfolioItems.slice(0, 5);
    }, [i18n.language]);

    const location = useLocation();
    useEffect(() => {
      homePortfolio = portfolio[language].slice(0, 5);
    }, [location]);

    const preloadVideo = (url: string): void => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "video";
      link.href = url;
      document.head.appendChild(link);
    };

    useEffect(() => {
      const portfolioItems = portfolio[language];
      portfolioItems.slice(0, 3).forEach((item: PortfolioItemData) => {
        if (item.preview?.media?.type === "video" && item.preview.media.src) {
          preloadVideo(item.preview.media.src);
        }
      });
    }, []);

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && videoRef.current) {
              videoRef.current.load();
            }
          });
        },
        { rootMargin: "50%" }
      );

      if (videoRef.current) {
        observer.observe(videoRef.current);
      }

      return () => observer.disconnect();
    }, []);

    return (
      <DynamicBackgroundTrigger>
        <Section className="view-home-portfolio-section background--gray">
          <div className="view-home-portfolio-section__container container">
            <header className="view-home-portfolio-section__header row">
              <div className="row col-11">
                <div className="view-home-portfolio-section__col-with-heading col-11 col-xl-7">
                  <RevealWhenInView>
                    <h2 className="view-home-portfolio-section__heading heading">
                      <Print data="view:home.portfolio.title" />
                    </h2>
                  </RevealWhenInView>
                </div>
                <div className="view-home-portfolio-section__col-with-text col-md-7 offset-md-4 offset-xl-0 col-xl-4">
                  <RevealWhenInView>
                    <p className="text--indent-md-quarter u-max-w-480">
                      <Print data="view:home.portfolio.description" />
                    </p>
                  </RevealWhenInView>
                </div>
              </div>
            </header>
            <List
              className="view-home-portfolio-section__portfolio-items"
              itemClassName="view-home-portfolio-section__portfolio-item"
              items={homePortfolio}
            >
              {(portfolioItem, i) => {
                const hasOffset = !!(i % 2);

                return (
                  <PortfolioItem
                    key={portfolioItem.id}
                    id={portfolioItem.id}
                    name={portfolioItem.name}
                    preview={portfolioItem.preview}
                    subtype={portfolioItem.subtype}
                    trigger={{ type: "scroll" }}
                    noPadding={portfolioItem.noPadding}
                    className={classNames("col-11 col-md-9 col-lg-7", {
                      "offset-md-2 offset-lg-4": hasOffset,
                    })}
                  />
                );
              }}
            </List>
            {/* </CursorProvider> */}
          </div>
        </Section>
      </DynamicBackgroundTrigger>
    );
  }
);

export default ViewHomePortfolioListSection;
