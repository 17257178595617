import React, {
  ElementType,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Ref } from "react";
import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "./helpers";

interface ContextValue {
  /**
   * Состояние скроллбара, если true, тогда скролл отключен, в противном случае вкл.
   */
  locked: boolean;
  /**
   * Ширина скроллбара. Если скрллбар отключен она будет равняться 0
   */
  scrollbarWidth: number;
  /**
   * Блокирует скролл
   */
  lockScroll: () => void;
  /**
   * Разблокирует скролл
   */
  unlockScroll: () => void;
  /**
   * Переключатель состояние скроллбара в зависимости от переданного аргумента
   */
  toggleLockScroll: (locked: boolean) => void;
}

const lockBodyScrollbar = () => {
  document.body.style.overflow = "hidden";
};

const unlockBodyScrollbar = () => {
  document.body.style.overflow = "";
};

/**
 * Возвращает ширину скроллбара body
 */
const getBodyScrollbarWidth = () => {
  return window.innerWidth - document.documentElement.clientWidth;
};

const Context = createSafeContext<ContextValue>();

export const useBodyScrollLocker = () => useSafeContext(Context);
export const BodyScrollLockerConsumer = createSafeConsumer(Context);

interface BodyScrollLockerOffsetProps extends React.ComponentPropsWithoutRef<"div"> {
  tag?: ElementType<any>
}

export const BodyScrollLockerOffset = forwardRef<HTMLElement, BodyScrollLockerOffsetProps>(({ tag: Tag = "div", style, ...otherProps }: BodyScrollLockerOffsetProps, ref: Ref<HTMLElement>) => {
  const { scrollbarWidth } = useBodyScrollLocker();

  return (
  //@ts-ignore
  <Tag {...otherProps} ref={ref} style={{
    ...style,
    paddingRight: scrollbarWidth
  }} />)
})

/**
 * Позволяет контролировать блокировать прокрутку
 */
export const BodyScrollLockerProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [locked, _setLocked] = useState<ContextValue["locked"]>(false);
  const [scrollbarWidth, _setScrollbarWidth] = useState<
    ContextValue["scrollbarWidth"]
  >(0);

  const lockedRef = useRef(locked);
  const scrollbarWidthRef = useRef(scrollbarWidth);

  const setLocked = useCallback((locked) => {
    _setLocked((lockedRef.current = locked));
  }, []);

  const setScrollbarWidth = useCallback((scrollbarWidth) => {
    _setScrollbarWidth((scrollbarWidthRef.current = scrollbarWidth));
  }, []);

  /**
   * Блокирует скролл
   */
  const lockScroll = useCallback(() => {
    if (lockedRef.current) return;

    setScrollbarWidth(getBodyScrollbarWidth());

    lockBodyScrollbar();

    setLocked(true);
  }, [setLocked, setScrollbarWidth]);

  /**
   * Разблокирует скролл
   */
  const unlockScroll = useCallback(() => {
    if (!lockedRef.current) return;

    setScrollbarWidth(getBodyScrollbarWidth());

    unlockBodyScrollbar();

    setLocked(false);
  }, [setLocked, setScrollbarWidth]);

  /**
   * Переключатель состояние скроллбара в зависимости от переданного аргумента
   */
  const toggleLockScroll = useCallback(
    (locked: boolean = lockedRef.current) => {
      if (locked) {
        lockScroll();
      } else {
        unlockScroll();
      }
    },
    [lockScroll, unlockScroll]
  );

  const value: ContextValue = useMemo(
    () => ({
      locked,
      scrollbarWidth,
      lockScroll,
      unlockScroll,
      toggleLockScroll,
    }),
    [locked, scrollbarWidth, lockScroll, unlockScroll, toggleLockScroll]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
