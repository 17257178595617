import { useCallback, useRef } from "react";

export const useElemRef = <T extends string>(
  initial: Record<T, (HTMLElement | null) | (HTMLElement | null)[]>
) => {
  const elemRef = useRef(initial);

  const setElemRef = useCallback((name: T, index?: number) => {
    return (elem: HTMLElement | null) => {
      if (name in elemRef.current) {
        if (typeof index === "number") {
          if (Array.isArray(elemRef.current[name])) {
            // elemRef.current[name] = [...(elemRef.current[name] as (HTMLElement | null)[]), elem];
            if (index === 0) {
              elemRef.current[name] = [elem];
            } else {
              (elemRef.current[name] as (HTMLElement | null)[])[index] = elem;
            }
          } else {
            elemRef.current[name] = [elem];
          }
        } else {
          elemRef.current[name] = elem;
        }
      } else {
        elemRef.current[name] = elem;
      }
    };
  }, []);

  return { elemRef, setElemRef };
};
