import { TFunction } from "i18next";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import he from "he"

interface PrintProps {
  /**
   * Текст который будет выведен
   */
  data: string | string[];
  /**
   * Если true тогда будет выведен перевод текста
   *
   * @default true
   */
  translate?: boolean | ((data: PrintProps["data"], t: TFunction) => string);
  /**
   * Если true, тогда html entities будут декодированы и выведены как текст
   */
  htmlEntities?: boolean;
  children?: (text: string) => JSX.Element;
}

/**
 * Выводит текст, который можно перевести или отобразить html entities
 */
const Print = ({
  data,
  translate = true,
  htmlEntities = true,
  children,
}: PrintProps) => {
  const { t } = useTranslation();

  const translated = useMemo(() => {
    if (translate) {
      if (typeof translate === "function") {
        return translate(data, t);
      }

      return t(data);
    }

    return Array.isArray(data) ? data.join(" ") : data;
  }, [t, translate, data]);

  const decoded = useMemo(() => {
    if (htmlEntities) {
      return he.decode(translated)
    }

    return translated
  }, [translated, htmlEntities]);


  return <>{children ? children(decoded) : decoded}</>;
};

export default memo(Print);
