import React from "react";
import VimeoPlayer from "components/common/VimeoPlayer";

export default function ViewTest() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <VimeoPlayer url={569469499} play={false} loop muted controls />
    </div>
  );
}
