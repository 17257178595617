import Home from "components/views/ViewHome";
import ViewServices from "components/views/ViewServices";
import Portfolio from "components/views/ViewPortfolio";
import Vacancy from "components/views/Vacancy";
import About from "components/views/ViewAbout";
import PortfolioItem from "components/views/ViewPortfolioItem";
import { RouteComponentProps } from "react-router-dom";
import Contacts from "components/views/ViewContacts";
import NotFound from "components/views/NotFound";
import ViewServicesCopy from "components/views/ViewServicesCopy";
import ViewPrivacyPolicy from "components/views/ViewPrivacyPolicy";
import { Seo, SeoLang } from "types/global";
import ViewTest from "components/views/ViewTest";
import ViewDeveloperSites from "components/views/ViewDeveloperSites";
import seo from "seo";
import ViewServicesItem from "components/views/ViewServicesItem/ViewServicesItem";

const baseRouteUrl = "/:locale( |en)?";

export interface RouteItem {
  id?: string;
  path: string;
  exact?: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  nav?: {
    label: string;
    order?: number;
  };
  footer?: boolean | { backgroundColor: string };
  seo?: Seo | SeoLang;
}

export const home: RouteItem = {
  id: "home",
  path: baseRouteUrl + "/",
  exact: true,
  component: Home,
  nav: {
    label: "Home",
    order: 0,
  },
  seo: seo.home,
};

export const portfolio: RouteItem = {
  id: "portfolio",
  path: baseRouteUrl + "/portfolio",
  exact: true,
  component: Portfolio,
  nav: {
    label: "Works",
    order: 1,
  },
  seo: seo.portfolio,
};

export const developerSites: RouteItem = {
  id: "developer-sites",
  path: baseRouteUrl + "/developer-sites",
  exact: true,
  component: ViewDeveloperSites,
  seo: seo.developerSites,
};

export const about: RouteItem = {
  id: "about",
  path: baseRouteUrl + "/about",
  exact: true,
  component: About,
  nav: {
    label: "About us",
    order: 4,
  },
  seo: seo.about,
};

export const contacts: RouteItem = {
  id: "contacts",
  path: baseRouteUrl + "/contacts",
  exact: true,
  component: Contacts,
  nav: {
    label: "Contacts",
    order: 7,
  },
  footer: false,
  seo: seo.contacts,
};

export const vacancies: RouteItem = {
  id: "vacancies",
  path: baseRouteUrl + "/vacancies",
  exact: true,
  component: Vacancy,
  nav: {
    label: "Vacancies",
    order: 5,
  },
  footer: {
    backgroundColor: "gray",
  },
  seo: seo.vacancies,
};

export const services: RouteItem = {
  id: "services",
  path: baseRouteUrl + "/services",
  exact: true,
  component: ViewServices,
  nav: {
    label: "Services",
    order: 2,
  },
  seo: seo.services,
};

export const portfolioItem: RouteItem = {
  id: "portfolio-item",
  path: `${portfolio.path}/:portfolioItemId`,
  exact: true,
  component: PortfolioItem,
};

export const servicesItem: RouteItem = {
  id: "services-item",
  path: `${services.path}/:servicesItemId`,
  exact: true,
  component: ViewServicesItem,
};

export const servicesCopy: RouteItem = {
  id: "services-copy",
  path: baseRouteUrl + "/services-copy",
  exact: true,
  component: ViewServicesCopy,
};

const defaultDescription =
  "Digital-продакшн: дизайн, разработка и поддержка сайтов и приложений";

export const notFound: RouteItem = {
  id: "404",
  path: "/404",
  exact: true,
  component: NotFound,
  seo: {
    title: "Not found",
    description: defaultDescription,
  },
};

export const privacyPolicy: RouteItem = {
  id: "privacy-policy",
  path: "/:locale( |en)?/privacy-policy",
  exact: true,
  component: ViewPrivacyPolicy,
  seo: {
    title: "Privacy policy",
    description: defaultDescription,
  },
};

export const test: RouteItem = {
  id: "test",
  path: "/test",
  exact: true,
  component: ViewTest,
};

export const routes: RouteItem[] = [
  home,
  about,
  vacancies,
  contacts,
  services,
  developerSites,
  servicesItem,
  servicesCopy,
  portfolio,
  portfolioItem,
  privacyPolicy,
  notFound,
  test,
  {
    id: "not-found-wildcard",
    path: "*",
    component: NotFound,
    exact: true,
    nav: undefined,
    seo: {
      title: "Not found",
      description: defaultDescription,
    },
  },
];

export interface NavRoute extends RouteItem {
  nav: NonNullable<RouteItem["nav"]>;
}

export const navRoutes = routes
  .filter((route): route is NavRoute => !!route.nav)
  .sort((a, b) => (a.nav?.order || 0) - (b.nav?.order || 0));
