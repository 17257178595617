import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import Print from "components/common/Print";
import { useEffect, useState } from "react";

import { MorphSvgWord } from "components/common/MorphSvg";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import Section from "components/layouts/Section";
import {useTranslation} from "react-i18next";

interface VacancyLeadSectionProps { }

const lookingForItems = [
  "UX | UI designer",
  "Full-stack dev",
  "Front-end dev",
  "Content manager",
];

const VacancyLeadSection = (props: VacancyLeadSectionProps) => {
  const [morph, setMorph] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorph(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  const { t } = useTranslation();
  return (
    <DynamicBackgroundTrigger>
      <Section viewportHeight isLead className="vacancy-lead-section">
        <div className="vacancy-lead-section__container lead-section__container container">
          <div className="vacancy-lead-section__inner">
            <div className="vacancy-lead-section__header row">
              <div className="vacancy-lead-section__col-with-title col-11 col-xl-auto">
                <RevealWhenInView>
                  <div className="vacancy-lead-section__title">
                    <MorphSvgWord className="svg-title" word={t("view:vacancies.lead.title")} morph={morph} />
                  </div>
                </RevealWhenInView>
              </div>

              <div className="vacancy-lead-section__header-col-with-email-link col-11 offset-md-5 col-md offset-xl-0 col-xl">
                <RevealWhenInView>
                  <a
                    href="mailto:hr@sagirov.com"
                    className="vacancy-lead-section__header-email-link label link link--normal"
                  >
                    hr@sagirov.com
                  </a>
                </RevealWhenInView>
              </div>
            </div>

            <div className="vacancy-lead-section__body row">
              <div className="vacancy-lead-section__looking-for row col-11 order-xl-2 offset-xl-1 col-xl-3 ">
                <RevealWhenInView className="col-3 offset-md-3 col-md-2 offset-xl-0 col-xl-3">
                  <p className="label side-brackets">
                    <Print data="view:vacancies.lead.list-label" />
                  </p>
                </RevealWhenInView>
                <RevealWhenInView className="col">
                  <List gap="xxs" items={lookingForItems}>
                    {(lookingForItem) => (
                      <p className="label">
                        <Print data={lookingForItem} />
                      </p>
                    )}
                  </List>
                </RevealWhenInView>
              </div>
              <div className="vacancy-lead-section__body-col-with-email-link col">
                <RevealWhenInView>
                  <a
                    href="mailto:hr@sagirov.com"
                    className="vacancy-lead-section__body-email-link label link link--normal"
                  >
                    hr@sagirov.com
                  </a>
                </RevealWhenInView>
              </div>
              <div className="vacancy-lead-section__col-with-description col order-xl-1 col-xl-auto">
                <RevealWhenInView>
                  <p className="vacancy-lead-section__description text--indent-quarter u-max-w-480">
                    <Print data="view:vacancies.lead.description" />
                  </p>
                </RevealWhenInView>
              </div>
              <div className="vacancy-lead-section__col-with-arrow col-auto order-last order-xl-3 col-xl">
                <RevealWhenInView
                  from={{ opacity: 0, y: -25 }}
                  to={{ opacity: 1, y: 0 }}
                >
                  <ScrollDownButton scrollTo=".vacancy-about-section" />
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default VacancyLeadSection;
