import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemRelatedSection from '../ViewPortfolioItemRelatedSection';
import VimeoPlayer from '../../../common/VimeoPlayer/VimeoPlayer';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import ViewPortfolioDomavekaCardSection from './ViewPortfolioDomavekaCardSection';
import ViewPortfolioDomavekaGallerySection from './ViewPortfolioDomavekaGallerySection';

export interface ViewPortfolioDomaVekaSiteProps {}

const ViewPortfolioDomaVekaSite = (props: ViewPortfolioDomaVekaSiteProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  const language = t('portfolioItem:wildberries.language');
  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: '#FFF', color: '#1F1F21' }}
        name={data.name}
        video={{
          src: 'https://vimeo.com/805139456',
        }}
        description={data.description}
      />
      <ViewPortfolioDomavekaGallerySection
        images={Array.from({ length: 5 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-3-${i + 1}`),
          };
        })}
        texts={t('portfolioItem:doma-veka-site.section-3.text', {
          returnObjects: true,
        })}
      />
      {/* 4ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#b7bdb4' }}
        className="portfolio-item-section--type-doma-veka-site-4">
        <div className="doma-veka-section__background">
          <img src={buildPageImageSrc('screen-4-3')} alt={'doma-veka'} />
        </div>
        <div className="doma-veka-section row">
          <div className="doma-veka-section__background">
            <img src={buildPageImageSrc('screen-4-3')} alt={'doma-veka'} />
          </div>
          <div className="doma-veka-section__image doma-veka-section__image--1">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-4-1')} alt={'doma-veka'} />
            </RevealWhenInView>
          </div>
          <div className="doma-veka-section__image doma-veka-section__image--2">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-4-2')} alt={'doma-veka'} />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 5ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#E6E6E6' }}
        className="portfolio-item-section--type-doma-veka-site-5 container">
        <div className={`doma-veka-section doma-veka-section--${language}`}>
          <div className="doma-veka-section__title">
            <RevealWhenInView>
              <p>{t('portfolioItem:doma-veka-site.section-5.title.0')}</p>
            </RevealWhenInView>
          </div>
          <div className="doma-veka-section__subtitle">
            <RevealWhenInView>
              <p>{t('portfolioItem:doma-veka-site.section-5.subtitle.0')}</p>
              <p>{t('portfolioItem:doma-veka-site.section-5.subtitle.1')}</p>
            </RevealWhenInView>
          </div>
          <div className="doma-veka-section__info-text">
            <RevealWhenInView>
              <span className="doma-veka-section__text">
                {t('portfolioItem:doma-veka-site.section-5.text.0')}
              </span>
              <span>1920px</span>
            </RevealWhenInView>
          </div>
          <div className="doma-veka-section__img  col-md-9">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-5-1')} alt={'doma-veka'} />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 6ый экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#202020' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-doma-veka-site-6">
        <div className="doma-veka-section">
          <div className="doma-veka-section__video">
            <VimeoPlayer
              size="fill"
              url="https://player.vimeo.com/video/805147778?h=88077f60f6"
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#e7e6e8' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-doma-veka-site-7">
        <div className="doma-veka-section">
          <div className="doma-veka-section__background">
            <img src={buildPageImageSrc('screen-7-1')} alt={'doma-veka'} />
            <div className="doma-veka-section__text-container">
              <RevealWhenInView>
                <span className="doma-veka-section__text">
                  {t('portfolioItem:doma-veka-site.section-7.text.0')}
                </span>
                <span>1920px</span>
              </RevealWhenInView>
            </div>
          </div>
          <div className="doma-veka-section__video">
            <VimeoPlayer
              size="fill"
              url="https://player.vimeo.com/video/645241046?h=89439f786e"
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 8ой экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#e7e6e8' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-doma-veka-site-8">
        <div className="doma-veka-section">
          <div></div>
          <div className="doma-veka-section__video">
            <VimeoPlayer
              size="fill"
              url={t('portfolioItem:doma-veka-site.section-8.video')}
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 9ый экран*/}
      <ViewPortfolioDomavekaCardSection
        images={Array.from({ length: 16 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-9-${i + 1}`),
          };
        })}
      />
      {/* 10ый экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#E6E6E6' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-doma-veka-site-10">
        <div className="doma-veka-section">
          <div className="doma-veka-section__header container">
            <div
              className={`doma-veka-section__description doma-veka-section__description--${language}`}>
              <RevealWhenInView>
                <p>{t('portfolioItem:doma-veka-site.section-10.text.0')}</p>
              </RevealWhenInView>
            </div>
            <div className="doma-veka-section__title">
              <RevealWhenInView>
                <p>{t('portfolioItem:doma-veka-site.section-10.text.1')}</p>
              </RevealWhenInView>
            </div>
          </div>
          <div className="doma-veka-section__video">
            <VimeoPlayer
              size="fill"
              url={t('portfolioItem:doma-veka-site.section-10.video')}
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 11ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#E6E6E6' }}
        className="portfolio-item-section--type-doma-veka-site-11">
        <div className="doma-veka-section">
          <div className="doma-veka-section__background">
            <img src={buildPageImageSrc('screen-11-1')} alt={'doma-veka'} />
          </div>
          <div className="doma-veka-section__text-container row">
            <div>
              <RevealWhenInView>
                <p>{t('portfolioItem:doma-veka-site.section-11.text')}</p>
              </RevealWhenInView>
            </div>
            <div>
              <RevealWhenInView>
                <p>1920px</p>
              </RevealWhenInView>
            </div>
          </div>
          <div className="doma-veka-section__image">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-11-2')} alt={'doma-veka'} />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 12ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#E6E6E6' }}
        className="portfolio-item-section--type-doma-veka-site-12 container">
        <div className="doma-veka-section">
          <div className="row">
            <div className="col-3 col-md-2 offset-2 offset-md-3">
              <RevealWhenInView>
                <p>{t('portfolioItem:doma-veka-site.section-12.text.0')}</p>
              </RevealWhenInView>
            </div>
            <div className="col-3 offset-1 offset-md-0">
              <RevealWhenInView>
                <p>{t('portfolioItem:doma-veka-site.section-12.text.1')}</p>
              </RevealWhenInView>
            </div>
          </div>
          <div className="col-7 offset-2 col-md-5 offset-md-3">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-12-1')} alt={'doma-veka'} />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 13ый экран*/}
      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          id={data.relatedId}
          style={{ backgroundColor: '#57644F', color: '#fff' }}
          preview={{
            backgroundColor: '#e6e8ea',
            image: {
              src: buildPageImageSrc('related'),
            },
          }}
        />
      )}
    </>
  );
};

export default ViewPortfolioDomaVekaSite;
