import { NavLink, useHistory } from "react-router-dom";

import "./_view-not-found.scss";

import List from "components/common/List";
import View from "components/layouts/View";
import { navRoutes } from "routes";
import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import TerminalLoading from "./TerminalLoading";
import Section from "components/layouts/Section";
import i18n from "i18n";

export interface NotFoundProps {}

const banner = `        ,d8       ,a8888a,             ,d8             888b      88    ,ad8888ba,  888888888888        88888888888  ,ad8888ba,    88        88  888b      88  88888888ba,
      ,d888     ,8P"'  \`"Y8,         ,d888             8888b     88   d8"'    \`"8b      88             88          d8"'    \`"8b   88        88  8888b     88  88      \`"8b
    ,d8" 88    ,8P        Y8,      ,d8" 88             88 \`8b    88  d8'        \`8b     88             88         d8'        \`8b  88        88  88 \`8b    88  88        \`8b
  ,d8"   88    88          88    ,d8"   88             88  \`8b   88  88          88     88             88aaaaa    88          88  88        88  88  \`8b   88  88         88
,d8"     88    88          88  ,d8"     88             88   \`8b  88  88          88     88             88"""""    88          88  88        88  88   \`8b  88  88         88
8888888888888  \`8b        d8'  8888888888888           88    \`8b 88  Y8,        ,8P     88             88         Y8,        ,8P  88        88  88    \`8b 88  88         8P
         88     \`8ba,  ,ad8'            88             88     \`8888   Y8a.    .a8P      88             88          Y8a.    .a8P   Y8a.    .a8P  88     \`8888  88      .a8P
         88       "Y8888P"              88             88      \`888    \`"Y8888Y"'       88             88           \`"Y8888Y"'     \`"Y8888Y"'   88      \`888  88888888Y"'
`;

const NotFound = (props: NotFoundProps) => {
  const [nextLocationPath, _setNextLocationPath] = useState<string | null>(
    null
  );
  const [selectedIndex, _setSelectedIndex] = useState<number>(0);

  const nextLocationPathRef = useRef(nextLocationPath);
  const selectedIndexRef = useRef<number>(selectedIndex);

  const history = useHistory();
  const terminalContentElRef = useRef<HTMLDivElement>(null);

  const setSelectedIndex = (cb: (prevState: number) => number) => {
    _setSelectedIndex((prevSelectedIndex) => {
      return (selectedIndexRef.current = cb(prevSelectedIndex));
    });
  };

  const setNextLocationPath = (path: string) => {
    _setNextLocationPath((nextLocationPathRef.current = path));
  };

  const loading = nextLocationPath !== null;

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        const nextLocation = nextLocationPathRef.current;

        if (nextLocation) {
          history.push(nextLocation);
        }
      }, 3000);
    }
  }, [loading, history]);

  const handleNavLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const href = (e.target as HTMLAnchorElement).getAttribute("href") || "";

    if (!nextLocationPathRef.current) {
      setNextLocationPath(href);
    }

    e.preventDefault();
  };

  useEffect(() => {
    const handleWindowKeydown = (e: KeyboardEvent) => {
      if (nextLocationPathRef.current) {
        return;
      }

      if (e.key === "Enter") {
        setNextLocationPath(
          navRoutes[selectedIndexRef.current].path.replace(
            "/:locale( |en)?",
            ""
          )
        );
      } else {
        setSelectedIndex((prevSelectedIndex) => {
          if (e.key === "ArrowUp") {
            if (prevSelectedIndex === null || prevSelectedIndex <= 0) {
              return 0;
            } else {
              return prevSelectedIndex - 1;
            }
          } else if (e.key === "ArrowDown") {
            if (
              prevSelectedIndex === null ||
              prevSelectedIndex >= navRoutes.length - 1
            ) {
              return navRoutes.length - 1;
            } else {
              return prevSelectedIndex + 1;
            }
          }

          return prevSelectedIndex;
        });
      }
    };

    window.addEventListener("keydown", handleWindowKeydown);

    return () => {
      window.removeEventListener("keydown", handleWindowKeydown);
    };
  }, []);

  const nextLangCode = useMemo(() => {
    return i18n.language === "en" ? "/en" : "";
  }, [i18n.language]);

  return (
    <View className="view-not-found">
      <Section viewportHeight isLead>
        <div className="view-not-found__container lead-section__container container ">
          <div className="view-not-found__inner">
            <div className="view-not-found__terminal text--secondary">
              <div
                ref={terminalContentElRef}
                className="view-not-found__terminal-content u-max-w-600"
              >
                <p>&gt; ooops! there is no such page on our site!</p>
                <p>&gt; this is 404 page error!!</p>
                <p>
                  &gt; choose page where you want to go (use Up &amp; Down btn
                  to SELECT and press Enter to go)
                </p>
              </div>

              <nav className="view-not-found__terminal-nav">
                <List
                  className="view-not-found__terminal-nav-list text--lowercase"
                  items={navRoutes}
                >
                  {({ path, nav }, i) => (
                    <NavLink
                      to={nextLangCode + path.replace("/:locale( |en)?", "")}
                      onClick={handleNavLinkClick}
                      className={classNames(
                        "view-not-found__terminal-link link",
                        {
                          active: nextLocationPath === path,
                          selected: selectedIndex === i,
                        }
                      )}
                    >
                      <span className="view-not-found__terminal-link-indicator side-brackets">
                        <span className="view-not-found__terminal-link-indicator-mark" />
                      </span>
                      {nav?.label}
                    </NavLink>
                  )}
                </List>
              </nav>

              {loading ? (
                <div className="view-not-found__terminal-footer">
                  <TerminalLoading text={"-".repeat(28)} />
                  <div>
                    Loading
                    <TerminalLoading text="..." />
                  </div>
                  <TerminalLoading text={"-".repeat(28)} />
                </div>
              ) : null}

              <p className="view-not-found__terminal-banner">{banner}</p>
            </div>
          </div>
        </div>
      </Section>
    </View>
  );
};

export default NotFound;
