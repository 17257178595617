
import React, { createElement, memo, ReactNode, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";

interface SlideUpInViewProps {
  tag?: keyof JSX.IntrinsicElements;
  targetTag?: keyof JSX.IntrinsicElements;
  children: ReactNode;
  delay?: number;
}

const SlideUpInView = memo(
  ({ tag = "div", targetTag = "div", delay = 0, children }: SlideUpInViewProps) => {
    const timelineRef = useRef<gsap.core.Timeline | null>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      /**
       * @type HTMLElement
       */
      const triggerEl = triggerRef.current!;
      const targetEl = targetRef.current!;

      if (!timelineRef.current) {
        timelineRef.current = gsap.timeline({
          scrollTrigger: {
            trigger: triggerEl,
            toggleActions: "play reset play reset",
          },
        });
      }

      gsap.set(targetEl, {
        willChange: "transform",
        backfaceVisibility: "hidden",
        perspective: 1,
      });

      const timeline = timelineRef.current;

      timeline.from(targetEl, {
        ease: "ease-out",
        duration: 0.6,
        y: 20,
        opacity: 0,
        delay,
      });

      return () => {
        timeline.pause(1).kill();
        gsap.set(targetEl, { clearProps: true });
      };
    }, [delay]);

    return createElement(
      tag,
      {
        ref: triggerRef,
      },
      createElement(
        targetTag,
        {
          ref: targetRef,
        },
        children
      )
    );
    // <div ref={triggerRef}>
    //   <div ref={targetRef}>{children}</div>
    // </div>
  }
);

// const SlideUpInView = ({ children }: SlideUpInViewProps) => {
//   const [triggerRef, inView] = useInView();
//   const controls = useAnimation();

//   useEffect(() => {
//     if (inView) {
//       controls.start("appear");
//     } else {
//       controls.set("hidden");
//     }
//   }, [controls, inView]);

//   return (
//     <div ref={triggerRef}>
//       <motion.div
//         animate={controls}
//         variants={{
//           hidden: {
//             opacity: 0,
//             y: "40px",
//           },
//           appear: {
//             opacity: 1,
//             y: 0,
//           },
//         }}
//         transition={{
//           ease: "easeOut",
//           duration: 0.6,
//         }}
//       >
//         {children}
//       </motion.div>
//     </div>
//   );
// };

export default SlideUpInView;
