import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import { useTranslation } from "react-i18next";
import { ViewSpecificPortfolioItemProps } from "data/portfolio/types";

const ViewPortfolioAist = (props: ViewSpecificPortfolioItemProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#232325", color: "#fff" }}
        name={data.name}
        description={data.description}
        video={{ src: "https://vimeo.com/822907288?share=copy" }}
        className="aist-site"
      />

      {/* экран 2 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFFFFF", color: "#232325" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-2 container"
      >
        <div className="row title-block">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>
        <div className="row description">
          <div className="offset-xl-2 col-md-2 caption">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.description.0.0")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.description.0.1")}
            </RevealWhenInView>
          </div>
          <div className="offset-md-1 col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.description.0.2")}
            </RevealWhenInView>
          </div>
        </div>
        <div className="row description">
          <div className="offset-xl-2 col-md-2 caption">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.description.1.0")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.description.1.1")}
            </RevealWhenInView>
          </div>
          <div className="offset-md-1 col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-2.description.1.2")}
            </RevealWhenInView>
          </div>
        </div>
        <img
          className="back"
          src={buildPageImageSrc(t("portfolioItem:aist.section-2.image"))}
          alt={"aist"}
        />
      </ViewPortfolioItemSection>

      {/* экран 3 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#26262a", color: "#FFFFFF" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-3"
      >
        <div className="row title-block container">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-3.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-6 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-3.title-block.title")}
            </RevealWhenInView>
          </div>
          <div className="offset-md-auto col-md-auto subtitle">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-3.title-block.subtitle")}
            </RevealWhenInView>
          </div>
        </div>

        <img
          className="back"
          src={buildPageImageSrc(t("portfolioItem:aist.section-3.image"))}
          alt={"aist"}
        />
      </ViewPortfolioItemSection>

      {/* экран 4 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFFFFF", color: "#252529" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-4 "
      >
        <div className="row title-block container">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="row description container">
          <div className="offset-xl-2 col-md-2 caption">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.description.0.0")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.description.0.1")}
            </RevealWhenInView>
          </div>
          <div className="offset-md-1 col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.description.0.2")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="row description container">
          <div className="offset-xl-2 col-md-2 caption">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.description.1.0")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-4.description.1.1")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="img-wrap">
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t("portfolioItem:aist.section-4.image"))}
              alt={"aist"}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* экран 5 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#252529", color: "#FFFFFF" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-5 container"
      >
        <div className="row title-block">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-5.title-block.0.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-5.title-block.0.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="row person-list">
          <div className="offset-xl-2 col-xl-6 col-md-7 item">
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-5.person.0.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
            <div className="caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.person.0.caption")}
              </RevealWhenInView>
            </div>
            <div className="text">
              <p>
                <RevealWhenInView>
                  {t("portfolioItem:aist.section-5.person.0.text")}
                </RevealWhenInView>
              </p>
              <ul>
                <RevealWhenInView>
                  <li>{t("portfolioItem:aist.section-5.person.0.list.0")}</li>
                </RevealWhenInView>
                <RevealWhenInView>
                  <li>{t("portfolioItem:aist.section-5.person.0.list.1")}</li>
                </RevealWhenInView>
                <RevealWhenInView>
                  <li>{t("portfolioItem:aist.section-5.person.0.list.2")}</li>
                </RevealWhenInView>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-md-4 col-8 item small">
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-5.person.1.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
            <div className="caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.person.1.caption")}
              </RevealWhenInView>
            </div>
            <div className="text">
              <p>
                <RevealWhenInView>
                  {t("portfolioItem:aist.section-5.person.1.text")}
                </RevealWhenInView>
              </p>
            </div>
          </div>
        </div>

        <div className="job-stories">
          <div className="row title-block">
            <div className="col-md-2 number">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.title-block.1.number")}
              </RevealWhenInView>
            </div>
            <div className="col-md-9 title">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.title-block.1.title")}
              </RevealWhenInView>
            </div>
          </div>
          <div className="row description">
            <div className="offset-xl-2 col-md-2 caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.0.0")}
              </RevealWhenInView>
            </div>
            <div className="col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.0.1")}
              </RevealWhenInView>
            </div>
            <div className="offset-md-1 col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.0.2")}
              </RevealWhenInView>
            </div>
          </div>
          <div className="row description">
            <div className="offset-xl-2 col-md-2 caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.1.0")}
              </RevealWhenInView>
            </div>
            <div className="col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.1.1")}
              </RevealWhenInView>
            </div>
            <div className="offset-md-1 col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.1.2")}
              </RevealWhenInView>
            </div>
          </div>
          <div className="row description">
            <div className="offset-xl-2 col-md-2 caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.2.0")}
              </RevealWhenInView>
            </div>
            <div className="col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.2.1")}
              </RevealWhenInView>
            </div>
            <div className="offset-md-1 col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.2.2")}
              </RevealWhenInView>
            </div>
          </div>
          <div className="row description">
            <div className="offset-xl-2 col-md-2 caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.3.0")}
              </RevealWhenInView>
            </div>
            <div className="col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.3.1")}
              </RevealWhenInView>
            </div>
            <div className="offset-md-1 col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.job-stories.3.2")}
              </RevealWhenInView>
            </div>
          </div>
        </div>

        <div className="customer">
          <div className="row title-block">
            <div className="col-md-2 number">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.title-block.2.number")}
              </RevealWhenInView>
            </div>
            <div className="col-md-9 title">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-5.title-block.2.title")}
              </RevealWhenInView>
            </div>
          </div>

          <div className="img-wrap">
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t("portfolioItem:aist.section-5.customer.image")
                )}
                alt={"aist"}
              />
            </RevealWhenInView>
          </div>
        </div>

        <img
          className="background"
          src={buildPageImageSrc(t("portfolioItem:aist.section-5.background"))}
          alt={"aist"}
        />
      </ViewPortfolioItemSection>

      {/* экран 6 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFFFFF", color: "#252529" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-6"
      >
        <div className="row title-block container">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-6.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-6.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="row description container">
          <div className="offset-xl-2 col-md-2 caption">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-6.description.0.0")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-6.description.0.1")}
            </RevealWhenInView>
          </div>
          <div className="offset-md-1 col-xl-3 col-md-4 text">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-6.description.0.2")}
            </RevealWhenInView>
          </div>
        </div>
        <div className="row description container">
          <div className="offset-xl-2 col-md-2 col-3 caption">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-6.description.1.0")}
            </RevealWhenInView>
          </div>
          <div className="col-xl-6 col-8 text">
            <RevealWhenInView>
              <div className="item">
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.00195"
                    cy="6.39844"
                    r="6"
                    stroke="#4A4A4A"
                    strokeWidth="0.5"
                    strokeDasharray="2.5 2.5"
                  />
                </svg>
                <p>{t("portfolioItem:aist.section-6.description.1.1")}</p>
              </div>
            </RevealWhenInView>
            <RevealWhenInView>
              <div className="item">
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.48438 1H11.4844M11.4844 1V11M11.4844 1L1.48438 11"
                    stroke="#50B32B"
                    strokeWidth="2"
                  />
                </svg>
                <p>{t("portfolioItem:aist.section-6.description.1.2")}</p>
              </div>
            </RevealWhenInView>
            <RevealWhenInView>
              <div className="item">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6" r="6" fill="#4A51FF" />
                </svg>
                <p>{t("portfolioItem:aist.section-6.description.1.3")}</p>
              </div>
            </RevealWhenInView>
          </div>
        </div>

        <div className="img-wrap scheme container">
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(
                t("portfolioItem:aist.section-6.image-scheme")
              )}
              alt={"aist"}
            />
          </RevealWhenInView>
        </div>

        <div className="auth-from row container">
          <img
            className="background"
            src={buildPageImageSrc(
              t("portfolioItem:aist.section-6.background")
            )}
            alt={"aist"}
          />
          <div className="offset-xl-1 col-xl-4 col-md-5 item">
            <p>{t("portfolioItem:aist.section-6.auth.0.caption")}</p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-6.auth.0.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-md-1 col-xl-4 col-md-5 item">
            <p>{t("portfolioItem:aist.section-6.auth.1.caption")}</p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-6.auth.1.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-xl-1 col-xl-4 col-md-5 item">
            <p className="left">
              {t("portfolioItem:aist.section-6.auth.2.caption")}
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-6.auth.2.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-md-1 col-xl-4 col-md-5 item">
            <p className="left">
              {t("portfolioItem:aist.section-6.auth.3.caption")}
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-6.auth.3.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>

      {/* экран 7 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFFFFF", color: "#FFFFFF" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-7"
      >
        <div className="row title-block container">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-7.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-7.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="description offset-xl-1 container">
          <RevealWhenInView>
            {t("portfolioItem:aist.section-7.description")}
          </RevealWhenInView>
        </div>

        <img
          className="back"
          src={buildPageImageSrc(t("portfolioItem:aist.section-7.image"))}
          alt={"aist"}
        />
      </ViewPortfolioItemSection>

      {/* экран 8 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#252529", color: "#FFFFFF" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-8"
      >
        <div className="row title-block container">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-8.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-8.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="img-wrap container">
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t("portfolioItem:aist.section-8.image"))}
              alt={"aist"}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* экран 9 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#FFFFFF", color: "#252529" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-9 container"
      >
        <div className="row title-block ">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-9.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-9.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="row flights">
          <div className="offset-xl-1 col-xl-5 col-md-6 item">
            <p>
              <RevealWhenInView>
                {t("portfolioItem:aist.section-9.list.0.caption")}
              </RevealWhenInView>
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-9.list.0.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-md-1 offset-auto col-md-4 col-auto item">
            <p>
              <RevealWhenInView>
                {t("portfolioItem:aist.section-9.list.1.caption")}
              </RevealWhenInView>
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-9.list.1.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-xl-1 col-xl-5 col-md-6 item">
            <p>
              <RevealWhenInView>
                {t("portfolioItem:aist.section-9.list.2.caption")}
              </RevealWhenInView>
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-9.list.2.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-md-1 offset-auto col-md-4 col-auto item no-caption">
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-9.list.3.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-xl-1 col-xl-5 col-md-6 item">
            <p>
              <RevealWhenInView>
                {t("portfolioItem:aist.section-9.list.4.caption")}
              </RevealWhenInView>
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-9.list.4.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="offset-md-1 offset-auto col-md-4 col-auto item">
            <p>
              <RevealWhenInView>
                {t("portfolioItem:aist.section-9.list.5.caption")}
              </RevealWhenInView>
            </p>
            <div className="img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-9.list.5.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>

      {/* экран 10 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#252529", color: "#FFFFFF" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-10 "
      >
        <div className="black-zone">
          <div className="row title-block container">
            <div className="col-md-2 number">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-10.title-block.number")}
              </RevealWhenInView>
            </div>
            <div className="col-md-9 title">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-10.title-block.title")}
              </RevealWhenInView>
            </div>
          </div>

          <div className="row description container">
            <div className="offset-xl-2 col-md-2 caption">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-10.description.0")}
              </RevealWhenInView>
            </div>
            <div className="col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-10.description.1")}
              </RevealWhenInView>
            </div>
            <div className="offset-md-1 col-xl-3 col-md-4 text">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-10.description.2")}
              </RevealWhenInView>
            </div>
          </div>
        </div>

        <div className="banner offset-1 col-9 container">
          <RevealWhenInView>
            <img
              src={buildPageImageSrc(t("portfolioItem:aist.section-10.image"))}
              alt={"aist"}
            />
          </RevealWhenInView>
        </div>

        <div className="white-zone">
          <div className="list row container">
            <div className="offset-xl-1 col-xl-4 col-md-5 item">
              <p>
                <RevealWhenInView>
                  {t("portfolioItem:aist.section-10.list.0.caption")}
                </RevealWhenInView>
              </p>
              <div className="img-wrap">
                <RevealWhenInView>
                  <img
                    src={buildPageImageSrc(
                      t("portfolioItem:aist.section-10.list.0.image")
                    )}
                    alt={"aist"}
                  />
                </RevealWhenInView>
              </div>
            </div>
            <div className="offset-md-1 offset-auto col-md-5 col-auto item right">
              <p>
                <RevealWhenInView>
                  {t("portfolioItem:aist.section-10.list.1.caption")}
                </RevealWhenInView>
              </p>
              <div className="img-wrap">
                <RevealWhenInView>
                  <img
                    src={buildPageImageSrc(
                      t("portfolioItem:aist.section-10.list.1.image")
                    )}
                    alt={"aist"}
                  />
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>

      {/* экран 11 */}
      <ViewPortfolioItemSection
        style={{ backgroundColor: "#252529", color: "#FFFFFF" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-11 container"
      >
        <div className="row title-block">
          <div className="col-md-2 number">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-11.title-block.number")}
            </RevealWhenInView>
          </div>
          <div className="col-md-9 title">
            <RevealWhenInView>
              {t("portfolioItem:aist.section-11.title-block.title")}
            </RevealWhenInView>
          </div>
        </div>

        <div className="list">
          <div className="item">
            <p className="offset-xl-1">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-11.list.0.caption")}
              </RevealWhenInView>
            </p>
            <div className="offset-xl-2 col-xl-9 img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-11.list.0.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="item right">
            <p className="offset-md-auto col-auto">
              <RevealWhenInView>
                {t("portfolioItem:aist.section-11.list.1.caption")}
              </RevealWhenInView>
            </p>
            <div className="offset-xl-1 col-xl-9 img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-11.list.1.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
          <div className="item">
            <p>
              <RevealWhenInView>
                {t("portfolioItem:aist.section-11.list.2.caption")}
              </RevealWhenInView>
            </p>
            <div className="offset-xl-2 col-xl-9 img-wrap">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t("portfolioItem:aist.section-11.list.2.image")
                  )}
                  alt={"aist"}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection
        style={{ backgroundColor: "#252529" }}
        className=" view-portfolio-aist-section  portfolio-item-section--type-aist-12 container"
      >
        <img
          src={buildPageImageSrc(t("portfolioItem:aist.section-12.image"))}
          alt={"aist"}
        />
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioAist;
