import ViewPortfolioAlfaleads from "components/views/ViewPortfolioItem/ViewPortfolioAlfaleads/ViewPortfolioAlfaleads";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "alfa",
  component: ViewPortfolioAlfaleads,
  title: "alfa",
  name: "alfaleads network",
  altName: "banners and posters",
  year: "2024",
  awardsList: [],

  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/213836291/Alfaleads-Network-Banners-posters",
    },
  ],

  preview: {
    label: "brand identity",
    // backgroundColor: "#131313",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/alfa/preview",
        file: "1.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/alfa/preview",
        file: "2.webp",
      }),
    },
  },

  poolOfWorks: ["Research", "Design"],

  type: "site",
  subtype: "site",

  description: [
    "We provided comprehensive design support for AlfaLeads: developing banners, social media creatives, and brand identity elements.",
    "Our team handled a broad range of tasks, from promotional materials to advertising layouts.",
  ],
  shortDescription: "Инвестиционно-строительная холдинговая компания",

  seo: {
    title:
      "Design Support for AlfaLeads: Banners, Creatives, and Brand Identity | SAGIROV",
    description:
      "We provided comprehensive design support for AlfaLeads: developing banners, social media creatives, and brand identity elements. Our team handled a broad range of tasks, from promotional materials to advertising layouts.",
    keywords: [
      "design support",
      "banners",
      "creatives",
      "social media creatives",
      "brand identity",
      "corporate identity",
      "promo materials",
      "advertising materials",
      "AlfaLeads design",
    ],
    openGraph: {
      type: "article",
      title:
        "Design Support for AlfaLeads: Banners, Creatives, and Brand Identity | SAGIROV",
      description:
        "We provided comprehensive design support for AlfaLeads: developing banners, social media creatives, and brand identity elements. Our team handled a broad range of tasks, from promotional materials to advertising layouts.",
    },
  },
};

export default porfolioItem;
