import Image, { ImageConfig } from "components/common/Image";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioDomaVekaEquipmentCardSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  image: ImageConfig;
  caption: [string, string];
}

const ViewPortfolioDomaVekaEquipmentCardSection = ({
  image,
  caption,
  ...sectionProps
}: ViewPortfolioDomaVekaEquipmentCardSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-doma-veka-equipment-card-section"
    >
      <div className="container">
        <figure className="row">
          <div className="col-11 col-lg-6">
            <RevealWhenInView>
              <Image className="view-portfolio-doma-veka-equipment-card-section__image" {...image} />
            </RevealWhenInView>
          </div>
          <div className="view-portfolio-doma-veka-equipment-card-section__col-with-cation col-11 offset-lg-1 col-lg-4">
            <SlideFadeOutWhenInView>
              {({ setTriggerEl, setTarget }) => (
                <figcaption ref={setTriggerEl} className="row u-max-w-480">
                  <p
                    ref={setTarget()}
                    className="view-portfolio-doma-veka-equipment-card-section__caption-item--first col-11 order-lg-2"
                  >
                    {caption[0]}
                  </p>
                  <p
                    ref={setTarget()}
                    className="view-portfolio-doma-veka-equipment-card-section__caption-item--second col-11 order-lg-1 "
                  >
                    {caption[1]}
                  </p>
                </figcaption>
              )}
            </SlideFadeOutWhenInView>
          </div>
        </figure>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioDomaVekaEquipmentCardSection;
