import { usePortfolioItemAssets } from "hooks/useAssets";

import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";

import { useTranslation } from "react-i18next";
import React from "react";

export interface ViewPortfolioAlfaleadsProps {}

const ViewPortfolioAlfaleads = (props: ViewPortfolioAlfaleadsProps) => {
  const { data } = useViewPortfolioItemContext();

  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);

  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-alfa"
        caption={[
          t("portfolioItem:alfa.about.0"),
          t("portfolioItem:alfa.about.1"),
          t("portfolioItem:alfa.about.2"),
        ]}
        image={{
          isLogo: false,
          src: buildPageImageSrc(
            t("portfolioItem:alfa.section-2.image"),
            "webp"
          ),
        }}
        style={{
          backgroundColor: "#131313",
          color: "#FFF",
          backgroundPosition: "top left",
          backgroundSize: "cover",
        }}
        name={data.name}
        description={data.description}
      />
      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-3"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-3.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-4"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-4.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 5ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-5"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-5.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-6"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-6.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-7"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-7.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-8"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-8.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 9ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-9"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-9.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 10ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-10"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-10.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 11ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-11"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-11.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 12ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-12"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-12.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 13ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-13"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-13.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 14ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-14"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-14.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 15ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-15"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-15.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 16ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-16"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-16.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 17ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-17"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-17.image"),
              "gif"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 18ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-18"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-18.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 19ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{
          backgroundColor: "#000",
        }}
        className={
          " view-portfolio-alfa-section portfolio-item-section--type-alfa-19"
        }
      >
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(
              t("portfolioItem:alfa.section-19.image"),
              "webp"
            )}
            alt={"alfa"}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioAlfaleads;
