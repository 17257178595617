import Section from "components/layouts/Section";
import s from "./index.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Button from "components/common/Button";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { useEffect, useState } from "react";
import { useViewPortfolioItemContext } from "components/views/ViewPortfolioItem/ViewPortfolioItem";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

function ProjectsSwiperSection({ slides }: { slides?: any[] }) {
  const [backModifier, setBackModifier] = useState("");
  const [imageFormat, setImageFormat] = useState("png");
  const [isWebpSupported, setIsWebpSupported] = useState(false);

  // Проверка поддержки WebP
  useEffect(() => {
    const checkWebpSupport = async () => {
      const webpSupported = await isWebpSupportedByBrowser();
      setIsWebpSupported(webpSupported);
      setImageFormat(webpSupported ? "webp" : "png");
    };

    checkWebpSupport();
  }, []);

  // Функция для проверки поддержки WebP
  const isWebpSupportedByBrowser = () => {
    return new Promise<boolean>((resolve) => {
      const webpImage = new Image();
      webpImage.onload = () => resolve(true);
      webpImage.onerror = () => resolve(false);
      webpImage.src =
        "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
    });
  };

  function move() {
    const viewport_width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    if (viewport_width <= 20000) {
      setBackModifier("");
    }

    if (viewport_width <= 992) {
      setBackModifier("-tab");
    }

    if (viewport_width <= 414) {
      setBackModifier("-mob");
    }
  }

  useEffect(() => {
    move();
    window.addEventListener("resize", move);
    return () => window.removeEventListener("resize", move);
  }, []);

  const { data } = useViewServicesItemContext();

  // Определяем, какие данные использовать для слайдов
  const slidesToRender =
    slides ||
    (Array.isArray(data?.sliderSection)
      ? data?.sliderSection
      : data?.sliderSection?.items);

  console.log("ProjectsSwiperSection - данные слайдов:", slidesToRender);
  console.log("Текущий модификатор изображения:", backModifier);
  console.log("Поддержка WebP:", isWebpSupported ? "Да" : "Нет");
  console.log("Используемый формат изображений:", imageFormat);

  // Функция для исправления путей к изображениям
  const fixImagePath = (path: string, isLogo: boolean = false) => {
    if (!path) return "";

    // Убираем лишние слеши
    const cleanPath = path.replace(/^\/+/, "").replace(/\/+$/, "");

    // Проверяем, содержит ли путь "mumu" и заменяем на "mu-mu"
    let correctedPath = cleanPath;
    if (cleanPath.includes("mumu")) {
      correctedPath = cleanPath.replace("mumu", "mu-mu");
    }

    // Проверяем, содержит ли путь "novation" и заменяем на "nova-group"
    if (correctedPath.includes("novation")) {
      correctedPath = correctedPath.replace("novation", "nova-group");
    }

    // Проверяем, содержит ли путь "cuprum-golem" и обрабатываем его
    if (correctedPath.includes("cuprum-golem")) {
      console.log("Обрабатываем путь для Медного голема:", correctedPath);
    }

    // Разбиваем путь на части
    const pathParts = correctedPath.split("/");

    // Если это логотип
    if (isLogo) {
      // Получаем директорию проекта (например, "ux-ui") и имя проекта (например, "nova-group")
      const serviceType =
        pathParts.length > 1 ? pathParts[pathParts.length - 3] : "";
      const projectName =
        pathParts.length > 0 ? pathParts[pathParts.length - 2] : "";

      // Формируем корректный путь к логотипу
      if (serviceType && projectName) {
        const logoPath = `/images/${serviceType}/${projectName}/logo.svg`;
        console.log(`Сформирован путь к логотипу: ${logoPath}`);
        return logoPath;
      } else {
        // Если не удалось разобрать путь, возвращаем исходный путь с добавлением .svg
        console.log(`Не удалось разобрать путь: ${correctedPath}`);
        return `/${correctedPath}.svg`;
      }
    } else {
      // Для фоновых изображений
      const serviceType =
        pathParts.length > 1 ? pathParts[pathParts.length - 3] : "";
      const projectName =
        pathParts.length > 0 ? pathParts[pathParts.length - 2] : "";

      // Формируем корректный путь к фоновому изображению
      if (serviceType && projectName) {
        const bgPath = `/images/${serviceType}/${projectName}/background${backModifier}.${
          isWebpSupported ? "webp" : "png"
        }`;
        console.log(`Сформирован путь к фону: ${bgPath}`);
        return bgPath;
      } else {
        // Если не удалось разобрать путь, возвращаем исходный путь
        console.log(`Не удалось разобрать путь: ${correctedPath}`);
        return `/${correctedPath}${backModifier}.${
          isWebpSupported ? "webp" : "png"
        }`;
      }
    }
  };

  return (
    <DynamicBackgroundTrigger>
      <Section
        viewportHeight
        className={
          s["services-item-swiper-section"] +
          " text--light services-item-swiper-section"
        }
      >
        <Swiper
          className={s["services-item-swiper-section__swiper"]}
          navigation={{
            nextEl: ".button.navigation",
          }}
          loop
        >
          {/* @ts-ignore */}
          {slidesToRender?.map((item, index) => {
            // Формируем пути к изображениям
            const backgroundPath = fixImagePath(item.background);
            const logoPath = fixImagePath(item.logo, true);

            console.log(`Слайд ${index} - путь к фону:`, backgroundPath);
            console.log(`Слайд ${index} - путь к лого:`, logoPath);

            return (
              <SwiperSlide
                key={index}
                className={"row " + s["services-item-swiper-section__slide"]}
                style={{
                  backgroundColor: "#333", // Запасной цвет фона
                }}
              >
                {/* Фоновое изображение */}
                {item.background && (
                  <div
                    className={s["services-item-swiper-section__background"]}
                  >
                    <picture>
                      {isWebpSupported && (
                        <source srcSet={backgroundPath} type="image/webp" />
                      )}
                      <img
                        src={backgroundPath.replace(".webp", ".png")}
                        alt={item.title}
                        onError={(e) => {
                          console.error(
                            `Ошибка загрузки фона: ${backgroundPath}`
                          );
                          const target = e.target as HTMLImageElement;

                          // Пробуем альтернативный путь для mu-mu
                          if (
                            item.background &&
                            item.background.includes("mumu")
                          ) {
                            // Гарантируем правильное имя папки mu-mu
                            const altPath = `/images/branding/mu-mu/background${backModifier}.${
                              isWebpSupported ? "webp" : "png"
                            }`;
                            console.log(
                              `Пробуем альтернативный путь: ${altPath}`
                            );
                            target.src = altPath;
                          }

                          // Пробуем альтернативный путь для novation
                          if (
                            item.background &&
                            item.background.includes("novation")
                          ) {
                            // Гарантируем правильное имя папки nova-group
                            const altPath = `/images/ux-ui/nova-group/background${backModifier}.${
                              isWebpSupported ? "webp" : "png"
                            }`;
                            console.log(
                              `Пробуем альтернативный путь: ${altPath}`
                            );
                            target.src = altPath;
                          }

                          // Пробуем альтернативный путь для cuprum-golem
                          if (
                            item.background &&
                            item.background.includes("cuprum-golem")
                          ) {
                            // Проверяем путь к изображению
                            const altPath = `/images/developer-sites/cuprum-golem/background${backModifier}.${
                              isWebpSupported ? "webp" : "png"
                            }`;
                            console.log(
                              `Пробуем альтернативный путь для Медного голема: ${altPath}`
                            );
                            target.src = altPath;
                          }
                        }}
                      />
                    </picture>
                  </div>
                )}

                <div
                  className={s["services-item-swiper-section__slide-container"]}
                  style={{ position: "relative", zIndex: 1 }}
                >
                  <div>
                    <div className={"row "}>
                      <div className=" col-11 col-md-6 order-1">
                        <div
                          className={
                            "heading col-11 " +
                            s["services-item-swiper-section__title"]
                          }
                        >
                          <span>{item.title}</span>
                        </div>
                      </div>
                      <div className="col-11 col-md-4 order-3">
                        <div className="col-11">
                          {item.subtitle || item.description}
                        </div>
                      </div>
                      <div
                        className={
                          "row col-11 col-md-5 offset-0 col-lg-3 offset-lg-2 order-2 " +
                          s["slider-head"]
                        }
                      >
                        <div>
                          <div>
                            <div
                              className={
                                "row " +
                                s[
                                  "services-item-swiper-section__right-top-list"
                                ]
                              }
                            >
                              {/* @ts-ignore */}
                              {item.headerItems &&
                                item.headerItems.map(
                                  (headerItem: string, index: number) => (
                                    <span
                                      key={index + headerItem}
                                      className={
                                        "side-brackets col-md-11 col-auto label "
                                      }
                                    >
                                      {headerItem}
                                    </span>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {item.body && (
                      <div
                        className={
                          "row " + s["services-item-swiper-section__list"]
                        }
                      >
                        <div className="col-4 col-sm-3 col-md-2 col-lg-1 side-brackets label">
                          {item.body.title}
                        </div>
                        <List
                          items={item.body.items}
                          gap="lg"
                          marked
                          className="col-6 col-md-3"
                        >
                          {(item) => (
                            <span className="text--secondary">{item}</span>
                          )}
                        </List>
                      </div>
                    )}
                  </div>

                  {item.logo && (
                    <footer className={"row "}>
                      <div
                        className={
                          s["services-item-swiper-section__footer-logo"] +
                          " row"
                        }
                      >
                        <div className={" row"}>
                          <img
                            src={logoPath}
                            alt={item.title}
                            onError={(e) => {
                              console.error(
                                `Ошибка загрузки логотипа: ${logoPath}`
                              );
                              const target = e.target as HTMLImageElement;

                              // Пробуем альтернативный путь для mu-mu
                              if (item.logo && item.logo.includes("mumu")) {
                                // Гарантируем правильное имя папки mu-mu
                                const altPath = `/images/branding/mu-mu/logo.svg`;
                                console.log(
                                  `Пробуем альтернативный путь для лого: ${altPath}`
                                );
                                target.src = altPath;
                              }

                              // Пробуем альтернативный путь для novation
                              if (item.logo && item.logo.includes("novation")) {
                                // Гарантируем правильное имя папки nova-group
                                const altPath = `/images/ux-ui/nova-group/logo.svg`;
                                console.log(
                                  `Пробуем альтернативный путь для лого: ${altPath}`
                                );
                                target.src = altPath;
                              }

                              // Пробуем альтернативный путь для cuprum-golem
                              if (
                                item.logo &&
                                item.logo.includes("cuprum-golem")
                              ) {
                                // Проверяем путь к логотипу
                                const altPath = `/images/developer-sites/cuprum-golem/logo.svg`;
                                console.log(
                                  `Пробуем альтернативный путь для лого Медного голема: ${altPath}`
                                );
                                target.src = altPath;
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          "col-auto offset-auto " +
                          s["services-item-swiper-section__next-slide-button"]
                        }
                      >
                        <Button
                          className={"footnote navigation"}
                          renderRight={() => (
                            <div
                              className={
                                "sprite-icon sprite-icon--arrow-down-white "
                              }
                            />
                          )}
                        >
                          <p>Следующий проект</p>
                        </Button>
                      </div>
                    </footer>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ProjectsSwiperSection;
