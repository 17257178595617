import { useRef } from "react";
import { StageItems } from "./StageItems";
import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";

interface WorkStagesData {
  workStagesSection?: {
    title: string;
    description: string;
  };
}

interface ViewDeveloperSitesStagesSectionProps {
  // добавьте необходимые props если они есть
}

const ServicesStagesSection = (props: ViewDeveloperSitesStagesSectionProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { data } = useViewServicesItemContext();

  if (!data.workStagesSection) {
    return null;
  }

  // Вычисляем общее количество шагов для всех этапов
  const totalSteps = data.workStagesSection.items.reduce(
    (total, item) => total + item.items.length,
    0
  );

  // Создаем массив с этапами и сквозной нумерацией шагов
  let currentStepNumber = 0;
  const stagesWithContinuousSteps = data.workStagesSection.items.map((item) => {
    const stageItemProps = {
      ...item,
      items: item.items.map((i) => {
        currentStepNumber += 1;

        // Используем поле step, если оно есть, иначе используем текущий номер шага
        const stepNumber = i.step || currentStepNumber;

        // Используем поле subtitle, если оно есть, иначе разбиваем заголовок
        let mainTitle = i.title;
        let subTitle = "";

        if (i.subtitle) {
          // Если есть поле subtitle, используем его
          subTitle = i.subtitle;
        } else {
          // Иначе разбиваем заголовок на основной заголовок и подзаголовок
          const titleParts = i.title.split(" ");
          mainTitle = titleParts[0]; // Первое слово как основной заголовок
          subTitle = titleParts.slice(1).join(" "); // Остальные слова как подзаголовок
        }

        return {
          title: mainTitle,
          subtitle: subTitle,
          text: i.description,
          step_number: stepNumber,
        };
      }),
    };
    return stageItemProps;
  });

  return (
    <DynamicBackgroundTrigger>
      <section
        ref={triggerRef}
        className={
          `section text--light section--with-scroll-appear-background background--${data.color} ` +
          s["view-developer-sites-stages-section"]
        }
      >
        <div className="section-container container">
          <div className="view-developer-sites-stages-section__inner">
            <div
              className={
                "row " + s["view-developer-sites-stages-section__header"]
              }
            >
              <div className="col-11 col-xl-7 heading">
                <RevealWhenInView>
                  <h2 className="heading service-heading2">
                    {data.workStagesSection.title}
                  </h2>
                </RevealWhenInView>
              </div>
              <div className="col-md-7 offset-md-4 offset-xl-0 col-xl-4">
                <RevealWhenInView>
                  <p className="text--indent-md-quarter">
                    {data.workStagesSection.description}
                  </p>
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        {stagesWithContinuousSteps.map((item) => {
          return <StageItems {...item} max_step_number={totalSteps} />;
        })}
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ServicesStagesSection;
